import { productsActions } from "api/products/actions";
import { InfoLabel } from "components/common/infoLabel";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { useQuery } from "hooks";
import { SelectableOption } from "pages/inventory/products/shared/components/selectableOption/SelectableOption";
import { queryString } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const AttributesVisibilityOnLabel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({ product: panelId, pageSize: 999 });
  const { data: productAttributes, error, isLoading } = productsActions.useProductAttributes(
    search,
  );
  const showOnLabelMutation = productsActions.usePatchProductAttribute();

  if (isLoading)
    return (
      <InfoLabel title="cechy widoczne na etykiecie">
        <Spinner size={20} />
      </InfoLabel>
    );

  if (error)
    return (
      <InfoLabel title="cechy widoczne na etykiecie">
        <CommonError status={error._httpStatus_} />
      </InfoLabel>
    );

  if (!productAttributes) return <EmptyValue fontSize="14" fontWeight="700" />;

  return (
    <InfoLabel title="cechy widoczne na etykiecie">
      <div className="d-flex align-items-center gap-1">
        {productAttributes.map(productAttribute => (
          <SelectableOption
            checked={productAttribute.showOnLabel}
            isLoading={showOnLabelMutation.isLoading}
            key={productAttribute.id}
            label={productAttribute.attribute.name}
            onClick={() =>
              !showOnLabelMutation.isLoading &&
              showOnLabelMutation.mutate({
                id: productAttribute.id,
                showOnLabel: !productAttribute.showOnLabel,
              })
            }
            showIcon={false}
          />
        ))}
      </div>
    </InfoLabel>
  );
};
