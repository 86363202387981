import { manufacturingUnitsKeys } from "api/manufacturing/units/keys";
import socialIcon from "assets/images/social.svg";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { useQueryClient } from "react-query";
import { PaginatedQueryReturnType } from "hooks/createPaginatedQuery";
import { Pagination } from "api/types";
import { ManufacturingGroup, ManufacturingUnit } from "api/manufacturing/units/models";
import { ApiQueryReturnType } from "hooks/createApiQuery";

export const EmptyTodoSection = ({
  unitItemQuery,
  groupQuery,
}: {
  unitItemQuery: PaginatedQueryReturnType<Pagination<ManufacturingUnit>>;
  groupQuery: ApiQueryReturnType<ManufacturingGroup[]>;
}) => {
  const stageId = useStageId();
  const { data: units, refetch: refetchUnits, isRefetching: unitsAreLoading } = unitItemQuery;

  const { refetch: refetchGroups, isRefetching: groupsAreLoading } = groupQuery;
  const queryClient = useQueryClient();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between py-2 gap-3">
        <div className="d-flex align-items-center gap-2">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
            Do zrobienia
          </Typography>
          <Typography color="deepPurple400" fontSize="14" fontWeight="700">
            {units.length || "brak"}
          </Typography>
        </div>
      </div>
      <div className="d-flex flex-column gap-2 align-items-center">
        <div>
          <img alt="brak zleceń" src={socialIcon} />
        </div>
        <div className="d-flex flex-column gap-2 py-4 align-items-center">
          <Typography color="neutralBlack88" fontSize="20" fontWeight="700">
            Nie ma nic więcej do zrobienia
          </Typography>
          <Typography color="neutralBlack48" fontSize="16" fontWeight="500">
            Zrelaksuj się chwilę :)
          </Typography>
          <div className="py-4">
            <Button
              isLoading={unitsAreLoading || groupsAreLoading}
              onClick={() => {
                refetchGroups();
                refetchUnits();
                queryClient.invalidateQueries(
                  manufacturingUnitsKeys.manufacturingItem.count(stageId),
                );
              }}
              size="medium"
              startIcon={MdiRestartAlt}
              variant="deepPurple"
            >
              Odśwież listę
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
