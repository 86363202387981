import { CountryCode } from "CONSTANTS";
import { ManufacturingItem } from "api/manufacturing/models";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  manufacturingItem: ManufacturingItem;
}

export const BuyerSection = ({ manufacturingItem }: Props) => {
  if (!manufacturingItem.order) return null;
  const { order } = manufacturingItem;

  return (
    <RightPanelSection title="Nabywca">
      <div className="d-flex flex-column gap-2 pb-2">
        <Typography fontSize="20" fontWeight="700" noWrap>
          {`${order.delivery.firstName} ${order.delivery.lastName}`}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.street}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.postCode} {order.delivery.city}
        </Typography>
        <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
          {order.delivery.phone}
        </Typography>
      </div>
      <div className="pb-2">
        <InfoLabel title="kraj wysyłki">
          {order.delivery.countryCode ? (
            <CountryFlag countryCode={order.delivery.countryCode as CountryCode} />
          ) : (
            <EmptyValue fontSize="16" fontWeight="700" />
          )}
        </InfoLabel>
      </div>
      <div className="pb-2">
        <InfoLabel title="konto sprzedażowe">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {manufacturingItem.salesAccount?.name}
          </Typography>
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
