import {
  DeclinedNoteState,
  GroupedUnitInProgress,
  ImplementedBy,
  ManufacturingPriority,
  ManufacturingUnitGroupType,
  NormalizedGroupElement,
} from "api/manufacturing/units/models";
import {
  GroupBodyProps,
  GroupHeaderProps,
  InProgressGroupOptionsProps,
  ManufacturingPanelProps,
  TodoGroupOptionsProps,
  UnitBodyProps,
} from "./types";
import styles from "./ManufacturingPanel.module.css";
import { cx, dateFns, dateUtils, dictToList, queryString } from "utilities";
import { ColumnType } from "../ColumnView";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useQuery, useSelector, useStateModal, useToggle } from "hooks";
import { CancelManufacturingItem } from "../../cancelManufacturingItem/CancelManufacturingItem";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiBottomPanelClose } from "components/miloDesignSystem/atoms/icons/MdiBottomPanelClose";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiStacks } from "components/miloDesignSystem/atoms/icons/MdiStacks";
import { useState } from "react";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { MdiLowPriority } from "components/miloDesignSystem/atoms/icons/MdiLowPriority";
import { MdiFlame } from "components/miloDesignSystem/atoms/icons/MdiFlame";
import { MdiSkull } from "components/miloDesignSystem/atoms/icons/MdiSkull";
import { useGroupElementsColumns } from "./useGroupElementsColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { InfoLabel } from "components/common/infoLabel/InfoLabel";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UserWithInitials } from "api/users/models";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { CancelManufacturingGroupItems } from "../../cancelManufacturingGroupItems/CancelManufacturingGroupItems";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { manufacturingActions } from "api/manufacturing/actions";
import { useManufacturingBoardDrawer } from "./DrawerRenderer";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";
import { OrderTypeChoices } from "api/orders/enums";
import { MdiComment } from "components/miloDesignSystem/atoms/icons/MdiComment";

export const ManufacturingPanel = ({
  columnType,
  downloadLabelFn,
  employeeMutation,
  panel,
  priorityMutation,
}: ManufacturingPanelProps) => {
  const isGroup = panel.type === ManufacturingUnitGroupType.GROUP;

  return (
    <div className="d-flex flex-column w-100 mt-1 overflow-hidden">
      {isGroup ? (
        <GroupPanel columnType={columnType} downloadLabelFn={downloadLabelFn} panel={panel} />
      ) : (
        <UnitPanel
          columnType={columnType}
          downloadLabelFn={downloadLabelFn}
          panel={panel}
          employeeMutation={employeeMutation}
          priorityMutation={priorityMutation}
        />
      )}
    </div>
  );
};

const GroupPanel = ({ columnType, downloadLabelFn, panel }: ManufacturingPanelProps) => {
  const declinedNoteModal = useStateModal<DeclinedNoteState>();
  const [selectedGroupUnits, setSelectedGroupUnits] = useState<NormalizedGroupElement[]>([]);

  return (
    <div className={cx(styles.panelWrapper, "pb-2")}>
      <GroupHeader
        columnType={columnType}
        downloadLabelFn={downloadLabelFn}
        panel={panel}
        setSelectedGroupUnits={setSelectedGroupUnits}
      />
      <GroupBody
        columnType={columnType}
        declinedNoteModal={declinedNoteModal}
        panel={panel}
        selectedGroupUnits={selectedGroupUnits}
        setSelectedGroupUnits={setSelectedGroupUnits}
      />
    </div>
  );
};

const UnitPanel = ({
  columnType,
  downloadLabelFn,
  panel,
  employeeMutation,
  priorityMutation,
}: ManufacturingPanelProps) => {
  return (
    <div className={cx(styles.panelWrapper, "pb-2")}>
      <UnitHeader
        columnType={columnType}
        downloadLabelFn={downloadLabelFn}
        panel={panel}
        priorityMutation={priorityMutation}
      />
      <UnitBody columnType={columnType} employeeMutation={employeeMutation} panel={panel} />
    </div>
  );
};

const UnitHeader = ({
  columnType,
  downloadLabelFn,
  panel,
  priorityMutation,
}: ManufacturingPanelProps) => {
  const priorityOptions = dictToList(
    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict,
  ).map(({ key, value }) => ({
    icon: value.dark.icon,
    text: value.dark.label,
    type: MenuItemType.ICON,
    value: key,
  }));
  const cancelManufacturingItemModal = useToggle();

  const { closeDrawer } = useManufacturingBoardDrawer();

  return (
    <>
      <div
        className={cx(
          styles.panelHeader,
          "d-flex align-items-center justify-content-between gap-2 px-3 py-2",
          {
            [styles.thinHeader]: columnType === ColumnType.READY,
            [styles.wideHeader]:
              columnType === ColumnType.IN_PROGRESS || columnType === ColumnType.TODO,
          },
        )}
      >
        <Typography
          color={
            manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[panel.priority!].dark
              .color
          }
          fontSize="16"
          fontWeight="700"
          noWrap
        >
          {panel.signature}
        </Typography>
        {panel.groupSignature && (
          <Typography color="neutralWhite64" fontSize="14" fontWeight="700" noWrap>
            {panel.groupSignature}
          </Typography>
        )}
        <div className="d-flex align-items-center justify-content-end gap-2">
          {columnType !== ColumnType.READY && priorityMutation && (
            <Select.Async
              items={priorityOptions}
              mutationHook={priorityMutation}
              selected={panel.priority!}
              transformQueryData={priority => ({
                id: panel.id,
                priority: priority as ManufacturingPanelProps["panel"]["priority"],
              })}
              theme="dark"
            />
          )}
          <Tooltip title="Anuluj zlecenie">
            <IconButton
              disabled={panel.isCancelled}
              icon={MdiCancel}
              onClick={cancelManufacturingItemModal.open}
              theme="dark"
              variant="transparent"
            />
          </Tooltip>
          <FileDownloadHandler factoryFn={() => downloadLabelFn(panel)} type="pdf">
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz etykietę zlecenia">
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={download}
                  theme="dark"
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <span className="line-divider" />
          <IconButton
            icon={MdiBottomPanelClose}
            onClick={closeDrawer}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      {cancelManufacturingItemModal.isOpen && panel.manufacturingItemId && panel.signature && (
        <CancelManufacturingItem
          close={cancelManufacturingItemModal.close}
          id={panel.manufacturingItemId}
          signature={panel.signature}
        />
      )}
    </>
  );
};

const GroupHeader = ({
  columnType,
  downloadLabelFn,
  panel,
  setSelectedGroupUnits,
}: ManufacturingPanelProps & GroupHeaderProps) => {
  const isInProgress = columnType === ColumnType.IN_PROGRESS;
  const { closeDrawer } = useManufacturingBoardDrawer();

  return (
    <div
      className={cx(
        styles.panelHeader,
        styles.thinHeader,
        "d-flex align-items-center justify-content-between gap-2 px-3 py-2",
      )}
    >
      <div className={cx(styles.groupHeaderAttributes, "d-flex align-items-center gap-2")}>
        <Typography
          className={styles.groupHeaderSignature}
          color={isInProgress ? "neutralWhite64" : "neutralWhite100"}
          fontSize="16"
          fontWeight="700"
          noWrap
        >
          {isInProgress ? panel.signature : panel.modelName ?? EMPTY_VALUE}
        </Typography>
        {isInProgress && panel.elementsCount && (
          <div className="d-flex align-items-center gap-1">
            <MdiStacks color="neutralWhite100" size="14" />
            <Typography color="neutralWhite100" fontSize="14" fontWeight="600" noWrap>
              {panel.elementsCount}
            </Typography>
          </div>
        )}
        <div className="d-flex align-items-center gap-2 nowrap">
          {panel.attributes?.map(attribute => (
            <Typography
              color="neutralWhite100"
              fontSize="14"
              fontWeight="600"
              key={
                isInProgress
                  ? `inProgress-${attribute.attribute.id}`
                  : `todoGroup-${attribute.attribute.id}`
              }
            >
              {attribute.value.name}
            </Typography>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        <FileDownloadHandler factoryFn={() => downloadLabelFn(panel)} type="pdf">
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz etykiety dla grupy">
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                onClick={download}
                theme="dark"
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <span className="line-divider" />
        <IconButton
          icon={MdiBottomPanelClose}
          onClick={() => {
            closeDrawer();
            setSelectedGroupUnits([]);
          }}
          theme="dark"
          variant="transparent"
        />
      </div>
    </div>
  );
};

const UnitBody = ({ columnType, employeeMutation, panel }: UnitBodyProps) => {
  const isInProgress = columnType === ColumnType.IN_PROGRESS;
  const employees = useSelector(store => store.partials.employees);
  const employeesOptions = employees.map(employee => ({
    icon: employee.avatar,
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.ICON,
    value: employee.id,
  }));
  return (
    <div className="d-flex flex-column px-3 py-2 gap-2">
      <div className="w-100 nowrap">
        {!isInProgress && (
          <Typography fontSize="16" fontWeight="700" noWrap>
            {panel.productName}
          </Typography>
        )}
        <div className="d-flex align-items-center gap-2 nowrap">
          {isInProgress
            ? panel.attributes
                ?.filter(attribute => attribute.category === AttributeCategory.PRODUCT)
                .map(attribute => (
                  <Typography fontSize="16" fontWeight="700" key={attribute.attribute.id} noWrap>
                    {attribute.value.name}
                  </Typography>
                ))
            : panel.attributes?.map(attribute => (
                <Typography fontSize="14" fontWeight="600">
                  {attribute.value.name}
                </Typography>
              ))}
        </div>
        {isInProgress && (
          <div
            className={cx(
              styles.panelAttributes,
              "d-flex align-items-center gap-2 cut-text col-12 pl-0 pr-0",
            )}
          >
            {panel.attributes
              ?.filter(attribute => attribute.category !== AttributeCategory.PRODUCT)
              .map(attribute => (
                <Typography fontSize="14" fontWeight="600" key={attribute.attribute.id}>
                  {attribute.value.name}
                </Typography>
              ))}
          </div>
        )}
      </div>
      <InfoLabel title="zamówienie">
        <div className="d-flex align-items-center gap-2">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {panel.orderSignature || EMPTY_VALUE}{" "}
          </Typography>
          {panel.isComplaint && <MdiClaim color="magenta200" size="16" />}
        </div>
      </InfoLabel>
      <InfoLabel title="nr. zlec. klienta">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
          {panel.externalOrderNumber || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="zrealizować do">
        <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
          {panel.scheduledAt ? dateUtils.formatDateToDisplay(panel.scheduledAt) : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      {panel.implementedBy === ImplementedBy.CONTRACTOR && panel.manufacturer && (
        <InfoLabel title="realizacja">
          <Typography fontSize="14" fontWeight="700" noWrap>
            {panel.manufacturer.name}
          </Typography>
        </InfoLabel>
      )}
      {panel.itemNote && (
        <InfoLabel title="uwgai to pozycji zamówienia">
          <MdiComment size="14" />
          <Typography fontSize="14" fontWeight="700">
            {panel.itemNote}
          </Typography>
        </InfoLabel>
      )}
      {(isInProgress || columnType === ColumnType.READY) &&
        panel.implementedBy !== ImplementedBy.CONTRACTOR &&
        employeeMutation && (
          <InfoLabel title="pracownik">
            <Select.Async
              items={employeesOptions}
              mutationHook={employeeMutation}
              selected={panel.employee?.id ?? null}
              transformQueryData={employee => {
                const searchedEmployee = employees.find(_employee => _employee.id === employee);
                assertIsDefined(searchedEmployee);
                return {
                  id: panel.id,
                  employee: searchedEmployee as UserWithInitials,
                };
              }}
            />
          </InfoLabel>
        )}
      {columnType === ColumnType.READY && (
        <InfoLabel title="wykonano">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {panel.finishedAt ? dateFns.formatRelative(new Date(panel.finishedAt)) : EMPTY_VALUE}
          </Typography>
        </InfoLabel>
      )}
      {panel.isDeclined && (
        <div className="d-flex flex-column gap-2 py-2">
          <InfoLabel title="Do poprawy">
            <div />
          </InfoLabel>
          <div className={styles.declinedNote}>
            <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
              {panel.note || EMPTY_VALUE}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

const GroupBody = ({
  columnType,
  declinedNoteModal,
  panel,
  selectedGroupUnits,
  setSelectedGroupUnits,
}: Omit<ManufacturingPanelProps, "downloadLabelFn"> & GroupBodyProps) => {
  const isInProgress = columnType === ColumnType.IN_PROGRESS;
  const search = queryString.stringify({
    manufacturingWorkingUnitGroup: panel.id,
  });
  const {
    data: groupedUnits,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingUnitsActions.useGetGroupedItems(search, {
    enabled: Boolean(isInProgress && panel.type === ManufacturingUnitGroupType.GROUP),
  });

  const groupedElements = isInProgress
    ? normalizeInProgressGroupedUnits(groupedUnits)
    : panel.groupElements || [];

  const columns = useGroupElementsColumns(
    groupedElements,
    selectedGroupUnits,
    setSelectedGroupUnits,
    declinedNoteModal,
  );
  const cancelGroupItemsModal = useToggle();

  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-2 px-3 py-2">
        {Boolean(selectedGroupUnits.length) && (
          <div className="d-flex align-items-center gap-1">
            <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
              wybrano:
            </Typography>
            <Typography color="neutralBlack88" fontSize="14" fontWeight="800">
              {selectedGroupUnits.length}
            </Typography>
          </div>
        )}
        {columnType === ColumnType.TODO && (
          <TodoGroupActions
            openCancellationModal={cancelGroupItemsModal.open}
            panel={panel}
            selectedGroupUnits={selectedGroupUnits}
            setSelectedGroupUnits={setSelectedGroupUnits}
          />
        )}
        {columnType === ColumnType.IN_PROGRESS && (
          <InProgressGroupActions
            openCancellationModal={cancelGroupItemsModal.open}
            selectedGroupUnits={selectedGroupUnits}
            groupUnitsCount={groupedUnits.length}
          />
        )}
      </div>

      <div className={styles.groupElementsTableWrapper}>
        <Table
          columns={columns}
          rows={
            isInProgress ? normalizeInProgressGroupedUnits(groupedUnits) : panel.groupElements || []
          }
          isLoading={isInProgress ? isLoading || isPreviousData : false}
          error={isInProgress ? error : null}
          uiSchema={comfortableListUiSchema}
          overrides={() => {
            return {
              hideHeader: isInProgress ? !groupedUnits.length : undefined,
              noResultComponent: isInProgress ? <EmptySection label="Brak zleceń" /> : undefined,
              row: row => {
                return {
                  className: cx({
                    dashedTableRow: row.isCancelled,
                    [styles.skullUrgent]: row.priority === ManufacturingPriority.A,
                    [styles.flameUrgent]: row.priority === ManufacturingPriority.B,
                    [styles.isComplaint]: row.isComplaint,
                  }),
                };
              },
            };
          }}
        />
      </div>

      {cancelGroupItemsModal.isOpen && (
        <CancelManufacturingGroupItems
          close={cancelGroupItemsModal.close}
          ids={selectedGroupUnits.map(groupElement => groupElement.id)}
        />
      )}
    </>
  );
};

const TodoGroupActions = ({
  openCancellationModal,
  panel,
  selectedGroupUnits,
  setSelectedGroupUnits,
}: TodoGroupOptionsProps) => {
  const { updateQuery } = useQuery();
  const stageId = useStageId();
  const criticalPriorityMutation = manufacturingActions.useBulkUpdateManufacturingItemEntryPriority();
  const highPriorityMutation = manufacturingActions.useBulkUpdateManufacturingItemEntryPriority();
  const normalPriorityMutation = manufacturingActions.useBulkUpdateManufacturingItemEntryPriority();
  const groupManufacturingUnitsMutation = manufacturingUnitsActions.useGroupManufacturingUnits();

  const groupAndMoveToInProgress = () => {
    return groupManufacturingUnitsMutation.mutate(
      {
        attributes: [
          {
            attribute: {
              id: null,
              name: "",
            },
            category: AttributeCategory.PRODUCT,
            value: {
              id: null,
              name: panel.modelName ?? "",
            },
          },
          ...selectedGroupUnits.flatMap(group =>
            group.attributeValues
              ? group.attributeValues?.map(attr => ({
                  attribute: {
                    id: Number(attr.attribute.id),
                    name: attr.attribute.name,
                  },
                  category: attr.kind ?? null,
                  value: {
                    id: Number(attr.value.id),
                    name: attr.value.name,
                  },
                }))
              : [],
          ),
        ],
        manufacturingWorkingUnitIds: selectedGroupUnits.map(groupElement => groupElement.id),
        schema_stage_id: stageId,
      },
      {
        onSuccess: () => {
          if (!Boolean(panel.groupElements!.length - selectedGroupUnits.length)) {
            updateQuery({ unitPanelId: "" });
          }
          setSelectedGroupUnits([]);
        },
      },
    );
  };

  return (
    <div className="d-flex align-items-center w-100 justify-content-end gap-2">
      <Button
        className="text-uppercase"
        disabled={!Boolean(selectedGroupUnits.length)}
        onClick={groupAndMoveToInProgress}
        size="small"
        variant="gray"
      >
        Zgrupuj i przenieś do "w trakcie"
      </Button>
      <Tooltip title="Ustaw priorytet: 'Zwykły'">
        <IconButton
          disabled={!Boolean(selectedGroupUnits.length)}
          icon={MdiLowPriority}
          isLoading={normalPriorityMutation.isLoading}
          onClick={() =>
            normalPriorityMutation.mutate({
              ids: selectedGroupUnits.map(groupElement => groupElement.manufacturingItemId),
              priority: ManufacturingPriority.C,
            })
          }
          variant="transparent"
        />
      </Tooltip>
      <Tooltip title="Ustaw priorytet: 'Pilny'">
        <IconButton
          disabled={!Boolean(selectedGroupUnits.length)}
          icon={MdiFlame}
          isLoading={highPriorityMutation.isLoading}
          onClick={() =>
            highPriorityMutation.mutate({
              ids: selectedGroupUnits.map(groupElement => groupElement.manufacturingItemId),
              priority: ManufacturingPriority.B,
            })
          }
          variant="transparent"
        />
      </Tooltip>
      <Tooltip title="Ustaw priorytet: 'Krytyczny'">
        <IconButton
          disabled={!Boolean(selectedGroupUnits.length)}
          icon={MdiSkull}
          isLoading={criticalPriorityMutation.isLoading}
          onClick={() =>
            criticalPriorityMutation.mutate({
              ids: selectedGroupUnits.map(groupElement => groupElement.manufacturingItemId),
              priority: ManufacturingPriority.A,
            })
          }
          variant="transparent"
        />
      </Tooltip>
      <Tooltip title="Anuluj zlecenia">
        <IconButton
          disabled={!Boolean(selectedGroupUnits.length)}
          icon={MdiCancel}
          onClick={openCancellationModal}
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};

const InProgressGroupActions = ({
  openCancellationModal,
  selectedGroupUnits,
}: InProgressGroupOptionsProps) => {
  const setUnitsAsFinishedMutation = manufacturingUnitsActions.useSetAsFinished();

  return (
    <div className="d-flex align-items-center w-100 justify-content-end gap-2">
      <Button
        className="text-uppercase"
        isLoading={setUnitsAsFinishedMutation.isLoading}
        disabled={!Boolean(selectedGroupUnits.length)}
        onClick={() =>
          setUnitsAsFinishedMutation.mutate({
            manufacturingWorkingUnitsIds: selectedGroupUnits.map(groupElement => groupElement.id),
          })
        }
        size="small"
        variant="gray"
      >
        Przenieś do "gotowe"
      </Button>
      <Tooltip title="Anuluj zlecenia">
        <IconButton
          disabled={!Boolean(selectedGroupUnits.length)}
          icon={MdiCancel}
          onClick={openCancellationModal}
          variant="transparent"
        />
      </Tooltip>
    </div>
  );
};

const normalizeInProgressGroupedUnits = (
  groupedUnits: GroupedUnitInProgress[],
): NormalizedGroupElement[] => {
  return groupedUnits.map(groupedUnit => ({
    id: groupedUnit.id,
    attributes: groupedUnit.attributes
      .filter(attribute => attribute.category === AttributeCategory.FABRIC)
      .map(attribute => attribute.value.name)
      .join(", "),
    attributeValues: [],
    isCancelled: groupedUnit.isCancelled,
    itemNote: groupedUnit.manufacturingItem.itemNote,
    manufacturingItemId: groupedUnit.manufacturingItem.id,
    isComplaint: groupedUnit.order?.type === OrderTypeChoices.COMPLAINT,
    isDeclined: groupedUnit.isDeclined,
    note: groupedUnit.note,
    orderSignature: groupedUnit.order?.signature || null,
    priority: groupedUnit.priority,
    signature: groupedUnit.signature,
    externalOrderNumber: groupedUnit.manufacturingItem.externalOrderNumber,
  }));
};
