import { shippingActions } from "api/shipping/actions";
import { Courier } from "api/shipping/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  courier: Courier;
}

export const PanelHeader = ({ courier }: Props) => {
  const { close } = useDrawer("shippingCourier");
  const { isTriggerElementHidden } = useScrollableContext();
  const shippingCheckConnectionMutation = shippingActions.usePostShippingCheckConnection();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-2">
        <Button
          size="small"
          variant="gray"
          className="text-uppercase"
          isLoading={shippingCheckConnectionMutation.isLoading}
          onClick={() => {
            shippingCheckConnectionMutation.mutate(courier.id);
          }}
        >
          Sprawdź połączenie
        </Button>

        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="noWrap">{courier.name}</SlidingHeaderWrapper>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
