import { useQuery, useSelector } from "hooks";
import { useRouteViewState } from "../routeCreatorState";
import { InputQuery } from "components/utils/queryProvider";
import styles from "./ExpressServiceFilterBar.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { expressServicesUtils } from "pages/logistics/expressServices/utils/expressServicesUtils";
import { useCheckboxFilter } from "../hooks/useCheckboxFilter";
import { MdiAvgPace } from "components/miloDesignSystem/atoms/icons/MdiAvgPace";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { useState } from "react";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { dictToList } from "utilities";
import { useNavigate } from "hooks/useNavigate";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";

const typePriority = { A: 1, B: 2, C: 3 };

export const ExpressServiceFilterBar = () => {
  const { updateQuery, query } = useQuery();
  const actions = useRouteViewState("slave", state => state.actions);
  const expressServices = useSelector(store => store.partials.expressServices);
  const { isChecked, updateCheckbox } = useCheckboxFilter("expressServices");
  const [value, setValue] = useState(query["expressServiceHoursLeft"] || "");
  const navigate = useNavigate();

  const customUpdateQuery = (args: InputQuery) => {
    if (
      args["expressServiceHoursLeft"] !== query["expressServiceHoursLeft"] &&
      (args["expressServiceHoursLeft"] || Boolean(value))
    )
      actions.openLoader("Trwa wczytywanie zamówień");
    updateQuery(args);
  };

  const existingServicesTypes = dictToList(typePriority).filter(({ key: serviceType }) =>
    expressServices.find(service => service.type === serviceType),
  );

  const existingServices = expressServices
    .filter(service =>
      existingServicesTypes.some(({ key: serviceType }) => service.type === serviceType),
    )
    .sort((a, b) => a.value - b.value);

  return (
    <div className={styles.filterBar}>
      {existingServices.map(existingService => (
        <div
          className="cursor-pointer d-flex align-items-center"
          key={existingService.id}
          onClick={() => updateCheckbox(!isChecked(existingService.id), existingService.id)}
        >
          <Tag
            label={existingService.name}
            startIcon={expressServicesUtils
              .getServiceDetailsBasedOnType(existingService.type)
              .icon({
                color: isChecked(existingService.id) ? "deepPurple700" : "neutralBlack100",
                size: "16",
              })}
            type={isChecked(existingService.id) ? "filled" : "outlined"}
            variant={isChecked(existingService.id) ? "deepPurple50" : "quaternary"}
          />
        </div>
      ))}
      {dictToList(typePriority)
        .filter(
          ({ key: serviceType }) => !expressServices.find(service => service.type === serviceType),
        )
        .map(({ key: serviceType }) => {
          return (
            <div className="d-flex align-items-center" key={serviceType}>
              <Tag
                label=""
                startIcon={expressServicesUtils.getServiceDetailsBasedOnType(serviceType).icon({
                  color: "neutralBlack48",
                  size: "16",
                })}
                type="outlined"
                variant="quaternary"
              />
            </div>
          );
        })}
      <IconButton
        icon={MdiAdd}
        onClick={() => navigate("/logistics/express-services/list/all")}
        size="medium"
        variant="gray"
      />
      <div className="d-flex align-items-center gap-1 ml-2">
        <MdiAvgPace size="16" />
        <TextField
          containerClassName={styles.input}
          EndInputSection={
            query["expressServiceHoursLeft"] ? (
              <div className="d-flex align-items-center">
                <IconButton
                  icon={MdiClose}
                  onClick={event => {
                    event.stopPropagation();
                    setValue("");
                    customUpdateQuery({
                      expressServiceHoursLeft: "",
                    });
                  }}
                  size="small"
                  variant="transparent"
                />
              </div>
            ) : (
              undefined
            )
          }
          onBlur={() =>
            customUpdateQuery({
              expressServiceHoursLeft: value,
            })
          }
          onChange={event => setValue(event.target.value)}
          size="small"
          value={value}
        />
        <Typography fontSize="10" fontWeight="600">
          h
        </Typography>
      </div>
    </div>
  );
};
