import { useState, useCallback, useEffect } from "react";
import { logbookActions, useLogs } from "hooks/apiPrimitives";
import { arrayToDict, queryString } from "utilities";
import { useQuery } from "components/utils/queryProvider/useQuery";
import { Log } from "api/logbook/models";
import { useFilters } from "./useFilters";
import { InputQuery } from "components/utils/queryProvider";

type SearchParam =
  | "order"
  | "productionOrder"
  | "orderGroup"
  | "route"
  | "uniquePackage"
  | "tradingDocument";

export type GetSearchProps = {
  sourceType: SearchParam;
  id: string | number;
  categoryCode: string;
  query: {
    [x: string]: string;
  };
};

export function getSearch({ query, sourceType, id, categoryCode }: GetSearchProps) {
  return queryString.stringify({
    ...query,
    category: categoryCode || "",
    [sourceType]: id,
  });
}

export function useInfiniteLogs(id: number | string, searchParam: SearchParam) {
  const [allLogs, setAllLogs] = useState<Record<Log["id"], Log>>({});
  const { query, updateQuery } = useQuery();
  const search = getSearch({
    query,
    id,
    sourceType: searchParam,
    categoryCode: query.category,
  });
  const [logs, { pagination, inProgress, error, isPristine }] = useLogs(search, {});

  useEffect(() => {
    if (query.page !== "1") {
      setAllLogs(prev => ({ ...prev, ...arrayToDict(logs) }));
    } else {
      setAllLogs(arrayToDict(logs));
    }
  }, [logs, query]);

  useEffect(() => {
    updateQuery({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAllLogs({});
    updateQuery({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchMore = () => {
    if (pagination.next) {
      updateQuery({ page: pagination.next });
    }
  };

  const fm = useCallback(fetchMore, [pagination, updateQuery]);

  return {
    fetchMore: fm,
    logs: Object.values(allLogs).sort((a, b) => (a.id < b.id ? 1 : -1)),
    inProgress,
    isPristine,
    error,
    hasMore: pagination.next !== null,
  };
}

export function useStateInfiniteLogs(
  id: number | string,
  searchParam: SearchParam,
  query: InputQuery,
) {
  const [allLogs, setAllLogs] = useState<Record<Log["id"], Log>>({});
  const { searchParams, setFilter, setFilters, filters } = useFilters({
    [searchParam]: id,
    page: 1,
    ...query,
  });
  const { pagination, isLoading: inProgress, error } = logbookActions.useLogsQuery(searchParams, {
    onSuccess: payload => {
      if (filters.page !== 1) {
        setAllLogs(prev => ({ ...prev, ...arrayToDict(payload) }));
      } else {
        setAllLogs(arrayToDict(payload));
      }
    },
  });

  useEffect(() => {
    setFilters({ ...filters, ...query });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchMore = () => {
    if (pagination.next) {
      setFilter("page", pagination.next);
    }
  };

  const fm = useCallback(fetchMore, [pagination, setFilter]);

  return {
    fetchMore: fm,
    logs: Object.values(allLogs).sort((a, b) => (a.id < b.id ? 1 : -1)),
    inProgress,
    error,
    hasMore: pagination.next !== null,
  };
}
