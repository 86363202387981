import { sptApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { sptKeys } from "./keys";
import { createApiQuery } from "hooks/createApiQuery";
import { assertIsDefined } from "utilities/assertIsDefined";

const useDefaults = createApiQuery(sptApi.getDefaults);
const useDetails = createApiQuery(sptApi.getDetails);

const usePatchDefaults = () => {
  return useMutation(sptApi.patchDefaults, ({ queryUtils }) => ({
    onMutate: ({ id, toUpdate }) => {
      const prevDetails = queryUtils.handleMutate(sptKeys.defaults(id), toUpdate);
      return { prevDetails };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(sptKeys.defaults(id), onMutateReturn.prevDetails, error);
    },
  }));
};

const usePatchDetails = () => {
  return useMutation(sptApi.patchDetails, ({ queryUtils }) => ({
    onMutate: ({ shipments, ...toUpdate }) => {
      const prevDetails = queryUtils.handleMutate(sptKeys.details(shipments[0]), toUpdate);
      return { prevDetails };
    },
    onError: (error, { shipments }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(sptKeys.details(shipments[0]), onMutateReturn.prevDetails, error);
    },
  }));
};

export const sptActions = {
  usePatchDefaults,
  useDefaults,
  useDetails,
  usePatchDetails,
};
