import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { NormalizedLineItem } from "../useLineItemColumns";
import { MdiArrowOutward } from "components/miloDesignSystem/atoms/icons/MdiArrowOutward";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  lineItem: NormalizedLineItem;
  showConnectedWithAnotherWhEntry: boolean;
}

export const ReceptionsConnectionWithAnotherWhEntry = ({
  lineItem,
  showConnectedWithAnotherWhEntry,
}: Props) => {
  if (
    showConnectedWithAnotherWhEntry &&
    lineItem.subRows.some(subRow => subRow.connectedWithAnotherWhEntry.length > 0)
  ) {
    const connectedWithAnotherWhEntry = lineItem.subRows.flatMap(subRow =>
      subRow.connectedWithAnotherWhEntry.map(receivedInAnotherEntry => receivedInAnotherEntry),
    );

    return (
      <div className="d-flex align-items-center gap-1">
        <IconRenderer color="neutralBlack100" icon={MdiArrowOutward} size={14} />
        <Link
          fontSize="10"
          fontWeight="500"
          to={`/finances/receptions/list/all?panelId=${connectedWithAnotherWhEntry[0].id}`}
        >
          {connectedWithAnotherWhEntry[0].signature}
        </Link>
      </div>
    );
  }

  if (lineItem.order)
    return (
      <Link
        fontSize="10"
        fontWeight="500"
        to={`/orders/list/active/all?panelId=${lineItem.order.id}`}
      >
        {lineItem.order.signature}
      </Link>
    );

  return (
    <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
      (na magazyn)
    </Typography>
  );
};
