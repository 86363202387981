import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiPackagePrice = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 19V8H8V16L11 14.5V12.25L10 12.75V8H14V12H16V8H19V12H21V6.525C21 6.29167 20.9625 6.06667 20.8875 5.85C20.8125 5.63333 20.7 5.43333 20.55 5.25L19.3 3.725C19.1167 3.49167 18.8875 3.3125 18.6125 3.1875C18.3375 3.0625 18.05 3 17.75 3H6.25C5.95 3 5.6625 3.0625 5.3875 3.1875C5.1125 3.3125 4.88333 3.49167 4.7 3.725L3.45 5.25C3.3 5.43333 3.1875 5.63333 3.1125 5.85C3.0375 6.06667 3 6.29167 3 6.525V19C3 19.55 3.19583 20.0208 3.5875 20.4125C3.97917 20.8042 4.45 21 5 21H11V19H5ZM12.5 12L12 11.75L11.5 12H12.5ZM18.6 6H5.4L6.25 5H17.75L18.6 6Z"
    />
    <path d="M14 21C13.725 21 13.4896 20.9021 13.2938 20.7063C13.0979 20.5104 13 20.275 13 20V15C13 14.725 13.0979 14.4896 13.2938 14.2938C13.4896 14.0979 13.725 14 14 14H22C22.275 14 22.5104 14.0979 22.7063 14.2938C22.9021 14.4896 23 14.725 23 15V20C23 20.275 22.9021 20.5104 22.7063 20.7063C22.5104 20.9021 22.275 21 22 21H14ZM14.5 19.5H21.5V15.5H14.5V19.5ZM19.5 19.5H21.5V17.5H20.75V18.75H19.5V19.5ZM18 19C18.4167 19 18.7708 18.8542 19.0625 18.5625C19.3542 18.2708 19.5 17.9167 19.5 17.5C19.5 17.0833 19.3542 16.7292 19.0625 16.4375C18.7708 16.1458 18.4167 16 18 16C17.5833 16 17.2292 16.1458 16.9375 16.4375C16.6458 16.7292 16.5 17.0833 16.5 17.5C16.5 17.9167 16.6458 18.2708 16.9375 18.5625C17.2292 18.8542 17.5833 19 18 19ZM14.5 17.5H15.25V16.25H16.5V15.5H14.5V17.5Z" />
  </SvgWrapper>
);
