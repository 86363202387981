import styled from "@emotion/styled";
import { OrderForRoute } from "api/orders/models";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { expressServicesUtils } from "pages/logistics/expressServices/utils/expressServicesUtils";
import { ordersUtils } from "utilities/orders";

interface Props {
  order: OrderForRoute;
}

export const ExpressServiceSection = ({ order }: Props) => {
  if (!order.expressService) return null;

  const serviceDetailsByType = expressServicesUtils.getServiceDetailsBasedOnType(
    order.expressService.type,
  );

  return (
    <div className="d-flex flex-wrap m-0 row">
      <StyledExpressServiceSection backgroundColor="neutralWhite100">
        <Typography fontSize="12" fontWeight="600">
          Przesyłka ekspresowa:
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Tag.WithCustomColor
            backgroundColor={serviceDetailsByType.backgroundColor}
            label={order.expressService.name}
            startIcon={serviceDetailsByType.icon({
              color: serviceDetailsByType.textColor,
              size: "16",
            })}
            textColor={serviceDetailsByType.textColor}
          />
          {order.expressServiceExpireDate && (
            <Typography fontSize="10" fontWeight="600">
              {ordersUtils.getExpressServiceRemainingTime(order.expressServiceExpireDate)}
            </Typography>
          )}
        </div>
      </StyledExpressServiceSection>
    </div>
  );
};

const StyledExpressServiceSection = styled.div<{
  backgroundColor: ColorPalette;
}>`
  backgroundcolor: ${props => colorPalette[props.backgroundColor]};
  border: 1px solid var(--neutralBlack16);
  border-radius: 4px;
  padding: 8px;
  gap: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;
