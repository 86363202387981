import { Partials } from "api/other/models";
import { UUID } from "api/types";
import { CreateInvoiceValues } from "../CreateInvoicesForMultipleOrdersModal";

export const getInitialValues = (
  customer: Partials["customers"][number] | undefined,
  orders: CreateInvoiceValues[],
  sellerId: UUID,
): {
  seller: string;
  orders: CreateInvoiceValues[];
  customer: string | number;
} => {
  return {
    customer: customer ? customer.id : "",
    orders: orders,
    seller: sellerId ? sellerId : "",
  };
};
