import { TradingDocument } from "api/trading-documents/models";
import { RightPanelTableRow } from "components/utils/drawer";
import { currencyConvertSectionListConfig } from "pages/tradingDocuments/shared/utils/panelTablesConfig";
import { dateFns } from "utilities";
import { CreateInvoiceValues } from "../CreateInvoicesForMultipleOrdersModal";
import { AmountForVatRate } from "./AmountForVatRate";

interface Props {
  currencyConvert: TradingDocument["amountSummaryPerVatRateCurrencyConvert"][number];
  orders: CreateInvoiceValues[];
}

export const CurrencyConvertionSection = ({ currencyConvert, orders }: Props) => {
  return (
    <RightPanelTableRow grid={currencyConvertSectionListConfig.grid}>
      <div>
        <div className="d-flex align-items-center gap-1">
          {currencyConvert.exchangeRate ? (
            <>
              <strong className="body-14 fw-800">
                {String(currencyConvert.exchangeRate).replace(/\./g, ",")}&nbsp;
              </strong>
              <span className="body-12 text-black-6">PLN</span>
            </>
          ) : (
            "--"
          )}
        </div>
      </div>
      <div>
        {currencyConvert.exchangeRateDate ? (
          <div>{dateFns.format(new Date(currencyConvert.exchangeRateDate), "dd.MM.yyyy")}</div>
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end text-grey-35">w tym:</div>
      {currencyConvert.vatRate !== undefined && currencyConvert.vatRate !== null ? (
        <div className="fw-700">{currencyConvert.vatRate}%</div>
      ) : (
        "--"
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountForVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="amountWithoutTax"
            orders={orders}
            vatRate={currencyConvert.vatRate}
          />
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountForVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="taxAmountBeforeRounding"
            orders={orders}
            vatRate={currencyConvert.vatRate}
          />
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        {currencyConvert.vatRate !== undefined &&
        currencyConvert.vatRate !== null &&
        currencyConvert.exchangeRate ? (
          <AmountForVatRate
            conversionRate={currencyConvert.exchangeRate}
            fieldName="amountWithTax"
            orders={orders}
            vatRate={currencyConvert.vatRate}
          />
        ) : (
          "--"
        )}
      </div>
    </RightPanelTableRow>
  );
};
