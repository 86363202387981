import { PageHeader } from "components/common";
import styles from "../StageBoard.module.css";
import { cx, dictToList, queryString } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery, useRedux, useSelector } from "hooks";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiBoard } from "components/miloDesignSystem/atoms/icons/MdiBoard";
import { MdiTable } from "components/miloDesignSystem/atoms/icons/MdiTable";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiKingBed } from "components/miloDesignSystem/atoms/icons/MdiKingBed";
import { MdiArrowRange } from "components/miloDesignSystem/atoms/icons/MdiArrowRange";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { CommonError } from "components/utils";
import { BoardFormat } from "api/manufacturing/schemas/models";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { manufacturingActions } from "api/manufacturing/actions";
import { productionOrders } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/productionOrders";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { useNavigate } from "hooks/useNavigate";
import { MdiSide } from "components/miloDesignSystem/atoms/icons/MdiSide";
import { useStageId } from "../../hooks/useStageId";
import { Search } from "components/miloDesignSystem/molecules/search";
import { useManufacturingBoardDrawer } from "../columnView/panel/DrawerRenderer";
import { ColumnType } from "../columnView/ColumnView";
import { manufacturingUnitsApi } from "api/manufacturing/units/api";
import { ManufacturingUnitStatus } from "api/manufacturing/units/enums";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { TicketIcon } from "../columnView/shared/manufacturingTicket/ManufacturingTicket";
import { manufacturingUnitConstants } from "constants/manufacturingUnitConstants";
import { useGetTodoGroups } from "../columnView/hooks/useColumnDropService";

interface Props {
  isListOfAllUnits?: boolean;
}

export const Header = ({ isListOfAllUnits }: Props) => {
  const activeViewType = useSelector(
    store => store.ui.viewTypes.manufacturingStageBoard ?? "board",
  );
  const stages = useSelector(store => store.partials.stages);
  const stagesTabDict = stages.reduce(
    (acc, stage) => {
      return {
        ...acc,
        [stage.id]: stage.name,
      };
    },
    { all: "Wszystkie" },
  );
  const { setQuery } = useQuery();
  const [dispatch, { ui }] = useRedux();
  const navigate = useNavigate();
  const stageId = useStageId();
  const manufacturingStages = useSelector(store => store.partials.stages);
  const manufacturingStage = manufacturingStages.find(stage => stage.id === stageId);
  const {
    data: manufacturingItemCount,
    error: countError,
    isLoading: countIsLoading,
  } = manufacturingUnitsActions.useStatistics(
    {
      stageId,
      search: "",
    },
    {
      enabled: Boolean(stageId),
    },
  );
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId === "all" ? "" : stageId,
    }),
  );
  const { toggleDrawer } = useManufacturingBoardDrawer();
  const getTodoGroups = useGetTodoGroups();
  return (
    <PageHeader
      bottomBarTitle={
        activeViewType === "board" && !isListOfAllUnits ? (
          <div
            className={cx(
              styles.subtoolbar,
              "d-flex align-items-center justify-content-between w-100",
            )}
          >
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="16" fontWeight="600">
                Pula zleceń:
              </Typography>
              {countIsLoading && <Spinner size={16} />}
              {countError && <CommonError status={countError._httpStatus_} />}
              {manufacturingItemCount && (
                <Typography color="deepPurple400" fontSize="18" fontWeight="700">
                  {manufacturingItemCount.counts.notStarted +
                    manufacturingItemCount.counts.inProgress}
                </Typography>
              )}
              <Search
                fetcherFn={manufacturingUnitsApi.getUnitItems}
                isNullable={false}
                transformQuery={{ masterStages: stageId }}
                onChange={manufacturingItem => {
                  if (manufacturingItem.status === ManufacturingUnitStatus.FINISHED) {
                    toggleDrawer({
                      columnType: ColumnType.READY,
                      id: manufacturingItem.id,
                      type: "unitItem",
                    });
                  }
                  if (manufacturingItem.status === ManufacturingUnitStatus.NOT_STARTED) {
                    const todoGroups = getTodoGroups();
                    const todoGroup = todoGroups.find(group =>
                      group.elements.some(element => element.id === manufacturingItem.id),
                    );
                    if (todoGroup) {
                      toggleDrawer({
                        columnType: ColumnType.TODO,
                        id: todoGroup.id as string,
                        type: "group",
                      });
                    }
                  }

                  if (manufacturingItem.status === ManufacturingUnitStatus.IN_PROGRESS) {
                    toggleDrawer({
                      columnType: ColumnType.IN_PROGRESS,
                      id: manufacturingItem.manufacturingWorkingUnitGroup || manufacturingItem.id,
                      type: manufacturingItem.manufacturingWorkingUnitGroup ? "group" : "unitItem",
                    });
                  }
                }}
                normalizeItem={item => {
                  const isPrioritized =
                    item.priority && manufacturingStagesUtils.isUnitUrgent(item.priority);
                  return {
                    type: MenuItemType.CUSTOM_RENDERER,
                    value: item.id,
                    renderer: () => (
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="d-flex gap-2">
                            {manufacturingStagesConstants.attributeCategoryIconsDict["PRODUCT"]}
                            <Typography fontSize="14" fontWeight="700" noWrap>
                              {item.name}
                            </Typography>
                            {item.attributeValues
                              .filter(attribute => attribute.value.id || attribute.value.name)
                              .map(attribute => (
                                <div
                                  className="d-flex align-items-center gap-1"
                                  key={`todo-section-group-attribute-${attribute.attribute.id}`}
                                >
                                  {
                                    manufacturingStagesConstants.attributeCategoryIconsDict[
                                      attribute.category
                                    ]
                                  }
                                  <Typography fontSize="14" fontWeight="600" noWrap>
                                    {attribute.value.name}
                                  </Typography>
                                </div>
                              ))}
                          </div>

                          <div className="d-flex align-items-center gap-2">
                            {item.manufacturingItem.signature && (
                              <Typography
                                className="flex-shrink-1"
                                color={
                                  item.priority
                                    ? manufacturingStagesConstants
                                        .manufacturingUnitGroupPriorityDict[item.priority].light
                                        .color
                                    : "neutralBlack100"
                                }
                                fontSize="14"
                                fontWeight="600"
                                noWrap
                              >
                                {item.manufacturingItem.signature}
                              </Typography>
                            )}
                            {isPrioritized && (
                              <div className="d-flex align-items-center gap-2">
                                <TicketIcon priority={item.priority!}>
                                  {item.priority
                                    ? manufacturingStagesConstants
                                        .manufacturingUnitGroupPriorityDict[item.priority].light
                                        .icon
                                    : null}
                                </TicketIcon>
                              </div>
                            )}
                            <div>
                              {manufacturingUnitConstants.getStatusContants(item.status).tag}
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  };
                }}
                textFieldProps={{
                  placeholder: "Szukaj zlecenia...",
                  containerClassName: styles.search,
                }}
              />
            </div>
            <div className="d-flex align-items-center gap-2">
              <Button
                className="text-uppercase"
                endIcon={MdiArrowForward}
                onClick={() => navigate(`/manufacturing/production-orders/demands/${stageId}`)}
                size="small"
                variant="gray"
              >
                Pokaż potrzebne materiały
              </Button>
              {activeViewType === "board" && manufacturingStage?.boardFormat === BoardFormat.GROUP && (
                <div className="d-flex align-items-center gap-2">
                  <span className="line-divider" style={{ height: 26 }} />
                  <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
                    Grupuj:
                  </Typography>
                  <div className="d-flex align-items-center gap-1">
                    <div className="cursor-not-allowed d-flex align-items-center">
                      <Tag
                        label="Model"
                        startIcon={<MdiKingBed size="14" />}
                        type={
                          manufacturingStage?.boardFormat === BoardFormat.GROUP
                            ? "filled"
                            : "outlined"
                        }
                        variant={
                          manufacturingStage?.boardFormat === BoardFormat.GROUP
                            ? "deepPurple400"
                            : "quaternary"
                        }
                      />
                    </div>
                    <Tag
                      label="Rozmiar"
                      startIcon={<MdiArrowRange size="14" />}
                      type={
                        manufacturingStagesUtils.hasSize(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasSize(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                    <Tag
                      label="materiały"
                      startIcon={<MdiFabric size="14" />}
                      type={
                        manufacturingStagesUtils.hasFabric(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasFabric(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                    <Tag
                      label="strona"
                      startIcon={<MdiSide size="14" />}
                      type={
                        manufacturingStagesUtils.hasSide(defaultFilters!) ? "filled" : "outlined"
                      }
                      variant={
                        manufacturingStagesUtils.hasSide(defaultFilters!)
                          ? "deepPurple400"
                          : "quaternary"
                      }
                    />
                  </div>
                </div>
              )}
              <span className="line-divider" style={{ height: 26 }} />
              <div className="d-flex align-items-center gap-1 pt-1">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "board" }))
                  }
                >
                  <Tag
                    label=""
                    startIcon={<MdiBoard size="16" />}
                    type={activeViewType === "board" ? "filled" : "outlined"}
                    variant={activeViewType === "board" ? "deepPurple400" : "quaternary"}
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
                    setQuery({
                      stageStatus: "READY",
                    });
                  }}
                >
                  <Tag
                    label=""
                    startIcon={<MdiTable size="16" />}
                    type="outlined"
                    variant="quaternary"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          undefined
        )
      }
      bottomBarButtons={
        activeViewType === "list" && !isListOfAllUnits ? (
          <div className="d-flex align-items-center gap-2">
            <Button
              className="text-uppercase"
              endIcon={MdiArrowForward}
              onClick={() => navigate(`/manufacturing/production-orders/demands/${stageId}`)}
              size="small"
              variant="gray"
            >
              Pokaż potrzebne materiały
            </Button>
            <span className="line-divider" style={{ height: 26 }} />
            <div className="d-flex align-items-center gap-1 pt-1">
              <div
                className="cursor-pointer"
                onClick={() => {
                  dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "board" }));
                  setQuery({});
                }}
              >
                <Tag
                  label=""
                  startIcon={<MdiBoard size="16" />}
                  type="outlined"
                  variant="quaternary"
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  dispatch(ui.toggleViewType({ type: "manufacturingStageBoard", value: "list" }));
                  setQuery({
                    stageStatus: "READY",
                  });
                }}
              >
                <Tag
                  label=""
                  startIcon={<MdiTable size="16" />}
                  type="filled"
                  variant="deepPurple400"
                />
              </div>
            </div>
          </div>
        ) : (
          undefined
        )
      }
      searchInput={
        isListOfAllUnits || activeViewType === "list"
          ? {
              label: "Szukaj wśród zleceń produkcyjnych",
              tags: [],
            }
          : undefined
      }
      searchBar={isListOfAllUnits || activeViewType === "list"}
      tabs={{
        list: dictToList(stagesTabDict).map(({ key, value }) => ({
          name: key,
          label: value,
          path:
            key === "all"
              ? `/manufacturing/production-orders/all`
              : `/manufacturing/production-orders/stage-orders/${key}`,
          exactPath: true,
        })),
        routesRoot: `manufacturing/${productionOrders.url}`,
      }}
      title={
        <div>{!isListOfAllUnits && manufacturingStage && <div>{manufacturingStage.name}</div>}</div>
      }
      viewLabel="MANUFACTURING_STAGE_BOARD"
    />
  );
};
