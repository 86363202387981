import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const wmsKeys = {
  unloads: {
    list: createPaginatedKey("unloads"),
    details: (id: UUID) => ["unloading", id],
    lineItems: createPaginatedKey("lineItems"),
    lineItemDetails: (id: UUID) => ["unloadingLineItem", id],
    lastUnloadedGroups: createPaginatedKey("lastUnloadedGroups"),
    workspaceInventory: (id: string) => ["unloadingWorkspaceInventory", id],
  },
  picking: {
    list: createPaginatedKey("pickings"),
    details: (id: UUID) => ["picking", id],
    lineItems: createPaginatedKey("pickingLineItems"),
    lineItemsDetails: (id: UUID) => ["pickingLineItemsDetails", id],
    lastPickedOrders: createPaginatedKey("lastPickedOrders"),
    workspaceInventory: (id: string) => ["pickingWorkspaceInventory", id],
  },
  loading: {
    list: createPaginatedKey("loadings"),
    details: (id: UUID) => ["loading", id],
    lineItems: createPaginatedKey("loadingLineItems"),
    lineItemsDetails: (id: UUID) => ["loadingLineItem", id],
    lastLoadedOrders: createPaginatedKey("lastLoadedOrders"),
    workspaceInventory: (id: string) => ["loadingWorkspaceInventory", id],
  },
  cargoUnloads: {
    list: createPaginatedKey("cargoUnloads"),
  },
  warehouseWorkers: {
    list: createPaginatedKey("warehouseWorkers"),
    details: (id: string) => ["warehouseWorker", id],
  },
  analytics: {
    receivedAndReleasedPackagesPerDay: (search: string) => [
      "receivedAndReleasedPackagesPerDay",
      search,
    ],
    dailySummaryReceivedAndReleasedPackages: (search: string) => [
      "dailySummaryReceivedAndReleasedPackages",
    ],
    receivedAndReleasedPackagesPerMonth: (search: string) => [
      "receivedAndReleasedPackagesPerMonth",
      search,
    ],
    releasedPerCategory: (search: string) => ["releasedPerCategory", search],
    receivedPerCategory: (search: string) => ["receivedPerCategory", search],
  },
  overviewReleasedAndReceived: (search: string) => ["overviewReleasedAndReceived", search],
  overviewReceivedByProductCategory: {
    list: createPaginatedKey("overviewReceivedByProductCategory"),
  },
  overviewReleasedByProductCategory: {
    list: createPaginatedKey("overviewReleasedByProductCategory"),
  },
  overviewReleasedForCustomer: {
    list: createPaginatedKey("overviewReleasedForCustomer"),
  },
  overviewReceivedForCustomer: {
    list: createPaginatedKey("overviewReceivedForCustomer"),
  },
  scannedPositionsInvoicingStatus: {
    list: (search: string) => ["scannedPositionsInvoicingStatus", search],
  },
};
