import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting1 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5451)">
        <path
          d="M11.9997 21.3909C17.1864 21.3909 21.3909 17.1863 21.3909 11.9997C21.3909 6.813 17.1864 2.6084 11.9997 2.6084C6.81299 2.6084 2.6084 6.813 2.6084 11.9997C2.6084 17.1863 6.81299 21.3909 11.9997 21.3909Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M18.6375 5.3559C20.1724 6.88945 21.1289 8.90722 21.3445 11.0662C21.5601 13.2251 21.0215 15.3921 19.8202 17.1989C18.6189 19.0057 16.8291 20.3408 14.7548 20.9773C12.6806 21.6139 10.45 21.5126 8.44197 20.6907C6.434 19.8687 4.77254 18.3768 3.74001 16.4685C2.70747 14.5602 2.36754 12.3534 2.77798 10.2228C3.18842 8.09232 4.32391 6.1695 5.99146 4.78143C7.65904 3.39333 9.75581 2.62555 11.9255 2.6084L11.9997 11.9994L18.6375 5.3559Z"
          fill="black"
        />
        <path
          d="M1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9998 1.02148C18.0629 1.02148 22.9781 5.93662 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5451">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
