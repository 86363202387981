import styled from "@emotion/styled";
import {
  CURRENCY_TYPE,
  CountryCode,
  countryCodes as countryCodeIconList,
  countryCurrenciesFlags,
} from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";

const DEFAULT_ICON_SIZE = 16 as const;

type Props =
  | {
      countryCode: CountryCode;
      currency?: never;
    }
  | {
      countryCode?: never;
      currency: CURRENCY_TYPE;
    };

const StyledImage = styled.img<{
  size: number;
}>`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;

export const CountryFlag = ({
  countryCode,
  currency,
  size = DEFAULT_ICON_SIZE,
  typographyProps,
}: Props & { typographyProps?: Partial<TypographyProps>; size?: number }) => {
  const icon = (() => {
    if (countryCode) {
      return countryCodeIconList.find(
        countryCodeObject => countryCodeObject.value.toUpperCase() === countryCode.toUpperCase(),
      )?.icon;
    }
    return countryCurrenciesFlags[currency];
  })();

  const displayLabel = countryCode?.toUpperCase() || currency?.toUpperCase();

  return (
    <div className="d-flex align-items-center gap-1">
      <StyledImage alt="Ikona kraju" size={size} src={icon} />
      <div className="d-flex align-items-baseline justify-content-center">
        <Typography
          className={typographyProps?.className}
          color={typographyProps?.color || "neutralBlack100"}
          fontFamily={typographyProps?.fontFamily || "sofiaSans"}
          fontSize={typographyProps?.fontSize || "10"}
          fontWeight={typographyProps?.fontWeight || "700"}
        >
          {displayLabel}
        </Typography>
      </div>
    </div>
  );
};
