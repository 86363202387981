import {
  ManufacturingUnit,
  ManufacturingPriority,
  ManufacturingUnitGroupType,
  ManufacturingGroup,
  NormalizedGroupElement,
} from "api/manufacturing/units/models";
import { ColumnType } from "../ColumnView";
import { useQuery } from "hooks";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import styles from "./ManufacturingPanel.module.css";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { ManufacturingPanel } from "./ManufacturingPanel";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import cuid from "cuid";
import { useGroupSearch } from "../subcomponents/todoSection/TodoSection";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { useGetTodoGroup } from "../hooks/useColumnDropService";
import { ErrorType } from "hooks/createApiQuery";
import { OrderTypeChoices } from "api/orders/enums";
interface Props {
  columnType: ColumnType;
}

export const DrawerRenderer = ({ columnType }: Props) => {
  const { currentDrawer } = useManufacturingBoardDrawer();

  if (!currentDrawer) return null;

  if (columnType === ColumnType.TODO) {
    if (columnType !== currentDrawer.columnType) return null;

    if (currentDrawer.type === "unitItem")
      return <UnitItemDrawer key={currentDrawer.id} unitId={currentDrawer.id} />;

    if (currentDrawer.type === "group")
      return <TodoGroupDrawer key={currentDrawer.id} id={currentDrawer.id} />;
  }

  if (columnType === ColumnType.READY) {
    if (columnType !== currentDrawer.columnType) return null;

    return <UnitItemDrawer key={currentDrawer.id} unitId={currentDrawer.id} />;
  }

  if (columnType === ColumnType.IN_PROGRESS) {
    if (columnType !== currentDrawer.columnType) return null;

    return <InProgressPanelRenderer unitId={currentDrawer.id} key={currentDrawer.id} />;
  }

  return null;
};

const UnitItemDrawer = ({ unitId }: { unitId: ManufacturingUnit["id"] }) => {
  const { closeDrawer } = useManufacturingBoardDrawer();
  const { data: unitDetails, error, isLoading } = manufacturingUnitsActions.useGetUnitItem(
    unitId ? unitId : "",
    {
      enabled: Boolean(unitId),
      onError: error => {
        if (error.code === "404") {
          closeDrawer();
        }
      },
    },
  );

  if (error) return <WrapperError error={error} />;

  if (isLoading) return <WrapperLoader />;

  if (!unitDetails) return null;

  return (
    <ManufacturingPanel
      columnType={ColumnType.TODO}
      downloadLabelFn={panel =>
        manufacturingFileFactory.manufacturingItemPdf(
          [panel.manufacturingItemId!],
          panel.signature!,
        )
      }
      panel={{
        attributes: unitDetails.attributeValues,
        isComplaint: unitDetails.orderType === OrderTypeChoices.COMPLAINT,
        itemNote: unitDetails.itemNote,
        employee: unitDetails.employee,
        finishedAt: unitDetails?.finishedAt,
        id: unitDetails.id,
        implementedBy: unitDetails.implementedBy,
        isCancelled: unitDetails.isCancelled,
        isDeclined: unitDetails.isDeclined,
        manufacturer: unitDetails.manufacturer,
        manufacturingItemId: unitDetails.manufacturingItem.id,
        orderSignature: unitDetails.orderSignature,
        priority: unitDetails.priority,
        productName: unitDetails.name,
        scheduledAt: unitDetails.scheduledAt,
        signature: unitDetails.manufacturingItem.signature,
        externalOrderNumber: unitDetails.manufacturingItem.externalOrderNumber,
        type: ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT,
      }}
      priorityMutation={() => manufacturingUnitsActions.usePatchUnitItem()}
    />
  );
};

const InProgressPanelRenderer = ({ unitId }: { unitId: ManufacturingUnit["id"] }) => {
  const { closeDrawer } = useManufacturingBoardDrawer();
  const {
    data: unitGroupDetails,
    error,
    isLoading,
  } = manufacturingUnitsActions.useGetManufacturingUnitGroupDetails(unitId, {
    onError: error => {
      if (error.code === "404") {
        closeDrawer();
      }
    },
  });

  if (error) return <WrapperError error={error} />;

  if (isLoading) return <WrapperLoader />;

  if (!unitGroupDetails) return null;

  if (unitGroupDetails.type === ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT) {
    return (
      <ManufacturingPanel
        columnType={ColumnType.IN_PROGRESS}
        downloadLabelFn={() =>
          manufacturingFileFactory.manufacturingItemPdf(
            unitGroupDetails.manufacturingItems.map(item => item.id),
            unitGroupDetails.signature,
          )
        }
        employeeMutation={() => manufacturingUnitsActions.usePatchUnitItem()}
        panel={{
          attributes: unitGroupDetails.attributes,
          employee: unitGroupDetails.employee,
          groupSignature: unitGroupDetails.group?.signature,
          externalOrderNumber: unitGroupDetails.manufacturingItems[0].externalOrderNumber,
          itemNote: unitGroupDetails.manufacturingItems[0].itemNote,
          productName: unitGroupDetails.manufacturingItems[0].name,
          id: unitGroupDetails.id,
          isDeclined: unitGroupDetails.isDeclined,
          isCancelled: unitGroupDetails.isCancelled,
          isComplaint: unitGroupDetails.order?.type === OrderTypeChoices.COMPLAINT,
          implementedBy: unitGroupDetails.implementedBy,
          manufacturer: unitGroupDetails.manufacturer,
          manufacturingItemId: unitGroupDetails.manufacturingItems[0].id,
          note: unitGroupDetails.note,
          orderSignature: unitGroupDetails.order?.signature,
          priority: unitGroupDetails.priority || ManufacturingPriority.C,
          scheduledAt: unitGroupDetails.scheduledAt,
          signature: unitGroupDetails.manufacturingItems[0].signature,
          type: ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT,
        }}
        priorityMutation={() => manufacturingUnitsActions.usePatchUnitItem()}
      />
    );
  }

  return (
    <ManufacturingPanel
      columnType={ColumnType.IN_PROGRESS}
      downloadLabelFn={() =>
        manufacturingFileFactory.manufacturingItemPdf(
          unitGroupDetails.manufacturingItems.map(item => item.id),
          unitGroupDetails.signature,
        )
      }
      panel={{
        attributes: unitGroupDetails.attributes,
        elementsCount: unitGroupDetails.manufacturingItems.length,
        id: unitGroupDetails.id,
        signature: unitGroupDetails.signature,
        type: ManufacturingUnitGroupType.GROUP,
        externalOrderNumber: "",
      }}
    />
  );
};

const TodoGroupDrawer = ({ id }: { id: string }) => {
  const { closeDrawer } = useManufacturingBoardDrawer();
  const stageId = useStageId();
  const groupsSearch = useGroupSearch();
  const { error, isLoading } = manufacturingUnitsActions.useGetManufacturingGroups(
    {
      id: stageId,
      search: groupsSearch.search,
    },
    {
      enabled: !groupsSearch.isLoading,
      onError: error => {
        if (error.code === "404") {
          closeDrawer();
        }
      },
    },
  );

  const getTodoGroup = useGetTodoGroup();

  if (error) return <WrapperError error={error} />;

  if (isLoading) return <WrapperLoader />;

  const group = getTodoGroup(id);
  if (!group) return null;
  return (
    <ManufacturingPanel
      columnType={ColumnType.TODO}
      downloadLabelFn={panel =>
        manufacturingFileFactory.manufacturingItemPdf(
          panel.groupElements!.map(groupElement => groupElement.manufacturingItemId!),
          panel.modelName!,
        )
      }
      panel={{
        attributes: group.attributesValues,
        externalOrderNumber: "",
        elementsCount: group.elements.length,
        groupElements: normalizeTodoGroupedUnits(group.elements),
        id: `todo-group-${cuid()}`,
        modelName: group.modelName,
        type: ManufacturingUnitGroupType.GROUP,
      }}
    />
  );
};

interface DrawerKeyData {
  columnType: ColumnType;
  id: string;
  type: "group" | "unitItem";
}

export const useManufacturingBoardDrawer = () => {
  const { query, setQuery } = useQuery();

  const createDrawerKey = (data: DrawerKeyData): string => {
    return `${data.id};${data.columnType};${data.type}`;
  };

  const parseDrawerKey = (queryKey: string): DrawerKeyData => {
    const [id, columnType, type] = queryKey.split(";");
    return {
      columnType: columnType as DrawerKeyData["columnType"],
      id,
      type: type as DrawerKeyData["type"],
    };
  };
  return {
    toggleDrawer: (data: DrawerKeyData) => {
      if (query?.drawerKey && parseDrawerKey(query?.drawerKey).id === data.id) {
        setQuery({ drawerKey: "" });
        return;
      }
      const drawerKey = createDrawerKey(data);
      setQuery({ drawerKey });
    },
    closeDrawer: () => {
      setQuery({ drawerKey: "" });
    },
    currentDrawer: query?.drawerKey ? parseDrawerKey(query?.drawerKey) : null,
  };
};

const WrapperError = ({ error }: { error: ErrorType }) => {
  return <div className={styles.panelWrapper}>{<CommonError status={error._httpStatus_} />}</div>;
};

const WrapperLoader = () => {
  return (
    <div className={styles.panelWrapper}>
      <div className="d-flex align-items-center justify-content-center flex-1">
        <Spinner size={30} />
      </div>
    </div>
  );
};

const normalizeTodoGroupedUnits = (
  elements: ManufacturingGroup["elements"],
): NormalizedGroupElement[] => {
  return elements.map(element => ({
    id: element.id,
    attributes: element.attributeValues.map(attribute => attribute.value.name).join(", "),
    isComplaint: element.orderType === OrderTypeChoices.COMPLAINT,
    attributeValues: [],
    isCancelled: element.isCancelled,
    isDeclined: element.isDeclined,
    manufacturingItemId: element.manufacturingItemId,
    note: element.note,
    itemNote: element.itemNote,
    orderSignature: element.orderSignature,
    priority: element.priority,
    signature: element.signature,
    externalOrderNumber: element.externalOrderNumber,
  }));
};
