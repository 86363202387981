import { ExpressService } from "api/orders/models";

const serviceAlerts: {
  key: keyof ExpressService;
  label: string;
}[] = [
  {
    key: "alert50PercentLeft",
    label: "50% czasu",
  },
  {
    key: "alert30PercentLeft",
    label: "30% czasu",
  },
  {
    key: "alert15PercentLeft",
    label: "15% czasu",
  },
];

export const expressServicesConstants = {
  serviceAlerts,
};
