import { yup } from "utilities";

export const validationSchema = yup.object().shape({
  orders: yup.array().of(
    yup.object().shape({
      positions: yup.array().of(
        yup.object().shape({
          amountWithTax: yup
            .number()
            .min(0, "Wartość brutto nie może być ujemna")
            .required("Wartość brutto jest wymagana"),
        }),
      ),
    }),
  ),
  seller: yup.string().required("Sprzedawca jest wymagany"),
});
