import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiPackagePrice } from "components/miloDesignSystem/atoms/icons/MdiPackagePrice";
import { PackagesValuesRouting } from "routes/finances/PackagesValuesRouting";

export const packagesInWarehouseValues: ModuleLink = {
  url: "packages-values",
  label: "Łączna wycena paczek",
  icon: MdiPackagePrice,
  routing: PackagesValuesRouting,
};
