import { ApiFetcher } from "hooks/createApiQuery";
import { wmsKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { ScannedPositions } from "./models";

const getScannedPositionsInvoicingStatus = (
  search: string = "",
): ApiFetcher<ScannedPositions[]> => ({
  key: wmsKeys.scannedPositionsInvoicingStatus.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/warehouse-finances/scanned-positions-invoicing-status" + search,
    }),
});

export const wmsApi = {
  getScannedPositionsInvoicingStatus,
};
