// Auto-generated file - DO NOT EDIT MANUALLY

import awaiting0 from "./awaiting0.png";
import awaiting0F from "./awaiting0F.png";
import awaiting0F_D from "./awaiting0F_D.png";
import awaiting0F_P from "./awaiting0F_P.png";
import awaiting0F_S from "./awaiting0F_S.png";
import awaiting0F_T from "./awaiting0F_T.png";
import awaiting0S from "./awaiting0S.png";
import awaiting0S_D from "./awaiting0S_D.png";
import awaiting0S_P from "./awaiting0S_P.png";
import awaiting0S_S from "./awaiting0S_S.png";
import awaiting0S_T from "./awaiting0S_T.png";
import awaiting0_D from "./awaiting0_D.png";
import awaiting0_P from "./awaiting0_P.png";
import awaiting0_S from "./awaiting0_S.png";
import awaiting0_T from "./awaiting0_T.png";
import awaiting1 from "./awaiting1.png";
import awaiting1F from "./awaiting1F.png";
import awaiting1F_D from "./awaiting1F_D.png";
import awaiting1F_P from "./awaiting1F_P.png";
import awaiting1F_S from "./awaiting1F_S.png";
import awaiting1F_T from "./awaiting1F_T.png";
import awaiting1S from "./awaiting1S.png";
import awaiting1S_D from "./awaiting1S_D.png";
import awaiting1S_P from "./awaiting1S_P.png";
import awaiting1S_S from "./awaiting1S_S.png";
import awaiting1S_T from "./awaiting1S_T.png";
import awaiting1_D from "./awaiting1_D.png";
import awaiting1_P from "./awaiting1_P.png";
import awaiting1_S from "./awaiting1_S.png";
import awaiting1_T from "./awaiting1_T.png";
import awaiting2 from "./awaiting2.png";
import awaiting2F from "./awaiting2F.png";
import awaiting2F_D from "./awaiting2F_D.png";
import awaiting2F_P from "./awaiting2F_P.png";
import awaiting2F_S from "./awaiting2F_S.png";
import awaiting2F_T from "./awaiting2F_T.png";
import awaiting2S from "./awaiting2S.png";
import awaiting2S_D from "./awaiting2S_D.png";
import awaiting2S_P from "./awaiting2S_P.png";
import awaiting2S_S from "./awaiting2S_S.png";
import awaiting2S_T from "./awaiting2S_T.png";
import awaiting2_D from "./awaiting2_D.png";
import awaiting2_P from "./awaiting2_P.png";
import awaiting2_S from "./awaiting2_S.png";
import awaiting2_T from "./awaiting2_T.png";
import awaiting3 from "./awaiting3.png";
import awaiting3F from "./awaiting3F.png";
import awaiting3F_D from "./awaiting3F_D.png";
import awaiting3F_P from "./awaiting3F_P.png";
import awaiting3F_S from "./awaiting3F_S.png";
import awaiting3F_T from "./awaiting3F_T.png";
import awaiting3S from "./awaiting3S.png";
import awaiting3S_D from "./awaiting3S_D.png";
import awaiting3S_P from "./awaiting3S_P.png";
import awaiting3S_S from "./awaiting3S_S.png";
import awaiting3S_T from "./awaiting3S_T.png";
import awaiting3_D from "./awaiting3_D.png";
import awaiting3_P from "./awaiting3_P.png";
import awaiting3_S from "./awaiting3_S.png";
import awaiting3_T from "./awaiting3_T.png";
import awaiting4 from "./awaiting4.png";
import awaiting4F from "./awaiting4F.png";
import awaiting4F_D from "./awaiting4F_D.png";
import awaiting4F_P from "./awaiting4F_P.png";
import awaiting4F_S from "./awaiting4F_S.png";
import awaiting4F_T from "./awaiting4F_T.png";
import awaiting4S from "./awaiting4S.png";
import awaiting4S_D from "./awaiting4S_D.png";
import awaiting4S_P from "./awaiting4S_P.png";
import awaiting4S_S from "./awaiting4S_S.png";
import awaiting4S_T from "./awaiting4S_T.png";
import awaiting4_D from "./awaiting4_D.png";
import awaiting4_P from "./awaiting4_P.png";
import awaiting4_S from "./awaiting4_S.png";
import awaiting4_T from "./awaiting4_T.png";
import awaiting5 from "./awaiting5.png";
import awaiting5F from "./awaiting5F.png";
import awaiting5F_D from "./awaiting5F_D.png";
import awaiting5F_P from "./awaiting5F_P.png";
import awaiting5F_S from "./awaiting5F_S.png";
import awaiting5F_T from "./awaiting5F_T.png";
import awaiting5S from "./awaiting5S.png";
import awaiting5S_D from "./awaiting5S_D.png";
import awaiting5S_P from "./awaiting5S_P.png";
import awaiting5S_S from "./awaiting5S_S.png";
import awaiting5S_T from "./awaiting5S_T.png";
import awaiting5_D from "./awaiting5_D.png";
import awaiting5_P from "./awaiting5_P.png";
import awaiting5_S from "./awaiting5_S.png";
import awaiting5_T from "./awaiting5_T.png";
import awaiting6 from "./awaiting6.png";
import awaiting6F from "./awaiting6F.png";
import awaiting6F_D from "./awaiting6F_D.png";
import awaiting6F_P from "./awaiting6F_P.png";
import awaiting6F_S from "./awaiting6F_S.png";
import awaiting6F_T from "./awaiting6F_T.png";
import awaiting6S from "./awaiting6S.png";
import awaiting6S_D from "./awaiting6S_D.png";
import awaiting6S_P from "./awaiting6S_P.png";
import awaiting6S_S from "./awaiting6S_S.png";
import awaiting6S_T from "./awaiting6S_T.png";
import awaiting6_D from "./awaiting6_D.png";
import awaiting6_P from "./awaiting6_P.png";
import awaiting6_S from "./awaiting6_S.png";
import awaiting6_T from "./awaiting6_T.png";
import awaiting7 from "./awaiting7.png";
import awaiting7F from "./awaiting7F.png";
import awaiting7F_D from "./awaiting7F_D.png";
import awaiting7F_P from "./awaiting7F_P.png";
import awaiting7F_S from "./awaiting7F_S.png";
import awaiting7F_T from "./awaiting7F_T.png";
import awaiting7S from "./awaiting7S.png";
import awaiting7S_D from "./awaiting7S_D.png";
import awaiting7S_P from "./awaiting7S_P.png";
import awaiting7S_S from "./awaiting7S_S.png";
import awaiting7S_T from "./awaiting7S_T.png";
import awaiting7_D from "./awaiting7_D.png";
import awaiting7_P from "./awaiting7_P.png";
import awaiting7_S from "./awaiting7_S.png";
import awaiting7_T from "./awaiting7_T.png";
import awaiting_n from "./awaiting_n.png";
import awaiting_nF from "./awaiting_nF.png";
import awaiting_nF_D from "./awaiting_nF_D.png";
import awaiting_nF_P from "./awaiting_nF_P.png";
import awaiting_nF_S from "./awaiting_nF_S.png";
import awaiting_nF_T from "./awaiting_nF_T.png";
import awaiting_nS from "./awaiting_nS.png";
import awaiting_nS_D from "./awaiting_nS_D.png";
import awaiting_nS_P from "./awaiting_nS_P.png";
import awaiting_nS_S from "./awaiting_nS_S.png";
import awaiting_nS_T from "./awaiting_nS_T.png";
import awaiting_n_D from "./awaiting_n_D.png";
import awaiting_n_P from "./awaiting_n_P.png";
import awaiting_n_S from "./awaiting_n_S.png";
import awaiting_n_T from "./awaiting_n_T.png";
import pB1 from "./pB1.png";
import pB1D from "./pB1D.png";
import pB1P from "./pB1P.png";
import pB1S from "./pB1S.png";
import pB1T from "./pB1T.png";
import pB2 from "./pB2.png";
import pB2D from "./pB2D.png";
import pB2P from "./pB2P.png";
import pB2S from "./pB2S.png";
import pB2T from "./pB2T.png";
import pB3 from "./pB3.png";
import pB3D from "./pB3D.png";
import pB3P from "./pB3P.png";
import pB3S from "./pB3S.png";
import pB3T from "./pB3T.png";
import pB4 from "./pB4.png";
import pB4D from "./pB4D.png";
import pB4P from "./pB4P.png";
import pB4S from "./pB4S.png";
import pB4T from "./pB4T.png";
import pB5 from "./pB5.png";
import pB5D from "./pB5D.png";
import pB5P from "./pB5P.png";
import pB5S from "./pB5S.png";
import pB5T from "./pB5T.png";
import pB6 from "./pB6.png";
import pB6D from "./pB6D.png";
import pB6P from "./pB6P.png";
import pB6S from "./pB6S.png";
import pB6T from "./pB6T.png";
import pB7 from "./pB7.png";
import pB7D from "./pB7D.png";
import pB7P from "./pB7P.png";
import pB7S from "./pB7S.png";
import pB7T from "./pB7T.png";
import pB8 from "./pB8.png";
import pB8D from "./pB8D.png";
import pB8P from "./pB8P.png";
import pB8S from "./pB8S.png";
import pB8T from "./pB8T.png";
import pF1 from "./pF1.png";
import pF1D from "./pF1D.png";
import pF1P from "./pF1P.png";
import pF1S from "./pF1S.png";
import pF1T from "./pF1T.png";
import pF2 from "./pF2.png";
import pF2D from "./pF2D.png";
import pF2P from "./pF2P.png";
import pF2S from "./pF2S.png";
import pF2T from "./pF2T.png";
import pF3 from "./pF3.png";
import pF3D from "./pF3D.png";
import pF3P from "./pF3P.png";
import pF3S from "./pF3S.png";
import pF3T from "./pF3T.png";
import pF4 from "./pF4.png";
import pF4D from "./pF4D.png";
import pF4P from "./pF4P.png";
import pF4S from "./pF4S.png";
import pF4T from "./pF4T.png";
import pF5 from "./pF5.png";
import pF5D from "./pF5D.png";
import pF5P from "./pF5P.png";
import pF5S from "./pF5S.png";
import pF5T from "./pF5T.png";
import pF6 from "./pF6.png";
import pF6P from "./pF6P.png";
import pF6S from "./pF6S.png";
import pF6T from "./pF6T.png";
import pF7 from "./pF7.png";
import pF7D from "./pF7D.png";
import pF7P from "./pF7P.png";
import pF7S from "./pF7S.png";
import pF7T from "./pF7T.png";
import pF8 from "./pF8.png";
import pF8D from "./pF8D.png";
import pF8P from "./pF8P.png";
import pF8S from "./pF8S.png";
import pF8T from "./pF8T.png";
import pFD from "./pFD.png";
import pPawaiting from "./pPawaiting.png";
import pPawaiting_D from "./pPawaiting_D.png";
import pPawaiting_P from "./pPawaiting_P.png";
import pPawaiting_S from "./pPawaiting_S.png";
import pPawaiting_T from "./pPawaiting_T.png";
import pR1 from "./pR1.png";
import pR1D from "./pR1D.png";
import pR1P from "./pR1P.png";
import pR1S from "./pR1S.png";
import pR1T from "./pR1T.png";
import pR2 from "./pR2.png";
import pR2D from "./pR2D.png";
import pR2P from "./pR2P.png";
import pR2S from "./pR2S.png";
import pR2T from "./pR2T.png";
import pR3 from "./pR3.png";
import pR3D from "./pR3D.png";
import pR3P from "./pR3P.png";
import pR3S from "./pR3S.png";
import pR3T from "./pR3T.png";
import pR4 from "./pR4.png";
import pR4D from "./pR4D.png";
import pR4P from "./pR4P.png";
import pR4S from "./pR4S.png";
import pR4T from "./pR4T.png";
import pR5 from "./pR5.png";
import pR5D from "./pR5D.png";
import pR5P from "./pR5P.png";
import pR5S from "./pR5S.png";
import pR5T from "./pR5T.png";
import pR6 from "./pR6.png";
import pR6D from "./pR6D.png";
import pR6P from "./pR6P.png";
import pR6S from "./pR6S.png";
import pR6T from "./pR6T.png";
import pR7 from "./pR7.png";
import pR7D from "./pR7D.png";
import pR7P from "./pR7P.png";
import pR7S from "./pR7S.png";
import pR7T from "./pR7T.png";
import pR8 from "./pR8.png";
import pR8D from "./pR8D.png";
import pR8P from "./pR8P.png";
import pR8S from "./pR8S.png";
import pR8T from "./pR8T.png";
import pRF1 from "./pRF1.png";
import pRF1D from "./pRF1D.png";
import pRF1P from "./pRF1P.png";
import pRF1S from "./pRF1S.png";
import pRF1T from "./pRF1T.png";
import pRF2 from "./pRF2.png";
import pRF2D from "./pRF2D.png";
import pRF2P from "./pRF2P.png";
import pRF2S from "./pRF2S.png";
import pRF2T from "./pRF2T.png";
import pRF3 from "./pRF3.png";
import pRF3D from "./pRF3D.png";
import pRF3P from "./pRF3P.png";
import pRF3S from "./pRF3S.png";
import pRF3T from "./pRF3T.png";
import pRF4 from "./pRF4.png";
import pRF4D from "./pRF4D.png";
import pRF4P from "./pRF4P.png";
import pRF4S from "./pRF4S.png";
import pRF4T from "./pRF4T.png";
import pRF5 from "./pRF5.png";
import pRF5D from "./pRF5D.png";
import pRF5P from "./pRF5P.png";
import pRF5S from "./pRF5S.png";
import pRF5T from "./pRF5T.png";
import pRF6 from "./pRF6.png";
import pRF6D from "./pRF6D.png";
import pRF6P from "./pRF6P.png";
import pRF6S from "./pRF6S.png";
import pRF6T from "./pRF6T.png";
import pRF7 from "./pRF7.png";
import pRF7D from "./pRF7D.png";
import pRF7P from "./pRF7P.png";
import pRF7S from "./pRF7S.png";
import pRF7T from "./pRF7T.png";
import pRF8 from "./pRF8.png";
import pRF8D from "./pRF8D.png";
import pRF8P from "./pRF8P.png";
import pRF8S from "./pRF8S.png";
import pRF8T from "./pRF8T.png";
import pRS1 from "./pRS1.png";
import pRS1D from "./pRS1D.png";
import pRS1P from "./pRS1P.png";
import pRS1S from "./pRS1S.png";
import pRS1T from "./pRS1T.png";
import pRS2 from "./pRS2.png";
import pRS2D from "./pRS2D.png";
import pRS2P from "./pRS2P.png";
import pRS2S from "./pRS2S.png";
import pRS2T from "./pRS2T.png";
import pRS3 from "./pRS3.png";
import pRS3D from "./pRS3D.png";
import pRS3P from "./pRS3P.png";
import pRS3S from "./pRS3S.png";
import pRS3T from "./pRS3T.png";
import pRS4 from "./pRS4.png";
import pRS4D from "./pRS4D.png";
import pRS4P from "./pRS4P.png";
import pRS4S from "./pRS4S.png";
import pRS4T from "./pRS4T.png";
import pRS5 from "./pRS5.png";
import pRS5D from "./pRS5D.png";
import pRS5P from "./pRS5P.png";
import pRS5S from "./pRS5S.png";
import pRS5T from "./pRS5T.png";
import pRS6 from "./pRS6.png";
import pRS6D from "./pRS6D.png";
import pRS6P from "./pRS6P.png";
import pRS6S from "./pRS6S.png";
import pRS6T from "./pRS6T.png";
import pRS7 from "./pRS7.png";
import pRS7D from "./pRS7D.png";
import pRS7P from "./pRS7P.png";
import pRS7S from "./pRS7S.png";
import pRS7T from "./pRS7T.png";
import pRS8 from "./pRS8.png";
import pRS8D from "./pRS8D.png";
import pRS8P from "./pRS8P.png";
import pRS8S from "./pRS8S.png";
import pRS8T from "./pRS8T.png";
import pS1 from "./pS1.png";
import pS1D from "./pS1D.png";
import pS1P from "./pS1P.png";
import pS1S from "./pS1S.png";
import pS1T from "./pS1T.png";
import pS2 from "./pS2.png";
import pS2D from "./pS2D.png";
import pS2P from "./pS2P.png";
import pS2S from "./pS2S.png";
import pS2T from "./pS2T.png";
import pS3 from "./pS3.png";
import pS3D from "./pS3D.png";
import pS3P from "./pS3P.png";
import pS3S from "./pS3S.png";
import pS3T from "./pS3T.png";
import pS4 from "./pS4.png";
import pS4D from "./pS4D.png";
import pS4P from "./pS4P.png";
import pS4S from "./pS4S.png";
import pS4T from "./pS4T.png";
import pS5 from "./pS5.png";
import pS5D from "./pS5D.png";
import pS5P from "./pS5P.png";
import pS5S from "./pS5S.png";
import pS5T from "./pS5T.png";
import pS6 from "./pS6.png";
import pS6D from "./pS6D.png";
import pS6P from "./pS6P.png";
import pS6S from "./pS6S.png";
import pS6T from "./pS6T.png";
import pS7 from "./pS7.png";
import pS7D from "./pS7D.png";
import pS7P from "./pS7P.png";
import pS7S from "./pS7S.png";
import pS7T from "./pS7T.png";
import pS8 from "./pS8.png";
import pS8D from "./pS8D.png";
import pS8P from "./pS8P.png";
import pS8S from "./pS8S.png";
import pS8T from "./pS8T.png";
import pU1 from "./pU1.png";
import pU1D from "./pU1D.png";
import pU1P from "./pU1P.png";
import pU1S from "./pU1S.png";
import pU1T from "./pU1T.png";
import pU2 from "./pU2.png";
import pU2D from "./pU2D.png";
import pU2P from "./pU2P.png";
import pU2S from "./pU2S.png";
import pU2T from "./pU2T.png";
import pU3 from "./pU3.png";
import pU3D from "./pU3D.png";
import pU3P from "./pU3P.png";
import pU3S from "./pU3S.png";
import pU3T from "./pU3T.png";
import pU4 from "./pU4.png";
import pU4D from "./pU4D.png";
import pU4P from "./pU4P.png";
import pU4S from "./pU4S.png";
import pU4T from "./pU4T.png";
import pU5 from "./pU5.png";
import pU5D from "./pU5D.png";
import pU5P from "./pU5P.png";
import pU5S from "./pU5S.png";
import pU5T from "./pU5T.png";
import pU6 from "./pU6.png";
import pU6D from "./pU6D.png";
import pU6P from "./pU6P.png";
import pU6S from "./pU6S.png";
import pU6T from "./pU6T.png";
import pU7 from "./pU7.png";
import pU7D from "./pU7D.png";
import pU7P from "./pU7P.png";
import pU7S from "./pU7S.png";
import pU7T from "./pU7T.png";
import pU8 from "./pU8.png";
import pU8D from "./pU8D.png";
import pU8P from "./pU8P.png";
import pU8S from "./pU8S.png";
import pU8T from "./pU8T.png";
import pUF1 from "./pUF1.png";
import pUF1D from "./pUF1D.png";
import pUF1P from "./pUF1P.png";
import pUF1S from "./pUF1S.png";
import pUF1T from "./pUF1T.png";
import pUF2 from "./pUF2.png";
import pUF2D from "./pUF2D.png";
import pUF2P from "./pUF2P.png";
import pUF2T from "./pUF2T.png";
import pUF3 from "./pUF3.png";
import pUF3D from "./pUF3D.png";
import pUF3P from "./pUF3P.png";
import pUF3S from "./pUF3S.png";
import pUF3T from "./pUF3T.png";
import pUF4 from "./pUF4.png";
import pUF4D from "./pUF4D.png";
import pUF4P from "./pUF4P.png";
import pUF4S from "./pUF4S.png";
import pUF4T from "./pUF4T.png";
import pUF5 from "./pUF5.png";
import pUF5D from "./pUF5D.png";
import pUF5P from "./pUF5P.png";
import pUF5S from "./pUF5S.png";
import pUF5T from "./pUF5T.png";
import pUF6 from "./pUF6.png";
import pUF6D from "./pUF6D.png";
import pUF6P from "./pUF6P.png";
import pUF6S from "./pUF6S.png";
import pUF6T from "./pUF6T.png";
import pUF7 from "./pUF7.png";
import pUF7D from "./pUF7D.png";
import pUF7P from "./pUF7P.png";
import pUF7S from "./pUF7S.png";
import pUF7T from "./pUF7T.png";
import pUF8 from "./pUF8.png";
import pUF8D from "./pUF8D.png";
import pUF8P from "./pUF8P.png";
import pUF8S from "./pUF8S.png";
import pUF8T from "./pUF8T.png";
import pUFS from "./pUFS.png";
import pUR1 from "./pUR1.png";
import pUR1D from "./pUR1D.png";
import pUR1P from "./pUR1P.png";
import pUR1S from "./pUR1S.png";
import pUR1T from "./pUR1T.png";
import pUR2 from "./pUR2.png";
import pUR2D from "./pUR2D.png";
import pUR2P from "./pUR2P.png";
import pUR2S from "./pUR2S.png";
import pUR2T from "./pUR2T.png";
import pUR3 from "./pUR3.png";
import pUR3D from "./pUR3D.png";
import pUR3P from "./pUR3P.png";
import pUR3S from "./pUR3S.png";
import pUR3T from "./pUR3T.png";
import pUR4 from "./pUR4.png";
import pUR4D from "./pUR4D.png";
import pUR4P from "./pUR4P.png";
import pUR4S from "./pUR4S.png";
import pUR4T from "./pUR4T.png";
import pUR5 from "./pUR5.png";
import pUR5D from "./pUR5D.png";
import pUR5P from "./pUR5P.png";
import pUR5S from "./pUR5S.png";
import pUR5T from "./pUR5T.png";
import pUR6 from "./pUR6.png";
import pUR6D from "./pUR6D.png";
import pUR6P from "./pUR6P.png";
import pUR6S from "./pUR6S.png";
import pUR6T from "./pUR6T.png";
import pUR7 from "./pUR7.png";
import pUR7D from "./pUR7D.png";
import pUR7P from "./pUR7P.png";
import pUR7S from "./pUR7S.png";
import pUR7T from "./pUR7T.png";
import pUR8 from "./pUR8.png";
import pUR8D from "./pUR8D.png";
import pUR8P from "./pUR8P.png";
import pUR8S from "./pUR8S.png";
import pUR8T from "./pUR8T.png";
import pURF1 from "./pURF1.png";
import pURF1D from "./pURF1D.png";
import pURF1P from "./pURF1P.png";
import pURF1S from "./pURF1S.png";
import pURF1T from "./pURF1T.png";
import pURF2 from "./pURF2.png";
import pURF2D from "./pURF2D.png";
import pURF2P from "./pURF2P.png";
import pURF2S from "./pURF2S.png";
import pURF2T from "./pURF2T.png";
import pURF3 from "./pURF3.png";
import pURF3D from "./pURF3D.png";
import pURF3P from "./pURF3P.png";
import pURF3S from "./pURF3S.png";
import pURF3T from "./pURF3T.png";
import pURF4 from "./pURF4.png";
import pURF4D from "./pURF4D.png";
import pURF4P from "./pURF4P.png";
import pURF4S from "./pURF4S.png";
import pURF4T from "./pURF4T.png";
import pURF5 from "./pURF5.png";
import pURF5D from "./pURF5D.png";
import pURF5P from "./pURF5P.png";
import pURF5S from "./pURF5S.png";
import pURF5T from "./pURF5T.png";
import pURF6 from "./pURF6.png";
import pURF6D from "./pURF6D.png";
import pURF6P from "./pURF6P.png";
import pURF6S from "./pURF6S.png";
import pURF6T from "./pURF6T.png";
import pURF7 from "./pURF7.png";
import pURF7D from "./pURF7D.png";
import pURF7P from "./pURF7P.png";
import pURF7S from "./pURF7S.png";
import pURF7T from "./pURF7T.png";
import pURF8 from "./pURF8.png";
import pURF8D from "./pURF8D.png";
import pURF8P from "./pURF8P.png";
import pURF8S from "./pURF8S.png";
import pURF8T from "./pURF8T.png";
import pURS1 from "./pURS1.png";
import pURS1D from "./pURS1D.png";
import pURS1P from "./pURS1P.png";
import pURS1S from "./pURS1S.png";
import pURS1T from "./pURS1T.png";
import pURS2 from "./pURS2.png";
import pURS2D from "./pURS2D.png";
import pURS2P from "./pURS2P.png";
import pURS2S from "./pURS2S.png";
import pURS2T from "./pURS2T.png";
import pURS3 from "./pURS3.png";
import pURS3D from "./pURS3D.png";
import pURS3P from "./pURS3P.png";
import pURS3S from "./pURS3S.png";
import pURS3T from "./pURS3T.png";
import pURS4 from "./pURS4.png";
import pURS4D from "./pURS4D.png";
import pURS4P from "./pURS4P.png";
import pURS4S from "./pURS4S.png";
import pURS4T from "./pURS4T.png";
import pURS5 from "./pURS5.png";
import pURS5D from "./pURS5D.png";
import pURS5P from "./pURS5P.png";
import pURS5S from "./pURS5S.png";
import pURS5T from "./pURS5T.png";
import pURS6 from "./pURS6.png";
import pURS6D from "./pURS6D.png";
import pURS6P from "./pURS6P.png";
import pURS6S from "./pURS6S.png";
import pURS6T from "./pURS6T.png";
import pURS7 from "./pURS7.png";
import pURS7D from "./pURS7D.png";
import pURS7P from "./pURS7P.png";
import pURS7S from "./pURS7S.png";
import pURS7T from "./pURS7T.png";
import pURS8 from "./pURS8.png";
import pURS8D from "./pURS8D.png";
import pURS8P from "./pURS8P.png";
import pURS8S from "./pURS8S.png";
import pURS8T from "./pURS8T.png";
import pUS1 from "./pUS1.png";
import pUS1D from "./pUS1D.png";
import pUS1P from "./pUS1P.png";
import pUS1S from "./pUS1S.png";
import pUS1T from "./pUS1T.png";
import pUS2 from "./pUS2.png";
import pUS2D from "./pUS2D.png";
import pUS2P from "./pUS2P.png";
import pUS2S from "./pUS2S.png";
import pUS2T from "./pUS2T.png";
import pUS3 from "./pUS3.png";
import pUS3D from "./pUS3D.png";
import pUS3P from "./pUS3P.png";
import pUS3S from "./pUS3S.png";
import pUS3T from "./pUS3T.png";
import pUS4 from "./pUS4.png";
import pUS4D from "./pUS4D.png";
import pUS4P from "./pUS4P.png";
import pUS4S from "./pUS4S.png";
import pUS4T from "./pUS4T.png";
import pUS5 from "./pUS5.png";
import pUS5D from "./pUS5D.png";
import pUS5P from "./pUS5P.png";
import pUS5S from "./pUS5S.png";
import pUS5T from "./pUS5T.png";
import pUS6 from "./pUS6.png";
import pUS6D from "./pUS6D.png";
import pUS6P from "./pUS6P.png";
import pUS6S from "./pUS6S.png";
import pUS6T from "./pUS6T.png";
import pUS7 from "./pUS7.png";
import pUS7D from "./pUS7D.png";
import pUS7P from "./pUS7P.png";
import pUS7S from "./pUS7S.png";
import pUS7T from "./pUS7T.png";
import pUS8 from "./pUS8.png";
import pUS8D from "./pUS8D.png";
import pUS8P from "./pUS8P.png";
import pUS8S from "./pUS8S.png";
import pUS8T from "./pUS8T.png";
import pinned from "./pinned.png";
import pinnedD from "./pinnedD.png";
import pinnedP from "./pinnedP.png";
import pinnedS from "./pinnedS.png";
import pinnedT from "./pinnedT.png";

export const mapIcons = {
  awaiting0,
  awaiting0F,
  awaiting0F_D,
  awaiting0F_P,
  awaiting0F_S,
  awaiting0F_T,
  awaiting0S,
  awaiting0S_D,
  awaiting0S_P,
  awaiting0S_S,
  awaiting0S_T,
  awaiting0_D,
  awaiting0_P,
  awaiting0_S,
  awaiting0_T,
  awaiting1,
  awaiting1F,
  awaiting1F_D,
  awaiting1F_P,
  awaiting1F_S,
  awaiting1F_T,
  awaiting1S,
  awaiting1S_D,
  awaiting1S_P,
  awaiting1S_S,
  awaiting1S_T,
  awaiting1_D,
  awaiting1_P,
  awaiting1_S,
  awaiting1_T,
  awaiting2,
  awaiting2F,
  awaiting2F_D,
  awaiting2F_P,
  awaiting2F_S,
  awaiting2F_T,
  awaiting2S,
  awaiting2S_D,
  awaiting2S_P,
  awaiting2S_S,
  awaiting2S_T,
  awaiting2_D,
  awaiting2_P,
  awaiting2_S,
  awaiting2_T,
  awaiting3,
  awaiting3F,
  awaiting3F_D,
  awaiting3F_P,
  awaiting3F_S,
  awaiting3F_T,
  awaiting3S,
  awaiting3S_D,
  awaiting3S_P,
  awaiting3S_S,
  awaiting3S_T,
  awaiting3_D,
  awaiting3_P,
  awaiting3_S,
  awaiting3_T,
  awaiting4,
  awaiting4F,
  awaiting4F_D,
  awaiting4F_P,
  awaiting4F_S,
  awaiting4F_T,
  awaiting4S,
  awaiting4S_D,
  awaiting4S_P,
  awaiting4S_S,
  awaiting4S_T,
  awaiting4_D,
  awaiting4_P,
  awaiting4_S,
  awaiting4_T,
  awaiting5,
  awaiting5F,
  awaiting5F_D,
  awaiting5F_P,
  awaiting5F_S,
  awaiting5F_T,
  awaiting5S,
  awaiting5S_D,
  awaiting5S_P,
  awaiting5S_S,
  awaiting5S_T,
  awaiting5_D,
  awaiting5_P,
  awaiting5_S,
  awaiting5_T,
  awaiting6,
  awaiting6F,
  awaiting6F_D,
  awaiting6F_P,
  awaiting6F_S,
  awaiting6F_T,
  awaiting6S,
  awaiting6S_D,
  awaiting6S_P,
  awaiting6S_S,
  awaiting6S_T,
  awaiting6_D,
  awaiting6_P,
  awaiting6_S,
  awaiting6_T,
  awaiting7,
  awaiting7F,
  awaiting7F_D,
  awaiting7F_P,
  awaiting7F_S,
  awaiting7F_T,
  awaiting7S,
  awaiting7S_D,
  awaiting7S_P,
  awaiting7S_S,
  awaiting7S_T,
  awaiting7_D,
  awaiting7_P,
  awaiting7_S,
  awaiting7_T,
  awaiting_n,
  awaiting_nF,
  awaiting_nF_D,
  awaiting_nF_P,
  awaiting_nF_S,
  awaiting_nF_T,
  awaiting_nS,
  awaiting_nS_D,
  awaiting_nS_P,
  awaiting_nS_S,
  awaiting_nS_T,
  awaiting_n_D,
  awaiting_n_P,
  awaiting_n_S,
  awaiting_n_T,
  pB1,
  pB1D,
  pB1P,
  pB1S,
  pB1T,
  pB2,
  pB2D,
  pB2P,
  pB2S,
  pB2T,
  pB3,
  pB3D,
  pB3P,
  pB3S,
  pB3T,
  pB4,
  pB4D,
  pB4P,
  pB4S,
  pB4T,
  pB5,
  pB5D,
  pB5P,
  pB5S,
  pB5T,
  pB6,
  pB6D,
  pB6P,
  pB6S,
  pB6T,
  pB7,
  pB7D,
  pB7P,
  pB7S,
  pB7T,
  pB8,
  pB8D,
  pB8P,
  pB8S,
  pB8T,
  pF1,
  pF1D,
  pF1P,
  pF1S,
  pF1T,
  pF2,
  pF2D,
  pF2P,
  pF2S,
  pF2T,
  pF3,
  pF3D,
  pF3P,
  pF3S,
  pF3T,
  pF4,
  pF4D,
  pF4P,
  pF4S,
  pF4T,
  pF5,
  pF5D,
  pF5P,
  pF5S,
  pF5T,
  pF6,
  pF6P,
  pF6S,
  pF6T,
  pF7,
  pF7D,
  pF7P,
  pF7S,
  pF7T,
  pF8,
  pF8D,
  pF8P,
  pF8S,
  pF8T,
  pFD,
  pPawaiting,
  pPawaiting_D,
  pPawaiting_P,
  pPawaiting_S,
  pPawaiting_T,
  pR1,
  pR1D,
  pR1P,
  pR1S,
  pR1T,
  pR2,
  pR2D,
  pR2P,
  pR2S,
  pR2T,
  pR3,
  pR3D,
  pR3P,
  pR3S,
  pR3T,
  pR4,
  pR4D,
  pR4P,
  pR4S,
  pR4T,
  pR5,
  pR5D,
  pR5P,
  pR5S,
  pR5T,
  pR6,
  pR6D,
  pR6P,
  pR6S,
  pR6T,
  pR7,
  pR7D,
  pR7P,
  pR7S,
  pR7T,
  pR8,
  pR8D,
  pR8P,
  pR8S,
  pR8T,
  pRF1,
  pRF1D,
  pRF1P,
  pRF1S,
  pRF1T,
  pRF2,
  pRF2D,
  pRF2P,
  pRF2S,
  pRF2T,
  pRF3,
  pRF3D,
  pRF3P,
  pRF3S,
  pRF3T,
  pRF4,
  pRF4D,
  pRF4P,
  pRF4S,
  pRF4T,
  pRF5,
  pRF5D,
  pRF5P,
  pRF5S,
  pRF5T,
  pRF6,
  pRF6D,
  pRF6P,
  pRF6S,
  pRF6T,
  pRF7,
  pRF7D,
  pRF7P,
  pRF7S,
  pRF7T,
  pRF8,
  pRF8D,
  pRF8P,
  pRF8S,
  pRF8T,
  pRS1,
  pRS1D,
  pRS1P,
  pRS1S,
  pRS1T,
  pRS2,
  pRS2D,
  pRS2P,
  pRS2S,
  pRS2T,
  pRS3,
  pRS3D,
  pRS3P,
  pRS3S,
  pRS3T,
  pRS4,
  pRS4D,
  pRS4P,
  pRS4S,
  pRS4T,
  pRS5,
  pRS5D,
  pRS5P,
  pRS5S,
  pRS5T,
  pRS6,
  pRS6D,
  pRS6P,
  pRS6S,
  pRS6T,
  pRS7,
  pRS7D,
  pRS7P,
  pRS7S,
  pRS7T,
  pRS8,
  pRS8D,
  pRS8P,
  pRS8S,
  pRS8T,
  pS1,
  pS1D,
  pS1P,
  pS1S,
  pS1T,
  pS2,
  pS2D,
  pS2P,
  pS2S,
  pS2T,
  pS3,
  pS3D,
  pS3P,
  pS3S,
  pS3T,
  pS4,
  pS4D,
  pS4P,
  pS4S,
  pS4T,
  pS5,
  pS5D,
  pS5P,
  pS5S,
  pS5T,
  pS6,
  pS6D,
  pS6P,
  pS6S,
  pS6T,
  pS7,
  pS7D,
  pS7P,
  pS7S,
  pS7T,
  pS8,
  pS8D,
  pS8P,
  pS8S,
  pS8T,
  pU1,
  pU1D,
  pU1P,
  pU1S,
  pU1T,
  pU2,
  pU2D,
  pU2P,
  pU2S,
  pU2T,
  pU3,
  pU3D,
  pU3P,
  pU3S,
  pU3T,
  pU4,
  pU4D,
  pU4P,
  pU4S,
  pU4T,
  pU5,
  pU5D,
  pU5P,
  pU5S,
  pU5T,
  pU6,
  pU6D,
  pU6P,
  pU6S,
  pU6T,
  pU7,
  pU7D,
  pU7P,
  pU7S,
  pU7T,
  pU8,
  pU8D,
  pU8P,
  pU8S,
  pU8T,
  pUF1,
  pUF1D,
  pUF1P,
  pUF1S,
  pUF1T,
  pUF2,
  pUF2D,
  pUF2P,
  pUF2T,
  pUF3,
  pUF3D,
  pUF3P,
  pUF3S,
  pUF3T,
  pUF4,
  pUF4D,
  pUF4P,
  pUF4S,
  pUF4T,
  pUF5,
  pUF5D,
  pUF5P,
  pUF5S,
  pUF5T,
  pUF6,
  pUF6D,
  pUF6P,
  pUF6S,
  pUF6T,
  pUF7,
  pUF7D,
  pUF7P,
  pUF7S,
  pUF7T,
  pUF8,
  pUF8D,
  pUF8P,
  pUF8S,
  pUF8T,
  pUFS,
  pUR1,
  pUR1D,
  pUR1P,
  pUR1S,
  pUR1T,
  pUR2,
  pUR2D,
  pUR2P,
  pUR2S,
  pUR2T,
  pUR3,
  pUR3D,
  pUR3P,
  pUR3S,
  pUR3T,
  pUR4,
  pUR4D,
  pUR4P,
  pUR4S,
  pUR4T,
  pUR5,
  pUR5D,
  pUR5P,
  pUR5S,
  pUR5T,
  pUR6,
  pUR6D,
  pUR6P,
  pUR6S,
  pUR6T,
  pUR7,
  pUR7D,
  pUR7P,
  pUR7S,
  pUR7T,
  pUR8,
  pUR8D,
  pUR8P,
  pUR8S,
  pUR8T,
  pURF1,
  pURF1D,
  pURF1P,
  pURF1S,
  pURF1T,
  pURF2,
  pURF2D,
  pURF2P,
  pURF2S,
  pURF2T,
  pURF3,
  pURF3D,
  pURF3P,
  pURF3S,
  pURF3T,
  pURF4,
  pURF4D,
  pURF4P,
  pURF4S,
  pURF4T,
  pURF5,
  pURF5D,
  pURF5P,
  pURF5S,
  pURF5T,
  pURF6,
  pURF6D,
  pURF6P,
  pURF6S,
  pURF6T,
  pURF7,
  pURF7D,
  pURF7P,
  pURF7S,
  pURF7T,
  pURF8,
  pURF8D,
  pURF8P,
  pURF8S,
  pURF8T,
  pURS1,
  pURS1D,
  pURS1P,
  pURS1S,
  pURS1T,
  pURS2,
  pURS2D,
  pURS2P,
  pURS2S,
  pURS2T,
  pURS3,
  pURS3D,
  pURS3P,
  pURS3S,
  pURS3T,
  pURS4,
  pURS4D,
  pURS4P,
  pURS4S,
  pURS4T,
  pURS5,
  pURS5D,
  pURS5P,
  pURS5S,
  pURS5T,
  pURS6,
  pURS6D,
  pURS6P,
  pURS6S,
  pURS6T,
  pURS7,
  pURS7D,
  pURS7P,
  pURS7S,
  pURS7T,
  pURS8,
  pURS8D,
  pURS8P,
  pURS8S,
  pURS8T,
  pUS1,
  pUS1D,
  pUS1P,
  pUS1S,
  pUS1T,
  pUS2,
  pUS2D,
  pUS2P,
  pUS2S,
  pUS2T,
  pUS3,
  pUS3D,
  pUS3P,
  pUS3S,
  pUS3T,
  pUS4,
  pUS4D,
  pUS4P,
  pUS4S,
  pUS4T,
  pUS5,
  pUS5D,
  pUS5P,
  pUS5S,
  pUS5T,
  pUS6,
  pUS6D,
  pUS6P,
  pUS6S,
  pUS6T,
  pUS7,
  pUS7D,
  pUS7P,
  pUS7S,
  pUS7T,
  pUS8,
  pUS8D,
  pUS8P,
  pUS8S,
  pUS8T,
  pinned,
  pinnedD,
  pinnedP,
  pinnedS,
  pinnedT,
};
