import { useMutation } from "hooks/useMutation";
import { financialPackagesApi } from "./api";
import { MG } from "utilities/mockGenerator";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { WarehouseIconKind } from "../models";

const useFinancialPackageDocuments = createPaginatedApiQuery(
  financialPackagesApi.getFinancialPackageSupplies,
);

const useFinancialPackageInventoryChecks = createPaginatedApiQuery(
  financialPackagesApi.getFinancialPackageInventoryChecks,
  () => ({
    created: MG.isoDateTime(),
    difference: MG.number({ min: 1, max: 200 }),
    id: MG.number({ min: 1, max: 9999 }),
    inventoryCheck: MG.number({ min: 1, max: 200 }),
    inWarehouse: MG.number({ min: 1, max: 200 }),
    signature: MG.signature({ collectionType: "INVENTORY_CHECK" }),
    unitPrice: MG.number({ min: 1, max: 100000 }),
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
  }),
);

const useCalculatePeriod = (open: () => void) => {
  return useMutation(financialPackagesApi.postCalculatePeriod, ({ queryUtils }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      open();
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

const useClosePeriod = () => {
  return useMutation(
    financialPackagesApi.postClosePeriod,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );
};

const usePackagesInWarehouseValues = createPaginatedApiQuery(
  financialPackagesApi.getPackagesInWarehouseValues,
);

const usePackageStates = createPaginatedApiQuery(financialPackagesApi.getPackageStates);

export const financialPackagesActions = {
  useCalculatePeriod,
  useClosePeriod,
  useFinancialPackageDocuments,
  useFinancialPackageInventoryChecks,
  usePackagesInWarehouseValues,
  usePackageStates,
};
