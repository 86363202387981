import { queryFetch } from "apiConnectors/queryFetch";
import {
  CalculatePeriodDTO,
  ClosePeriodDTO,
  FinancialPackageDocument,
  FinancialPackageInWarehouseValues,
  FinancialPackageInventoryCheck,
  PackageState,
} from "./models";
import { ApiFetcher } from "hooks/createApiQuery";
import { financialPackagesKeys } from "./keys";
import { Pagination } from "api/types";

const postCalculatePeriod = (data: CalculatePeriodDTO) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/wms/financial-packages/calculate-period",
    data,
  });

const postClosePeriod = (data: ClosePeriodDTO) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/wms/financial-packages/close-period",
    data,
  });

const getFinancialPackageSupplies = (
  search: string = "",
): ApiFetcher<Pagination<FinancialPackageDocument>> => ({
  key: financialPackagesKeys.packageState.supplies(search),
  fetcher: () => {
    const [internalId, params] = search.split("$");
    return queryFetch({
      method: "GET",
      url: `/wms/view-modules/financial-packages/package-supplies/${internalId}${params}`,
    });
  },
});

const getFinancialPackageInventoryChecks = (
  search: string = "",
): ApiFetcher<Pagination<FinancialPackageInventoryCheck>> => ({
  key: financialPackagesKeys.inventoryChecks.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/package-inventory-checks" + search,
    }),
});

const getPackagesInWarehouseValues = (
  search: string = "",
): ApiFetcher<Pagination<FinancialPackageInWarehouseValues>> => ({
  key: financialPackagesKeys.packagesInWarehouseValues.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/packages-in-warehouse-values",
    }),
});

const getPackageStates = (search: string = ""): ApiFetcher<Pagination<PackageState>> => ({
  key: financialPackagesKeys.packageState.states(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/financial-packages/packages/states" + search,
    }),
});

export const financialPackagesApi = {
  getFinancialPackageInventoryChecks,
  getFinancialPackageSupplies,
  getPackagesInWarehouseValues,
  getPackageStates,
  postCalculatePeriod,
  postClosePeriod,
};
