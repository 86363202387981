import { useMutation } from "hooks/useMutation";
import { bankAccountsApi } from "./api";
import { buildBankAccountLink } from "./calls";
import { useNavigate } from "hooks/useNavigate";

const useGetTransactions = () => {
  return useMutation(bankAccountsApi.getTransactions, ({ queryClient, queryUtils, toastr }) => ({
    onSuccess: () => {
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Pobrano transakcje",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
    onError: error => {
      queryClient.invalidateQueries();
      queryUtils.handleCommonResponse.onError(error);
    },
  }));
};

const useBuildBankAccountLink = () => {
  return useMutation(buildBankAccountLink, ({ queryUtils }) => ({
    onSuccess: payload => {
      window.location.href = payload.redirectTo;
    },
    onError: error => {
      queryUtils.handleCommonResponse.onError(error);
    },
  }));
};

const useRefreshBankAccount = () => {
  const navigate = useNavigate();

  return useMutation(bankAccountsApi.refreshBankAccount, ({ queryUtils }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      navigate("/bank-accounts/list");
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

export const bankAccountsActions = {
  useBuildBankAccountLink,
  useGetTransactions,
  useRefreshBankAccount,
};
