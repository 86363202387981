import { SptDefaults, SptDetails } from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { sptKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { Courier, Shipment } from "../models";
import { ShippingService } from "constants/shippingServiceConstants";
import { Assign } from "utility-types";

const getDefaults = (id: Courier["id"]): ApiFetcher<SptDefaults> => ({
  key: sptKeys.defaults(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipping-services-provider/${ShippingService.SPT}/${id}`,
    }),
});

const patchDefaults = (data: { toUpdate: PartialOf<SptDefaults>; id: Courier["id"] }) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipping-services-provider/${ShippingService.SPT}/${data.id}`,
    data: data.toUpdate,
  });

const getDetails = (id: Shipment["id"]): ApiFetcher<SptDetails> => ({
  key: sptKeys.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/shipping/shipments-provider/${ShippingService.SPT}?shipment=${id}`,
    }),
});

const patchDetails = (data: Assign<PartialOf<SptDetails>, { shipments: Shipment["id"][] }>) =>
  queryFetch<Courier>({
    method: "PATCH",
    url: `/shipping/shipments-provider/${ShippingService.SPT}`,
    data,
  });

export const sptApi = {
  patchDefaults,
  patchDetails,
  getDefaults,
  getDetails,
};
