import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting2 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5452)">
        <path
          d="M11.9998 21.391C17.1865 21.391 21.3911 17.1864 21.3911 11.9997C21.3911 6.81299 17.1865 2.6084 11.9998 2.6084C6.81305 2.6084 2.6084 6.81299 2.6084 11.9997C2.6084 17.1864 6.81305 21.391 11.9998 21.391Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M21.3911 11.9994C21.3911 14.4859 20.4051 16.8708 18.6492 18.6314C16.8933 20.3919 14.511 21.3842 12.0245 21.3907C9.53804 21.3973 7.15046 20.4176 5.38536 18.6664C3.62024 16.9151 2.62164 14.5353 2.60853 12.0489C2.59543 9.56248 3.56889 7.17239 5.31545 5.40266C7.06202 3.63293 9.43912 2.62806 11.9256 2.6084L11.9998 11.9994H21.3911Z"
          fill="black"
        />
        <path
          d="M11.9998 1.02148C18.0629 1.02148 22.9781 5.93662 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9998 1.02148Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5452">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
