import { MenuItem } from "components/miloDesignSystem/atoms/menu/types";
import { MenuStateReturn } from "reakit";

const useSelectKeyDownHandler = (menu: MenuStateReturn) => {
  return (
    event: React.KeyboardEvent<HTMLInputElement>,
    menuItems: MenuItem[],
    setSearchValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    if (!menu.visible) {
      menu.show();
    }
    switch (event.key) {
      case "Up":
      case "ArrowUp":
        event.preventDefault();
        menu.last();
        return;
      case "Down":
      case "ArrowDown":
        event.preventDefault();
        menu.first();
        return;
      case "Enter":
        event.preventDefault();

        if (menuItems.length) {
          menuItems[0]?.onClick?.();
        }
        return;
      case "Esc":
      case "Escape":
        event.preventDefault();
        setSearchValue("");
        menu.hide();
        return;
    }
  };
};

export const moleculesUtils = {
  useSelectKeyDownHandler,
};
