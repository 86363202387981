import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../AddOrdersToDraft.module.css";
import { OrderForInvoicing } from "api/orders/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useOrdersColumns } from "./useOrdersColumns";
import { Pagination } from "hooks/createPaginatedQuery";
import { DraftInvoiceOrdersFilters } from "../../AddOrdersToDraft";
import { createDraftDocumentUtils } from "pages/tradingDocuments/createDraftDocument/utils";
import { cx } from "utilities";

interface Props {
  orders: OrderForInvoicing[];
  areAllOrdersInvoiced: boolean;
  areAllSelected: boolean;
  areSomeOrdersSelected: boolean;
  isLoading: boolean;
  isPreviousData: boolean;
  isOrderSelected: (id: number) => boolean;
  pagination: Pagination;
  setAllOrders: () => void;
  setOrder: (id: number) => void;
  selectedOrders: OrderForInvoicing[];
  setFilter: <T extends keyof DraftInvoiceOrdersFilters, U extends DraftInvoiceOrdersFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

export const OrdersList = ({
  orders,
  areAllOrdersInvoiced,
  areAllSelected,
  areSomeOrdersSelected,
  isOrderSelected,
  isLoading,
  isPreviousData,
  pagination,
  setAllOrders,
  setFilter,
  setOrder,
  selectedOrders,
}: Props) => {
  const columns = useOrdersColumns(
    areAllOrdersInvoiced,
    areAllSelected,
    areSomeOrdersSelected,
    isOrderSelected,
    setAllOrders,
    setOrder,
  );

  return (
    <div>
      <div className={styles.selectedInfo}>
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          Zaznaczono:
        </Typography>
        <Typography color="neutralBlack88" fontSize="10" fontWeight="700">
          {selectedOrders.length}
        </Typography>
      </div>
      <div className={cx("d-flex flex-column flex-1 overflow-hidden", styles.tableContainer)}>
        <Table
          columns={columns}
          rows={orders}
          isLoading={isPreviousData || isLoading}
          error={null}
          pagination={pagination}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          uiSchema={comfortableListUiSchema}
          overrides={() => {
            return {
              row: row => {
                if (createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(row)) {
                  return {
                    className: "dashedTableRow",
                  };
                }
                return {};
              },
            };
          }}
        />
      </div>
    </div>
  );
};
