import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";
import { ManufacturingItem } from "./models";

export const manufacturingKeys = {
  manufacturingItemsProgress: createPaginatedKey("manufacturingItemsProgress"),
  indexStages: createPaginatedKey("indexStages"),
  stageComponents: createPaginatedKey("stageComponents"),
  orderDeliveries: createPaginatedKey("orderDeliveries"),
  materialOrders: createPaginatedKey("materialOrders"),
  manufacturingOrders: createPaginatedKey("manufacturingOrders"),
  manufacturingOrder: (orderId: UUID) => ["manufacturingOrder", orderId],
  recipes: createPaginatedKey("recipes"),
  manufacturingPlan: (manufacturingId: string) => ["manufacturingPlan", manufacturingId],
  manufacturingPosition: (route: number) => ["manufacturingPosition", route],
  manufacturingItems: createPaginatedKey("manufacturingItems"),
  manufacturingItem: (id: ManufacturingItem["id"]) => ["manufacturingItem", id],
  settings: {
    stageBoardAttributesKind: createPaginatedKey("stageBoardAttributesKind"),
  },
  manufacturerOrderMaterial: {
    list: createPaginatedKey("manufacturerOrdersMaterials"),
  },
  commission: {
    list: createPaginatedKey("serviceProviderCommissions"),
  },
  email: {
    list: createPaginatedKey("manufacturerOrderEmails"),
  },
};
