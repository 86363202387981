import { useQuery } from "hooks";
import { ToolbarTextFilter } from "../toolbar/types";
import { useEffect, useState } from "react";
import styles from "./FiltersDrawer.module.css";
import { DrawerSection } from "components/utils/drawer/Drawer";

export const TextFilter = ({
  filter,
  query,
}: {
  filter: ToolbarTextFilter;
  query: Record<string, string>;
}) => {
  const { updateQuery } = useQuery({ exclude: ["panelId"] });
  const [value, setValue] = useState(query[filter.name] || "");
  const queryValue = query[filter.name];

  useEffect(() => {
    setValue(queryValue || "");
  }, [queryValue]);

  const handleChange = (value: string) => {
    updateQuery({ [filter.name]: value });
  };

  return (
    <DrawerSection title={filter.label}>
      <div className="ml-2">
        <input
          className={styles.input}
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder={filter.placeholder || "Szukaj..."}
          onBlur={() => handleChange(value)}
        />
      </div>
    </DrawerSection>
  );
};
