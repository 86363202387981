import { ManufacturingUnitStatus } from "api/manufacturing/units/enums";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const getStatusContants = (status: ManufacturingUnitStatus) => {
  switch (status) {
    case ManufacturingUnitStatus.NOT_STARTED:
      return { tag: <Tag label="nierozpoczęte" variant="quaternary" type="outlined" /> };
    case ManufacturingUnitStatus.FINISHED:
      return { tag: <Tag label="gotowe" variant="success" type="filled" /> };
    case ManufacturingUnitStatus.IN_PROGRESS:
      return { tag: <Tag label="w trakcie" variant="warning" type="filled" /> };
    default:
      const exhaustiveCheck = status;
      console.error(`Unhandled manufacturing status code: ${exhaustiveCheck}`);
      return { tag: EMPTY_VALUE };
  }
};

export const manufacturingUnitConstants = {
  getStatusContants,
};
