import { createApiQuery } from "hooks/createApiQuery";
import { wmsApi } from "./api";

const useScannedPositionsInvoicingStatus = createApiQuery(
  wmsApi.getScannedPositionsInvoicingStatus,
);

export const wmsActions = {
  useScannedPositionsInvoicingStatus,
};
