import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateInvoiceValues } from "../CreateInvoicesForMultipleOrdersModal";

interface Props {
  conversionRate?: number;
  fieldName: "amountWithoutTax" | "amountWithTax" | "taxAmountBeforeRounding";
  orders: CreateInvoiceValues[];
  vatRate: number;
}

export const AmountForVatRate = ({ conversionRate, fieldName, orders, vatRate }: Props) => {
  const getAmount = () => {
    const filteredPositions = orders.flatMap(order =>
      order.positions.filter(position => {
        return (
          position.vatRate === vatRate &&
          position[fieldName] !== null &&
          position[fieldName] !== undefined
        );
      }),
    );

    const totalAmount =
      fieldName === "amountWithoutTax"
        ? filteredPositions.reduce((acc, position) => {
            return acc + position[fieldName] * position.quantity;
          }, 0)
        : filteredPositions.reduce((acc, position) => {
            return acc + position[fieldName] * position.quantity;
          }, 0);

    return totalAmount;
  };

  const getCurrencyConvertion = () => {
    const filteredPositions = orders.flatMap(order =>
      order.positions.filter(position => {
        return (
          position.vatRate === vatRate &&
          position[fieldName] !== null &&
          position[fieldName] !== undefined
        );
      }),
    );

    const totalAmount = filteredPositions.reduce((acc, position) => {
      return acc + position[fieldName] * position.quantity * conversionRate!;
    }, 0);

    return totalAmount;
  };

  if (
    orders.some(order =>
      order.positions
        .filter(position => position.vatRate === vatRate)
        .some(
          position =>
            position[fieldName] === undefined ||
            position[fieldName] === null ||
            position[fieldName] < 0,
        ),
    )
  ) {
    return (
      <div className="d-flex align-items-center justify-content-end text-color-coral">
        Błąd przeliczania
      </div>
    );
  }

  if (conversionRate) {
    return (
      <>
        <strong className="body-14 fw-800">
          <FinanceAmountFormat value={getCurrencyConvertion()} />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-end">
      <FinanceAmountFormat value={getAmount()} />
    </div>
  );
};
