import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { Ticket } from "../types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { UserWithInitials } from "api/users/models";

export const AssignEmployee = ({ ticket }: { ticket: Ticket }) => {
  const employees = useSelector(store => store.partials.employees);
  const employeesOptions = employees.map(employee => ({
    icon: employee.avatar,
    text: `${employee.firstName} ${employee.lastName}`,
    type: MenuItemType.ICON,
    value: employee.id,
  }));

  return (
    <Select.Async
      items={employeesOptions}
      mutationHook={() => manufacturingUnitsActions.usePatchUnitItem()}
      selected={ticket.employee?.id ?? null}
      transformQueryData={employee => {
        const searchedEmployee = employees.find(_employee => _employee.id === employee);
        assertIsDefined(searchedEmployee);
        return {
          id: ticket.id,
          employee: searchedEmployee as UserWithInitials,
        };
      }}
    />
  );
};
