import { ExpressService } from "api/orders/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { MdiBicycle } from "components/miloDesignSystem/atoms/icons/MdiBicycle";
import { MdiRaceCar } from "components/miloDesignSystem/atoms/icons/MdiRaceCar";
import { MdiRocketLaunch } from "components/miloDesignSystem/atoms/icons/MdiRocketLaunch";
import { BaseIcon } from "components/miloDesignSystem/atoms/icons/types";
import expressServiceA from "assets/images/mapIcons/expressService_A.png";
import expressServiceB from "assets/images/mapIcons/expressService_B.png";
import expressServiceC from "assets/images/mapIcons/expressService_C.png";
import rocketLaunchIcon from "assets/images/rocketLaunch.svg";
import raceCarIcon from "assets/images/raceCar.svg";
import bicycleIcon from "assets/images/bicycle.svg";
import { pluralize } from "utilities";

const getServiceDetailsBasedOnType = (
  type: ExpressService["type"],
): {
  backgroundColor: ColorPalette;
  icon: (props: BaseIcon) => JSX.Element;
  mapIcon: string;
  textColor: ColorPalette;
  titleIcon: string;
} => {
  switch (type) {
    case "A":
      return {
        backgroundColor: "red300",
        icon: MdiRocketLaunch,
        mapIcon: expressServiceB,
        textColor: "red25",
        titleIcon: rocketLaunchIcon,
      };
    case "B":
      return {
        backgroundColor: "orange300",
        icon: MdiRaceCar,
        mapIcon: expressServiceA,
        textColor: "orange25",
        titleIcon: raceCarIcon,
      };
    case "C":
      return {
        backgroundColor: "blue300",
        icon: MdiBicycle,
        mapIcon: expressServiceC,
        textColor: "blue25",
        titleIcon: bicycleIcon,
      };
  }
};

const formatExpireTime = (hours: number) => {
  if (hours < 72) {
    return `${hours} ${pluralize.pl(hours, {
      singular: "godzina",
      plural: "godziny",
      other: "godzin",
    })}`;
  }

  const days = Math.floor(hours / 24);

  return `${days} ${pluralize.pl(days, {
    singular: "dzień",
    plural: "dni",
    other: "dni",
  })}`;
};

export const expressServicesUtils = {
  formatExpireTime,
  getServiceDetailsBasedOnType,
};
