import { orderActions } from "api/orders/actions";
import { ExpressService } from "api/orders/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { cx, queryString } from "utilities";
import styles from "./ExpressServices.module.css";
import { expressServicesUtils } from "./utils/expressServicesUtils";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { expressServicesConstants } from "./constants/expressServicesConstants";
import { useToggle } from "hooks";
import { CreateOrModifyExpressServiceModal } from "./components/createOrModifyExpressServiceModal/CreateOrModifyExpressServiceModal";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { MdiCopyAll } from "components/miloDesignSystem/atoms/icons/MdiCopyAll";
import { useState } from "react";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";

export const ExpressServices = () => {
  const search = queryString.stringify({
    pageSize: 3,
  });
  const { data: expressServices, error, isLoading } = orderActions.useExpressServices(search);

  if (error) {
    return (
      <PageWrapper>
        <PageHeader searchBar={false} title="Przesyłki ekspresowe" viewLabel="EXPRESS_SERVICES" />
        <div className="d-flex flex-wrap p-3 gap-3 h-100 align-items-center justify-content-center">
          <CommonError status={error._httpStatus_} />
        </div>
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper>
        <PageHeader searchBar={false} title="Przesyłki ekspresowe" viewLabel="EXPRESS_SERVICES" />
        <div className="d-flex flex-wrap p-3 gap-3 h-100 align-items-center justify-content-center">
          <Spinner size={40} />
        </div>
      </PageWrapper>
    );
  }

  if (!expressServices) return null;

  return (
    <PageWrapper>
      <PageHeader searchBar={false} title="Przesyłki ekspresowe" viewLabel="EXPRESS_SERVICES" />
      <div className="d-flex flex-wrap p-3 gap-3 overflow-auto">
        <ExpressServiceItem expressServices={expressServices} type="A" />
        <ExpressServiceItem expressServices={expressServices} type="B" />
        <ExpressServiceItem expressServices={expressServices} type="C" />
      </div>
    </PageWrapper>
  );
};

const ExpressServiceItem = ({
  expressServices,
  type,
}: {
  expressServices: ExpressService[];
  type: ExpressService["type"];
}) => {
  const expressService = expressServices.find(service => service.type === type);
  const serviceDetailsBasedOnType = expressServicesUtils.getServiceDetailsBasedOnType(type);
  const hasService = Boolean(expressService);
  const alertMutation = orderActions.usePatchExpressService();
  const createOrModifyExpressServiceModal = useToggle();
  const [isAnimating, setIsAnimating] = useState(false);

  return (
    <div className="cursor-pointer" onClick={createOrModifyExpressServiceModal.open}>
      <Paper
        className={cx("d-flex flex-column gap-1 py-3 pr-2 pl-3", {
          [styles.emptyService]: expressService === undefined,
          [styles.service]: Boolean(expressService),
        })}
        minHeight={380}
        minWidth="352px"
        width="352px"
      >
        <div
          className={cx("pl-2", {
            [styles.opacity]: !hasService,
          })}
        >
          <img alt={`Przesyłka ${type}`} src={serviceDetailsBasedOnType.titleIcon} />
        </div>
        <div className="d-flex flex-column gap-2 p-2">
          <div className={styles.serviceName}>
            <Typography
              color={Boolean(expressService) ? "neutralBlack88" : "neutralBlack20"}
              fontSize="26"
              fontWeight="700"
            >
              {expressService?.name || "Nazwa etykiety"}
            </Typography>
          </div>
          <div className="d-flex flex-column pb-2 gap-2">
            <Typography
              className="text-uppercase"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="700"
            >
              Czas na dostarczenie:
            </Typography>
            <Typography
              color={hasService ? "neutralBlack88" : "neutralBlack20"}
              fontSize="16"
              fontWeight="400"
            >
              {hasService
                ? expressServicesUtils.formatExpireTime(expressService!.value)
                : "nie określono"}
            </Typography>
          </div>
          <div className="d-flex flex-column">
            <Typography
              className="text-uppercase"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="700"
            >
              Wyświetl alert gdy pozostanie:
            </Typography>
            <div
              className={cx("d-flex align-items-center gap-1", {
                [styles.opacity]: !hasService,
              })}
            >
              {expressServicesConstants.serviceAlerts.map(serviceAlert => (
                <div
                  className={cx({
                    "cursor-pointer": hasService,
                    "cursor-not-allowed":
                      !hasService ||
                      (alertMutation.isLoading &&
                        alertMutation.variables?.id === expressService?.id),
                  })}
                  key={serviceAlert.key}
                  onClick={event => {
                    event.stopPropagation();

                    if (hasService) {
                      alertMutation.mutate({
                        id: expressService!.id,
                        [serviceAlert.key]: !expressService![serviceAlert.key],
                      });
                    }
                  }}
                >
                  <Tag
                    label={serviceAlert.label}
                    type={hasService && expressService![serviceAlert.key] ? "filled" : "outlined"}
                    variant={
                      hasService && expressService![serviceAlert.key]
                        ? "deepPurple50"
                        : "quaternary"
                    }
                  />
                </div>
              ))}
            </div>
          </div>
          {expressService && (
            <div className="d-flex align-items-center gap-2 pt-2">
              <div className="d-flex flex-column">
                <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                  klucz usługi
                </Typography>
                <Typography fontSize="12" fontWeight="400">
                  {expressService.id}
                </Typography>
                <Typography
                  className="italic"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="600"
                >
                  * wymagany do połączenia API
                </Typography>
              </div>
              <div className={cx("position-relative", styles.copyContainerButton)}>
                <CopyToClipboardComponent text={expressService.id}>
                  <IconButton
                    icon={MdiCopyAll}
                    onClick={event => {
                      event.stopPropagation();
                      setIsAnimating(true);
                    }}
                    size="small"
                    variant="transparent"
                  />
                </CopyToClipboardComponent>
                <div
                  className={cx(styles.checkIcon, { [styles.successCheckAnimation]: isAnimating })}
                  onAnimationEnd={() => setIsAnimating(false)}
                />
              </div>
            </div>
          )}
        </div>
      </Paper>
      {createOrModifyExpressServiceModal.isOpen && (
        <CreateOrModifyExpressServiceModal
          close={createOrModifyExpressServiceModal.close}
          expressService={expressService}
          type={type}
        />
      )}
    </div>
  );
};
