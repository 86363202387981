import { ManufacturingItemStatus } from "api/orders/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const getStatusContants = (status: ManufacturingItemStatus) => {
  switch (status) {
    case ManufacturingItemStatus.PENDING:
      return { tag: <Tag label="nierozpoczęte" variant="quaternary" type="outlined" /> };
    case ManufacturingItemStatus.DONE:
      return { tag: <Tag label="gotowe" variant="success" type="filled" /> };
    case ManufacturingItemStatus.IN_PROGRESS:
      return { tag: <Tag label="w trakcie" variant="warning" type="filled" /> };
    default:
      const exhaustiveCheck = status;
      console.error(`Unhandled manufacturing status code: ${exhaustiveCheck}`);
      return { tag: EMPTY_VALUE };
  }
};

enum DRAGGABLE_BOARD_ITEMS {
  "TODO_MWU" = "TODO_MWU",
  "TODO_GROUP" = "TODO_GROUP",
  "IN_PROGRESS_GROUP" = "IN_PROGRESS_GROUP",
  "IN_PROGRESS_MWU" = "IN_PROGRESS_MWU",
}

export const manufacturingItemsConstants = {
  getStatusContants,
  DRAGGABLE_BOARD_ITEMS,
};
