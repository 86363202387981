import { manufacturingUnitsApi } from "api/manufacturing/units/api";
import { ManufacturingUnitListViewItem } from "api/manufacturing/units/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiChangeStatus } from "components/miloDesignSystem/atoms/icons/MdiChangeStatus";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { useMutation } from "hooks/useMutation";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";

interface Props {
  selectedRows: ManufacturingUnitListViewItem[];
}

export const ChangeStatus = ({ selectedRows }: Props) => {
  const stageId = useStageId();
  const markUnitAsInProgressMutation = useMutation(
    manufacturingUnitsApi.postGroup,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );
  const markUnitAsReadyMutation = useMutation(
    manufacturingUnitsApi.postSetAsFinished,
    ({ queryUtils }) => queryUtils.handleCommonResponse,
  );

  return (
    <Popover
      hideOnClickOutside={false}
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={hide}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div className="p-1 pr-3 pb-2">
            <Typography className="mb-2" fontSize="16" fontWeight="700">
              Zmień status
            </Typography>

            <Button
              size="medium"
              className="mb-2"
              variant="transparent"
              isLoading={
                markUnitAsInProgressMutation.isLoading || markUnitAsReadyMutation.isLoading
              }
              onClick={() => {
                markUnitAsReadyMutation.mutate(
                  {
                    manufacturingWorkingUnitsIds: selectedRows.map(row => row.id),
                  },
                  {
                    onSuccess: () => {
                      hide();
                    },
                  },
                );
              }}
            >
              Gotowe
            </Button>
            <Button
              size="medium"
              variant="transparent"
              isLoading={
                markUnitAsInProgressMutation.isLoading || markUnitAsReadyMutation.isLoading
              }
              onClick={() => {
                markUnitAsInProgressMutation.mutate(
                  {
                    attributes: [],
                    manufacturingWorkingUnitIds: selectedRows.map(row => row.id),
                    schema_stage_id: stageId,
                  },
                  {
                    onSuccess: () => {
                      hide();
                    },
                  },
                );
              }}
            >
              W trakcie
            </Button>
          </div>
        </ClickOutsideHandler>
      )}
      variant="DARK"
    >
      <div>
        <Tooltip title="Zmień status">
          <IconButton
            isLoading={markUnitAsInProgressMutation.isLoading || markUnitAsReadyMutation.isLoading}
            icon={MdiChangeStatus}
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "trading-documents-list-popover-ignore-class-name";
