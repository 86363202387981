import { CommonError, Pagination } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { DashedLabel } from "../../../subcomponents/DashedLabel";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import {
  ManufacturingUnitGroup,
  ManufacturingPriority,
  ManufacturingUnitGroupType,
} from "api/manufacturing/units/models";
import { ColumnWrapper } from "../ColumnWrapper";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { useQuery, useSelector } from "hooks";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { BoardFormat } from "api/manufacturing/schemas/models";
import { useStageId } from "pages/manufacturingNew/manufacturingStages/hooks/useStageId";
import { useFilters } from "hooks/useFilters";
import { manufacturingItemsConstants } from "constants/manufacturingItemsConstants";
import { DrawerRenderer, useManufacturingBoardDrawer } from "../../panel/DrawerRenderer";
import { ColumnType } from "../../ColumnView";
import { memo, useMemo } from "react";
import { getDraggableElementStyles } from "../shared";
import { useIsMutating } from "react-query";
import { OrderTypeChoices } from "api/orders/enums";
import { pluralize } from "utilities";

export const InProgressSection = () => {
  const { query, updateQuery } = useQuery();
  const stageId = useStageId();
  const manufacturingStages = useSelector(store => store.partials.stages);
  const manufacturingStage = manufacturingStages.find(stage => stage.id === stageId);

  const { filters, setFilter, searchParams } = useFilters({
    showGroups: manufacturingStage?.boardFormat === BoardFormat.GROUP ? "" : "false",
    schemaStageId: stageId,
    status: "IN_PROGRESS",
    search: query.inProgressUnitsGroupsSearch,
    page: 1,
  });

  const {
    data: units,
    error,
    isLoading,
    isFetching,
    isPreviousData,
    pagination,
  } = manufacturingUnitsActions.useGetManufacturingUnitsGroups(searchParams, {
    keepPreviousData: true,
  });

  const { data: manufacturingItemCount } = manufacturingUnitsActions.useStatistics({
    stageId,
    search: "",
  });

  const isMutationLoading = useIsMutating();
  const sortedUnits = useMemo(() => {
    //@ts-ignore
    return manufacturingStagesUtils.getSortedManufacturingUnitGroups<ManufacturingUnitGroup>(
      units ?? [],
      "startedAt",
    );
  }, [units]);

  if (isLoading && !isPreviousData)
    return (
      <div className="d-flex position-relative flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center overflow-hidden h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  if (error) {
    return (
      <div className="position-relative d-flex overflow-hidden flex-1 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <CommonError status={error._httpStatus_} />
          </div>
        </ColumnWrapper>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column overflow-hidden flex-1 px-0">
      <Droppable droppableId={manufacturingStagesConstants.IN_PROGRESS_DROPPABLE}>
        {(provided, snapshot) => (
          <ColumnWrapper
            isDragOver={snapshot.isDraggingOver}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="d-flex align-items-center justify-content-between py-2 gap-3">
              <div className="d-flex align-items-center gap-2">
                <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
                  W trakcie
                </Typography>
                <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                  {manufacturingItemCount?.counts.inProgress || "brak"}
                </Typography>
                {(isFetching || Boolean(isMutationLoading)) && <Spinner size={16} />}
              </div>
              <ColumnSearch
                isLoading={isFetching}
                queryKey="inProgressUnitsGroupsSearch"
                onChange={search =>
                  updateQuery({ ...query, inProgressUnitsGroupsSearch: search ?? "" })
                }
                value={query["inProgressUnitsGroupsSearch"]}
              />
            </div>

            <ListWrapper sortedUnits={sortedUnits} />

            <span
              style={{
                display: "none",
              }}
            >
              {provided.placeholder}
            </span>
          </ColumnWrapper>
        )}
      </Droppable>
      <Pagination
        page={filters.page}
        onChange={page => setFilter("page", page)}
        className="position-relative"
        mode="manual"
        removeMarginLeft
        pagination={pagination}
      />

      <DrawerRenderer columnType={ColumnType.IN_PROGRESS} />
    </div>
  );
};

const ListWrapper = memo(
  ({ sortedUnits }: { sortedUnits: (string | ManufacturingUnitGroup)[] }) => {
    const { toggleDrawer } = useManufacturingBoardDrawer();
    return (
      <div className="d-flex flex-column">
        {sortedUnits.map((element, index) => {
          if (typeof element === "string") {
            return <DashedLabel key={element} label={element} />;
          }
          if (element.type === ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT)
            return (
              <Draggable
                draggableId={`${manufacturingItemsConstants.DRAGGABLE_BOARD_ITEMS.IN_PROGRESS_MWU};${element.id}`}
                key={element.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getDraggableElementStyles(snapshot, provided.draggableProps.style)}
                  >
                    <ManufacturingTicket
                      canAssignEmployee
                      isDragging={snapshot.isDragging}
                      onClick={ticket => {
                        toggleDrawer({
                          columnType: ColumnType.IN_PROGRESS,
                          id: String(element.id),
                          type: "unitItem",
                        });
                      }}
                      ticket={{
                        attributesValues: element.attributes,
                        itemNote: element.manufacturingItems[0].itemNote,
                        productName: element.manufacturingItems[0].name,
                        externalOrderNumber: element.manufacturingItems[0].externalOrderNumber,
                        isComplaint:
                          element.manufacturingItems[0].orderType === OrderTypeChoices.COMPLAINT,
                        employee: element.employee,
                        id: element.id,
                        declinedElements: element.declinedElements || 0,
                        cancelledElements: element.cancelledElements || 0,
                        implementedBy: element.implementedBy,
                        manufacturer: element.manufacturer?.name,
                        manufacturingItems: element.manufacturingItems.map(item => item.id),
                        note: element.note,
                        priority: element.priority as ManufacturingPriority,
                        recentlyMovedDate: element.startedAt,
                        signature: element.manufacturingItems[0].signature,
                      }}
                    />
                  </div>
                )}
              </Draggable>
            );
          const itemNoteCount = element.manufacturingItems.filter(item => item.itemNote).length;

          return (
            <Draggable
              draggableId={`${manufacturingItemsConstants.DRAGGABLE_BOARD_ITEMS.IN_PROGRESS_GROUP};${element.id}`}
              key={element.id}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getDraggableElementStyles(snapshot, provided.draggableProps.style)}
                >
                  <ManufacturingTicket
                    isDragging={snapshot.isDragging}
                    onClick={ticket => {
                      toggleDrawer({
                        columnType: ColumnType.IN_PROGRESS,
                        id: String(element.id),
                        type: "group",
                      });
                    }}
                    ticket={{
                      attributesValues: element.attributes,
                      productName: element.manufacturingItems[0].name,
                      externalOrderNumber: "",
                      cancelledElements: element.cancelledElements!,
                      declinedElements: element.declinedElements!,
                      itemNote: Boolean(itemNoteCount)
                        ? `${itemNoteCount} ${pluralize.pl(itemNoteCount, {
                            singular: "komentarz",
                            plural: "komentarze",
                            other: "komentarzy",
                          })}`
                        : "",
                      elementsCount: element.elementsCount,
                      id: element.id,
                      manufacturingItems: element.manufacturingItems.map(item => item.id),
                      isComplaint: element.manufacturingItems.some(
                        mi => mi.orderType === OrderTypeChoices.COMPLAINT,
                      ),
                      recentlyMovedDate: element.startedAt,
                      signature: element.signature,
                    }}
                  />
                </div>
              )}
            </Draggable>
          );
        })}
      </div>
    );
  },
);
