import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiRocketLaunch = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.17097 15.0233C7.25829 15.2853 7.11671 15.5684 6.85474 15.6557C5.4764 16.1152 4.76051 17.2676 4.38674 18.3889C4.20135 18.9451 4.10772 19.4738 4.0608 19.8648C4.05738 19.8933 4.05421 19.9211 4.05127 19.9481C4.07823 19.9451 4.10601 19.942 4.13456 19.9385C4.52553 19.8916 5.05426 19.798 5.61044 19.6126C6.73176 19.2388 7.88416 18.523 8.34361 17.1446C8.43093 16.8826 8.71409 16.7411 8.97606 16.8284C9.23803 16.9157 9.37961 17.1989 9.29229 17.4608C8.69108 19.2645 7.19183 20.1396 5.92667 20.5613C5.2896 20.7737 4.69138 20.8789 4.25371 20.9314C4.03414 20.9578 3.85307 20.9711 3.7253 20.9778C3.66137 20.9812 3.61064 20.9829 3.57495 20.9838C3.5571 20.9842 3.543 20.9844 3.53287 20.9846L3.5207 20.9847L3.5169 20.9847L3.51558 20.9847C3.51558 20.9847 3.36129 21.0083 3.1583 20.8347C3.02155 20.7177 3.01465 20.4843 3.01465 20.4843L3.01465 20.4825L3.01467 20.4787L3.01478 20.4665C3.0149 20.4564 3.01514 20.4423 3.01558 20.4244C3.01646 20.3887 3.01819 20.338 3.02155 20.2741C3.02828 20.1463 3.04158 19.9652 3.06793 19.7456C3.12045 19.308 3.2257 18.7098 3.43805 18.0727C3.85978 16.8075 4.73488 15.3083 6.53852 14.7071C6.80049 14.6197 7.08365 14.7613 7.17097 15.0233ZM3.1583 20.8347C3.02155 20.7177 3.01465 20.4843 3.01465 20.4843C3.01465 20.7604 3.23943 20.9847 3.51558 20.9847C3.51558 20.9847 3.36129 21.0083 3.1583 20.8347Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8952 3.89809C18.9797 3.76149 16.7446 3.7208 14.4753 5.99004L8.46492 12.0005L12.0005 15.536L18.0109 9.52558C20.2801 7.25633 20.2394 5.02125 20.1028 4.10569C20.095 4.05343 20.0706 4.00499 20.0333 3.96763C19.9959 3.93026 19.9475 3.90588 19.8952 3.89809ZM20.0427 2.90903C18.9028 2.73898 16.3185 2.73271 13.7682 5.28294L7.40426 11.6469C7.31049 11.7407 7.25781 11.8678 7.25781 12.0005C7.25781 12.1331 7.31049 12.2602 7.40426 12.354L11.6469 16.5966C11.8422 16.7919 12.1587 16.7919 12.354 16.5966L18.718 10.2327C21.2682 7.68245 21.2619 5.09806 21.0919 3.95819M20.0427 2.90903C20.3069 2.94843 20.5515 3.07165 20.7404 3.26052C20.9293 3.44939 21.0525 3.694 21.0919 3.95819"
    />
    <path d="M17.611 11.3396L17.3036 16.993C17.3036 17.0915 17.2842 17.189 17.2465 17.28C17.2088 17.371 17.1535 17.4537 17.0839 17.5233L14.0519 20.5553C13.9559 20.6513 13.8356 20.7195 13.704 20.7525C13.5723 20.7855 13.4341 20.7822 13.3042 20.7428C13.1743 20.7035 13.0575 20.6296 12.9662 20.5291C12.875 20.4286 12.8127 20.3052 12.7861 20.1721L12.354 16.5966" />
    <path d="M12.3541 6.69703H7.00763C6.90913 6.69703 6.81161 6.71643 6.72061 6.75412C6.62962 6.79181 6.54694 6.84705 6.4773 6.9167L3.44526 9.94874C3.34927 10.0447 3.28113 10.165 3.24811 10.2966C3.21509 10.4283 3.21843 10.5665 3.25777 10.6964C3.29711 10.8263 3.37097 10.9431 3.47148 11.0344C3.57199 11.1256 3.69539 11.1879 3.8285 11.2145L7.40426 11.6469" />
  </SvgWrapper>
);
