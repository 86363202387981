import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRef } from "react";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { TitleSection } from "./titleSection/TitleSection";
import { GeneralInfoSection } from "./generalInfoSection/GeneralInfoSection";
import { BuyerSection } from "./buyerSection/BuyerSection";
import { manufacturingActions } from "api/manufacturing/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const {
    data: manufacturingItem,
    error,
    isLoading,
  } = manufacturingActions.useGetManufacturingItem(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturingItem) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={manufacturingItem.id}>
        <PanelHeader manufacturingItem={manufacturingItem} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef}>
            <TitleSection manufacturingItem={manufacturingItem} />
            <GeneralInfoSection manufacturingItem={manufacturingItem} />
            <BuyerSection manufacturingItem={manufacturingItem} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
