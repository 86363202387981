import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting6 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5456)">
        <path
          d="M11.9997 21.391C17.1864 21.391 21.391 17.1864 21.391 11.9997C21.391 6.81304 17.1864 2.6084 11.9997 2.6084C6.81304 2.6084 2.6084 6.81304 2.6084 11.9997C2.6084 17.1864 6.81304 21.391 11.9997 21.391Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M2.6084 11.9932C2.60811 11.1749 2.87449 9.46225 3.30994 8.43891C4.04438 6.71291 5.20403 5.25948 6.73687 4.22232C8.26968 3.18517 10.0748 2.624 11.9255 2.60938L11.9997 12.0003L2.6084 11.9932Z"
          fill="black"
        />
        <path
          d="M2.6084 11.9932C2.60811 11.1749 2.87449 9.46225 3.30994 8.43891C4.04438 6.71291 5.20403 5.25948 6.73687 4.22232C8.26968 3.18517 10.0748 2.624 11.9255 2.60938L11.9997 12.0003L2.6084 11.9932Z"
          fill="black"
        />
        <path
          d="M11.9997 1.02148C18.0629 1.02148 22.978 5.93662 22.978 11.9997C22.978 18.0629 18.0629 22.978 11.9997 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9997 1.02148Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5456">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
