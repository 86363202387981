import { financialPackagesActions } from "api/wms/financial-packages/actions";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { usePackagesInWarehouseValuesColumns } from "./usePackagesInWarehouseValuesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { FinancialPackageInWarehouseValues } from "api/wms/financial-packages/models";

export const PackagesInWarehouseValues = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: packagesValues,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financialPackagesActions.usePackagesInWarehouseValues(search);
  const columns = usePackagesInWarehouseValuesColumns();
  const tableProps = useTableFeatureConnector({
    rows: packagesValues,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<FinancialPackageInWarehouseValues>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  return (
    <PageHeader
      searchInput={{
        label: "Szukaj...",
        tags: [],
      }}
      title="Łączna wycena paczek"
      viewLabel="FINANCIAL_PACKAGES_IN_WAREHOUSE_VALUES"
    />
  );
};
