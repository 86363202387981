import emptyIcon from "assets/images/el13.png";
import cx from "classnames";
import { Button } from "components/common";
import { HistoryLogItem } from "components/common/historyLogItem";
import { ClickOutsideHandler } from "components/utils";
import { UserAvatar } from "components/utils/userAvatar";
import { FLAVOR } from "CONSTANTS";
import cuid from "cuid";
import { useSelector, useToggle } from "hooks";
import { useStateInfiniteLogs } from "hooks/useInfinityLogs";
import * as React from "react";
import styles from "./PackageHistory.module.css";
import { historyCategories } from "pages/warehouse/packageHistory/historyCategories";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Assign } from "utility-types";
import { MdiArrowBack } from "components/miloDesignSystem/atoms/icons/MdiArrowBack";
import { Spinner as MdsSpinner } from "components/miloDesignSystem/atoms/spinner";
import { useFilters } from "hooks/useFilters";

export interface PackageHistoryProps {
  uniquePackage: string;
  packageName: string;
}

export const PackageHistoryView = ({
  uniquePackage,
  customCloseModal,
  packageName,
}: Assign<PackageHistoryProps, { customCloseModal: () => void }>) => {
  const users = useSelector(state => state.partials.users);
  const { filters, setFilter } = useFilters({
    category: "",
    user: "",
  });
  const { logs, fetchMore, inProgress, hasMore } = useStateInfiniteLogs(
    uniquePackage,
    "uniquePackage",
    filters,
  );

  const noResults = !logs.length && !inProgress;

  return (
    <div className={styles.content}>
      <div className="d-flex flex-1 justify-content-between border-bottom p-2">
        {inProgress ? (
          <div className="ml-2">
            <MdsSpinner size={20} />
          </div>
        ) : (
          <div className="d-flex align-items-center gap-1 ml-1">
            <IconButton icon={MdiArrowBack} onClick={customCloseModal} variant="transparent" />
            <Typography fontSize="16" fontWeight="700">
              {packageName}
            </Typography>
          </div>
        )}

        <div>
          <FilterDropdown
            onChange={option => {
              setFilter("category", option.value);
            }}
            value={filters.category}
            label="kategoria zdarzenia"
            name="category"
            options={historyCategories.map(el => ({
              ...el,
              img: (
                <div className={styles.iconContainer} style={{ background: el.color }}>
                  <img src={el.img} alt="" />
                </div>
              ),
            }))}
            defaultValue=""
          />
          {FLAVOR === "main" && (
            <FilterDropdown
              onChange={option => {
                setFilter("user", option.value);
              }}
              value={filters.user}
              label="użytkownik"
              name="user"
              options={[
                {
                  color: "",
                  img: <UserAvatar className="mr-2" />,
                  label: "Wszyscy",
                  value: "",
                },
                ...users.map(user => ({
                  color: "",
                  img: <UserAvatar avatarSrc={user.avatar || ""} id={user.id} className="mr-2" />,
                  label: `${user.firstName} ${user.lastName}`,
                  value: String(user.id),
                })),
              ]}
              defaultValue=""
            />
          )}
        </div>
      </div>

      {inProgress && (
        <div className="d-flex flex-1 align-items-center justify-content-center mt-5">
          <MdsSpinner size={40} />
        </div>
      )}

      {Boolean(noResults) ? (
        <div className="d-flex justify-content-center align-items-center w-100 flex-column py-4">
          <img src={emptyIcon} alt="" />
          <h4 className="mt-5 mb-3">Nie udało nam się znaleźć tego, czego szukasz</h4>
          <h5 className="text-color-grey">Zmień kryteria wyszukania</h5>
        </div>
      ) : (
        <div className={cx(styles.listWrapper)}>
          <div className="p-3">
            <div className={styles.listGroup}>
              {Boolean(logs.length) &&
                logs.map(log => {
                  const icon = historyCategories.find(el => el.value === log.category!.code);

                  return (
                    <HistoryLogItem
                      showIcon
                      key={log.id}
                      log={log}
                      icon={icon && { img: icon.img, color: icon.color }}
                    />
                  );
                })}
            </div>
            <div className="d-flex mt-4 mb-3">
              {hasMore && (
                <Button disabled={inProgress} kind="secondary-grey" onClick={fetchMore}>
                  Wczytaj więcej
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const clickOutsideClass = cuid();
interface FilterDropdownProps {
  label: string;
  name: string;
  options: { value: string; label: string; img: React.ReactNode; color: string }[];
  defaultValue: string;
  value: string;
  onChange: (option: FilterDropdownProps["options"][number]) => void;
}

export const FilterDropdown = ({
  options,
  name,
  label,
  defaultValue,
  value,
  onChange,
}: FilterDropdownProps) => {
  const { isOpen, close, toggle } = useToggle(false);

  const selected =
    options.find(option => option.value === value) ||
    options.find(option => option.value === defaultValue);

  return (
    <ClickOutsideHandler onClickOutside={close} outsideClickIgnoreClass={clickOutsideClass}>
      <div className={styles.filter}>
        <button
          type="button"
          className="d-flex align-items-center px-1"
          aria-haspopup="menu"
          onClick={toggle}
        >
          <strong className={cx("mr-1", styles.filterLabel)}>{label}:</strong>
          <strong className={styles.selected}>{selected?.label}</strong>
        </button>
        <div
          className={styles.optionsList}
          role="menu"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options.map(option => (
            <div
              key={option.value}
              className={styles.optionsItem}
              role="menuitem"
              onClick={() => {
                onChange(option);
                close();
              }}
            >
              {option.img}
              <strong>{option.label}</strong>
            </div>
          ))}
        </div>
      </div>
    </ClickOutsideHandler>
  );
};
