import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiExpress } from "components/miloDesignSystem/atoms/icons/MdiExpress";
import { ExpressServicesRouting } from "routes/logistics/ExpressServicesRouting";

export const expressServices: ModuleLink = {
  url: "express-services",
  label: "Przesyłki ekspresowe",
  icon: MdiExpress,
  routing: ExpressServicesRouting,
};
