import { orderActions } from "api/orders/actions";
import { ExpressService } from "api/orders/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import styles from "./CreateOrModifyExpressServiceModal.module.css";
import { Formik, FormikHelpers } from "formik";
import { cx } from "utilities";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { validationSchema } from "./validationSchema";
import { expressServicesUtils } from "../../utils/expressServicesUtils";

interface Props {
  close: () => void;
  expressService?: ExpressService;
  type: ExpressService["type"];
}

interface Values {
  name: string;
  value: number;
  type: ExpressService["type"];
  unit: "hours" | "days";
}

export const CreateOrModifyExpressServiceModal = ({ close, expressService, type }: Props) => {
  const initialValues: Values = {
    name: expressService?.name || "",
    value: expressService?.value || 0,
    type,
    unit: "hours",
  };
  const updateMutation = orderActions.usePatchExpressService();
  const createMutation = orderActions.usePostExpressService(close);

  const handleSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    createMutation.mutate(
      {
        name: values.name,
        value: values.unit === "hours" ? values.value : values.value * 24,
        type: values.type,
      },
      {
        onSuccess: () => {
          actions.setSubmitting(false);
        },
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return (
    <Modal
      close={close}
      isOpen
      title={
        <div className="d-flex align-items-center gap-2">
          <Typography fontSize="20" fontWeight="700">
            {!expressService ? "Dodaj etykietę" : "Edytuj etykietę"}
          </Typography>
          {expressServicesUtils.getServiceDetailsBasedOnType(type).icon({
            size: "24",
            color: expressServicesUtils.getServiceDetailsBasedOnType(type).backgroundColor,
          })}
        </div>
      }
      width={470}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex flex-column p-3 gap-3">
              <TextField.Form<Values>
                helperText="Maks 255 znaków"
                label="Nazwa etykiety"
                name="name"
                placeholder="Np. 24h"
              />
              <div className="d-flex flex-column gap-1">
                <Typography
                  className="text-uppercase"
                  color="neutralBlack48"
                  fontSize="12"
                  fontWeight="700"
                >
                  Czas na dostarczenie:
                </Typography>
                <div className="d-flex align-items-center gap-2">
                  <TextField.Form<Values>
                    containerClassName={styles.shortInput}
                    name="value"
                    type="number"
                  />
                  <div className="cursor-pointer" onClick={() => setFieldValue("unit", "hours")}>
                    <Tag
                      label="Godziny"
                      type={values.unit === "hours" ? "filled" : "outlined"}
                      variant={values.unit === "hours" ? "deepPurple50" : "quaternary"}
                    />
                  </div>
                  <div className="cursor-pointer" onClick={() => setFieldValue("unit", "days")}>
                    <Tag
                      label="Dni"
                      type={values.unit === "days" ? "filled" : "outlined"}
                      variant={values.unit === "days" ? "deepPurple50" : "quaternary"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 p-3">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting || updateMutation.isLoading}
                onClick={() => {
                  if (expressService && isValid) {
                    updateMutation.mutate(
                      {
                        id: expressService.id,
                        name: values.name,
                        value: values.unit === "hours" ? values.value : values.value * 24,
                      },
                      {
                        onSuccess: () => close(),
                      },
                    );
                  }
                }}
                size="medium"
                type={expressService ? "button" : "submit"}
                variant="deepPurple"
              >
                Gotowe
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
