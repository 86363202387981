import filtersIcon from "assets/images/38.svg";
import commentIcon from "assets/images/45g.svg";
import callCenterIcon from "assets/images/bok.svg";
import homePointIcon from "assets/images/homePin.svg";
import styles from "./RightTopButtons.module.css";
import cx from "classnames";
import { useRouteViewState } from "../routeCreatorState";
import { Button } from "components/common";
import { Link } from "react-router-dom";
import { Route } from "api/routes/models";
import { MapMode } from "../Creator";
import areaImg from "assets/images/208.svg";
import binImg from "assets/images/81g.svg";
import playImg from "assets/images/61.svg";
import { ExpressServiceFilterBar } from "../expressServiceFilterBar/ExpressServiceFilterBar";

interface Props {
  route: Route;
  mode: MapMode;
  toggleMode: () => void;
  submitPolygon: () => void;
  resetPolygon: () => void;
  submitDisabled: boolean;
}

export const RightTopButtons = ({
  route,
  mode,
  toggleMode,
  submitPolygon,
  resetPolygon,
  submitDisabled,
}: Props) => {
  const isSettingCenterPoint = useRouteViewState("slave", state => state.isSettingCenterPoint);
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", store => store.isLoading);

  function handleReset() {
    resetPolygon();
    toggleMode();
  }

  return (
    <div className={styles.container}>
      <ExpressServiceFilterBar />
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin, {
          [styles.toggleBtnSelected]: isSettingCenterPoint,
        })}
        onClick={() => actions.setIsSettingCenterPoint()}
        title="Ustaw centralny punkt"
      >
        <img src={homePointIcon} alt="" style={{ height: "16px", width: "16px" }} />
      </button>
      <Button
        as={Link}
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        kind="secondary-stroke"
        to={`/call-center/routes/${route.id}/orders`}
        title="Wróć do BOK"
      >
        <img src={callCenterIcon} alt="cofnij do BOK" style={{ height: "16px", width: "16px" }} />
      </Button>
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        onClick={() => actions.toggle("isOrderCommentsDrawerOpen")}
      >
        <img src={commentIcon} alt="" />
      </button>
      <button
        className={cx(styles.toggleButton, styles.toggleButtonMargin)}
        onClick={() => actions.toggle("isFiltersDrawerOpen")}
      >
        <img src={filtersIcon} alt="" />
      </button>

      {mode === "polygon" ? (
        <div className={cx(styles.multiplePointsSelect, "d-flex align-items-center px-2")}>
          <Button
            kind="secondary"
            size="round-m"
            onClick={handleReset}
            title="Usuń zaznaczenie"
            className="mr-2"
          >
            <img src={binImg} alt="" />
          </Button>
          <Button
            kind="secondary"
            size="round-m"
            onClick={submitPolygon}
            disabled={submitDisabled || isLoading}
            title="Dodaj zaznaczone punkty do trasy"
          >
            <img src={playImg} alt="" />
          </Button>
        </div>
      ) : (
        <Button
          className={styles.toggleButton}
          kind="secondary"
          size="round-m"
          onClick={toggleMode}
          title="Zaznacz obszar, z którego punkty zostaną dodane do trasy"
        >
          <img src={areaImg} alt="" />
        </Button>
      )}
    </div>
  );
};
