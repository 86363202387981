import { TodoSection } from "./subcomponents/todoSection/TodoSection";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "../subcomponents/Header";
import { InProgressSection } from "./subcomponents/inProgressSection/InProgressSection";
import { ReadySection } from "./subcomponents/readySection/ReadySection";
import { useColumnDropService } from "./hooks/useColumnDropService";

export enum ColumnType {
  TODO = "todo",
  IN_PROGRESS = "inProgress",
  READY = "ready",
}

export const ColumnView = () => {
  const handleDrop = useColumnDropService();

  const handleObjectDropped = (result: DropResult) => handleDrop(result);

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden gap-2 pl-3 pr-3 py-2">
        <DragDropContext onDragEnd={handleObjectDropped}>
          <TodoSection />
          <InProgressSection />
          <ReadySection />
        </DragDropContext>
      </div>
    </PageWrapper>
  );
};
