import { assertIsDefined } from "utilities/assertIsDefined";
import { tradingDocumentsKeys } from "./keys";
import {
  deleteTradingDocument,
  postInvoiceForOrder,
  postNotificationToRecipient,
  postPreviewTradingDocument,
  postTradingDocumentPayment,
} from "./calls";
import { useMutation } from "hooks/useMutation";
import { useTradingDocument } from "./hooks";
import { useState } from "react";
import { UUID } from "api/types";
import immer from "immer";
import {
  AddProductToDraftInvoicePayload,
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
  CreatePricing,
  CreateTradingDocumentPayment,
  FiscalizeReceiptPayload,
  TradingDocument,
} from "./models";
import { tradingDocumentsApi } from "./api";
import { getAnyErrorKey } from "utilities";
import { useStateModal } from "hooks";
import { FormikHelpers } from "formik";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { createApiQuery } from "hooks/createApiQuery";
import { useNavigate } from "hooks/useNavigate";
import { QueryClient } from "react-query";
import {
  CreatePricingForm,
  Modifier,
} from "pages/tradingDocuments/createDraftDocument/pricing/Pricing";
import { financesApi } from "api/finances/api";

const useLightTradingDocuments = createPaginatedApiQuery(
  tradingDocumentsApi.getLightTradingDocuments,
);

const useImportedPurchaseInvoice = createApiQuery(tradingDocumentsApi.getImportedPurchaseInvoice);

const usePatchImportedPurchaseInvoice = () => {
  return useMutation(tradingDocumentsApi.patchImportedPurchaseInvoice, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevPanel = queryUtils.handleMutate(
        tradingDocumentsKeys.importedPurchaseInvoices.details(toUpdate.id),
        toUpdate,
      );

      const prevList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.tradingDocument.list(),
        toUpdate.id,
        toUpdate,
      );

      const purchaseImportList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList, purchaseImportList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollback(
        tradingDocumentsKeys.importedPurchaseInvoices.details(id),
        onMutationReturn.prevPanel,
        error,
      );
      queryUtils.rollbackList(
        tradingDocumentsKeys.tradingDocument.list(),
        onMutationReturn.prevList,
        id,
      );
      queryUtils.rollbackList(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        onMutationReturn.purchaseImportList,
        id,
        error,
      );
    },
  }));
};

const useCreateDraftDocumentPosition = () => {
  return useMutation(financesApi.postDraftDocumentPosition, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useAddPricing = (close: () => void) => {
  const addPricingMutation = useMutation(
    tradingDocumentsApi.postPricing,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Ustalono ceny",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const transformFormData = (value: CreatePricingForm, modifier: Modifier) => {
    const createPricing: CreatePricing = {
      tradingDocumentId: value.tradingDocumentId,
    };
    if (Boolean(value.priceListId)) {
      createPricing.priceListId = value.priceListId as number;
    }
    if (modifier !== "NONE") {
      createPricing.modifiers = {};
      if (modifier === "DISCOUNT") {
        createPricing.modifiers.discount = value.discount;
      } else if (modifier === "MARGIN") {
        createPricing.modifiers.margin = value.margin;
      }
    }
    return createPricing;
  };

  const handleSubmit = (
    value: CreatePricingForm,
    actions: FormikHelpers<CreatePricingForm>,
    modifier: Modifier,
  ) => {
    addPricingMutation.mutate(transformFormData(value, modifier), {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useAddProductToDraftDocument = (close: () => void) => {
  const addProductToDraftDocumentMutation = useMutation(
    financesApi.postDraftDocumentPosition,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano indeks",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddProductToDraftInvoicePayload,
    actions: FormikHelpers<AddProductToDraftInvoicePayload>,
  ) => {
    addProductToDraftDocumentMutation.mutate(
      {
        tradingDocumentId: values.tradingDocumentId,
        positions: [
          {
            amountWithTax: values.amountWithTax,
            discount: values.discount,
            name: values.name,
            orderId: values.orderId,
            quantity: values.quantity,
            vatRate: values.vatRate,
            indexId: values.productElements[0].index ?? undefined,
          },
        ],
      },
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};

const useCreateDraftDocument = () => {
  const navigate = useNavigate();

  return useMutation(tradingDocumentsApi.postWorkInProgressDocument, ({ toastr }) => ({
    onSuccess: payload => {
      navigate(`/finances/create-work-in-progress-document/${payload.id}`);
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono roboczy dokument handlowy dla podmiotu własnego",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useModifyDraftDocumentPosition = () => {
  const handleRollback = (context: TradingDocument, queryClient: QueryClient) => {
    queryClient.setQueryData<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(context.id),
      details => {
        assertIsDefined(details);
        return context;
      },
    );
  };

  return useMutation(tradingDocumentsApi.patchDraftDocumentPosition, ({ queryClient, toastr }) => ({
    onMutate: args => {
      const prevDetails = queryClient.getQueryData(
        tradingDocumentsKeys.tradingDocument.details(args.tradingDocumentId!),
      );
      const { id, tradingDocumentId, ...newArgs } = args;
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(args.tradingDocumentId!),
        details => {
          assertIsDefined(details);
          return immer(details, draft => {
            draft.items = draft.items.map(item => ({
              ...item,
              tradingDocumentItems: item.tradingDocumentItems.map(tradingDocumentItem => {
                if (tradingDocumentItem.id === id) return { ...tradingDocumentItem, ...newArgs };
                return tradingDocumentItem;
              }),
            }));
          });
        },
      );
      return prevDetails;
    },
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, _, context) => {
      assertIsDefined(context);
      handleRollback(context as TradingDocument, queryClient);
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveDraftDocumentPosition = () => {
  return useMutation(
    tradingDocumentsApi.deleteDraftDocumentPosition,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useSetVatRate = (tradingDocumentId: UUID) => {
  const [showInput, setShowInput] = useState(false);
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  const setVatRateMutation = useMutation(
    tradingDocumentsApi.patchTradingDocumentItemsVatRate,
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        return handleMutate(draft => {
          draft.isManagedManually = true;
          draft.items = draft.items.map(item => {
            if (item) {
              item.tradingDocumentItems = item.tradingDocumentItems.map(_tradingDocumentItem => {
                if (_tradingDocumentItem.id === args.tradingDocumentItemsIds[0]) {
                  return {
                    ..._tradingDocumentItem,
                    vatRate: args.vatRate,
                  };
                }
                return _tradingDocumentItem;
              });
            }
            return item;
          });
        });
      },
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zmodyfikowano stawkę VAT",
        });
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );
      },
      onError: (error, _, rollback) => {
        assertIsDefined(rollback);
        rollback(error);
      },
      onSettled: () => setShowInput(false),
    }),
  );

  return {
    setVatRateMutation,
    setShowInput,
    showInput,
  };
};

const useBulkPatchTradingDocuments = () => {
  return useMutation(tradingDocumentsApi.bulkUpdateTradingDocuments, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useSendTradingDocumentsToOptimaUsingElte = () => {
  return useMutation(tradingDocumentsApi.postSendToOptimaByElte, ({ queryUtils, toastr }) => ({
    ...queryUtils.handleCommonResponse,
    onSuccess: () => {
      toastr.open({
        text: "Pomyślnie wysłano do Optimy",
        title: "Udało się!",
        type: "success",
      });
      queryUtils.handleCommonResponse.onSuccess();
    },
  }));
};

const useRemoveInvoiceConfirmationPatch = () => {
  return useMutation(
    tradingDocumentsApi.patchRemoveInvoiceConfirmation,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

const useBulkConfirmReceipts = (panelId?: UUID) => {
  return useMutation(tradingDocumentsApi.postBulkConfirmReceipts, ({ queryClient }) => ({
    onSuccess: () => {
      if (panelId) {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
      }
      queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
    },
  }));
};

const useBulkSalesAccountConfirmation = (panelId?: UUID) => {
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();

  const bulkSalesInvoiceConfirmMutation = useMutation(
    tradingDocumentsApi.postBulkConfirmSalesInvoice,
    ({ queryClient }) => ({
      onSuccess: payload => {
        if (panelId) {
          queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
        }
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        replyModal.open(payload.message);
      },
    }),
  );

  return { replyModal, bulkSalesInvoiceConfirmMutation };
};

const useFiscalizeReceipts = (panelId: UUID) => {
  return useMutation(
    tradingDocumentsApi.postFiscalizeMultipleReceipts,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się",
          text: "Wysłano paragony",
        });
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useSubmitPayment = (close: () => void, id: UUID) => {
  const createPaymentMutation = useMutation(
    (formData: CreateTradingDocumentPayment) => postTradingDocumentPayment(formData),
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(id));
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano płatność",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  return (
    values: CreateTradingDocumentPayment,
    actions: FormikHelpers<CreateTradingDocumentPayment>,
  ) => {
    createPaymentMutation.mutate(
      values.kind === "ADVANCE"
        ? {
            ...values,
            amount: String(
              values
                .items!.reduce((acc, item) => {
                  return acc + item.totalAmount;
                }, 0)
                .toFixed(2),
            ),
          }
        : values,
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };
};

const useCreateSalesInvoice = () => {
  return useMutation(postInvoiceForOrder, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(tradingDocumentsKeys.lightTradingDocument.list());
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono fakturę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useSetCurrencyForDraftDocument = () => {
  return useMutation(tradingDocumentsApi.postSetCurrency, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zmieniono walutę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCreateSalesInvoiceFromReceipt = () => {
  return useMutation(tradingDocumentsApi.createSalesInvoiceFromReceipt, ({ toastr }) => ({
    onSuccess: () =>
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono fakturę sprzedażową na podstawie paragonu",
      }),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useDeleteTradingDocument = (close: () => void, signature: string) => {
  return withDeleteConfirmation(
    useMutation(deleteTradingDocument, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto fakturę ${signature}`,
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    })),
  )();
};

const useSendMultipleEmailNotifications = () => {
  return useMutation(
    tradingDocumentsApi.postMultipleEmailNotifications,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Pomyślnie wysłano powiadomienie o dokumencie handlowym",
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

const useSendEmailNotification = () => {
  return useMutation(postNotificationToRecipient, ({ queryClient, toastr }) => ({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        tradingDocumentsKeys.tradingDocument.details(variables.tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Pomyślnie wysłano powiadomienie o fakturze",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCreateCorrection = (close: () => void) => {
  return useMutation(tradingDocumentsApi.postCorrectionDocumentForInvoice, ({ toastr }) => ({
    onSuccess: () => {
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono korektę",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useResetPrices = (close: () => void) => {
  return useMutation(tradingDocumentsApi.postResetPrices, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zresetowano ceny",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useFiscalizeReceipt = (
  openReplyModal: (stateToSet: { preview: FiscalizeReceiptPayload }) => void,
  id: TradingDocument["id"],
) => {
  return useMutation(
    (data: { id: string }) => tradingDocumentsApi.postFiscalizeReceipt(data),
    ({ queryClient }) => ({
      onSuccess: payload => {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(id));
        openReplyModal({ preview: payload });
      },
    }),
  );
};

const useCreateCurrencyCorrection = () => {
  return useMutation(tradingDocumentsApi.postCurrencyCorrection, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono korektę kursu walut",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCorrectionPreview = (
  openModal: (stateToSet: CreateCorrectionModalState | null) => void,
) => {
  return useMutation(
    (data: { id: UUID; expectedPaymentForm: string }) =>
      tradingDocumentsApi.postPreviewCorrection({ tradingDocument: data.id }),
    ({ toastr }) => ({
      onSuccess: (payload, args) => {
        openModal({
          preview: payload,
          expectedPaymentForm: args.expectedPaymentForm,
          tradingDocumentId: args.id,
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

const useCreateCorrectionForAdvanceInvoice = (close: () => void) => {
  return useMutation(tradingDocumentsApi.postCorrectionDocumentForAdvanceInvoice, ({ toastr }) => ({
    onSuccess: () => {
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono korektę",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useCustomerTradingDocumentPreview = () => {
  return useMutation(
    ({
      items,
      orders,
    }: {
      orders: string[] | number[];
      items?: {
        id: number;
        quantity: number;
      }[];
      customerId?: number;
    }) => {
      return postPreviewTradingDocument({
        orders,
        items,
      });
    },
    ({ toastr }) => ({
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

export const tradingDocumentsActions = {
  useCreateCurrencyCorrection,
  useCreateSalesInvoiceFromReceipt,
  useCustomerTradingDocumentPreview,
  useSetVatRate,
  useBulkPatchTradingDocuments,
  useBulkSalesAccountConfirmation,
  useFiscalizeReceipts,
  useLightTradingDocuments,
  useSubmitPayment,
  useCreateSalesInvoice,
  useDeleteTradingDocument,
  useSendMultipleEmailNotifications,
  useSendEmailNotification,
  useCreateCorrection,
  useCorrectionPreview,
  useImportedPurchaseInvoice,
  usePatchImportedPurchaseInvoice,
  useRemoveInvoiceConfirmationPatch,
  useCreateDraftDocument,
  useCreateDraftDocumentPosition,
  useRemoveDraftDocumentPosition,
  useModifyDraftDocumentPosition,
  useAddProductToDraftDocument,
  useAddPricing,
  useResetPrices,
  useSetCurrencyForDraftDocument,
  useCreateCorrectionForAdvanceInvoice,
  useFiscalizeReceipt,
  useBulkConfirmReceipts,
  useSendTradingDocumentsToOptimaUsingElte,
};
