import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Filters, FormValues } from "../CreateExternalManufacturingOrderModal";
import { SelectableOption } from "pages/inventory/products/shared/components/selectableOption/SelectableOption";
import { ProductKind } from "api/products/models";
import { useFormikContext } from "formik";

interface Props {
  filters: Filters;
  setFilter: <T extends "productKind", U extends Filters[T]>(name: T, value: U) => void;
}

export const FiltersSection = ({ filters, setFilter }: Props) => {
  const context = useFormikContext<FormValues>();

  return (
    <div className="d-flex align-items-baseline gap-1 px-3">
      <Typography className="text-uppercase" color="neutralBlack48" fontSize="10" fontWeight="700">
        Zleć:
      </Typography>
      <div className="d-flex align-items-center gap-1">
        <SelectableOption
          checked={filters.productKind === ""}
          isLoading={false}
          label="Wszystko"
          showIcon={false}
          onClick={() => {
            setFilter("productKind", "");
            context.setValues(
              context.values.map(value => ({
                ...value,
                isSelected: true,
              })),
            );
          }}
        />
        <SelectableOption
          checked={filters.productKind === ProductKind.UPHOLSTERY}
          isLoading={false}
          label="Tylko meble tapicerowane"
          showIcon={false}
          onClick={() => {
            setFilter("productKind", ProductKind.UPHOLSTERY);
            context.setValues(
              context.values.map(value => ({
                ...value,
                isSelected: value.productKind === ProductKind.UPHOLSTERY ? true : false,
              })),
            );
          }}
        />
        <SelectableOption
          checked={filters.productKind === ProductKind.BOX}
          isLoading={false}
          label="Tylko meble skrzyniowe"
          showIcon={false}
          onClick={() => {
            setFilter("productKind", ProductKind.BOX);
            context.setValues(
              context.values.map(value => ({
                ...value,
                isSelected: value.productKind === ProductKind.BOX ? true : false,
              })),
            );
          }}
        />
      </div>
    </div>
  );
};
