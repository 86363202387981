import { Route } from "components/utils";
import { PackagesInWarehouseValues } from "pages/finances/packagesInWarehouseValues/PackagesInWarehouseValues";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const PackagesValuesRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={PackagesInWarehouseValues}
        exact
        path={`${match.path}/list/all`}
        title="Milo - Finances - Łączna wycena paczek"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
