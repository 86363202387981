import { CustomerConfirmationStatus, ManufacturingItemStatus, SmsStatus } from "api/orders/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";
import priorityNormal from "assets/images/priority_normal.svg";
import priorityHigh from "assets/images/priority_high.svg";
import priorityCritical from "assets/images/priority_critical.svg";
import { dictToList } from "./utilities";
import { MdiForwardToInbox } from "components/miloDesignSystem/atoms/icons/MdiForwardToInbox";
import { MdiEmailError } from "components/miloDesignSystem/atoms/icons/MdiEmailError";
import { MdiMarkEmailRead } from "components/miloDesignSystem/atoms/icons/MdiMarkEmailRead";
import { MdiMarkEmailUnread } from "components/miloDesignSystem/atoms/icons/MdiMarkEmailUnread";
import { MdiMail } from "components/miloDesignSystem/atoms/icons/MdiMail";
import { MdiOrderConfirmation } from "components/miloDesignSystem/atoms/icons/MdiOrderConfirmation";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { PriorityChoices } from "api/orders/enums";
import { ISODateTime } from "api/types";
import { differenceInDays, differenceInMinutes, isBefore } from "date-fns";
import { DAY_IN_MINUTES, HOUR_IN_MINUTES } from "CONSTANTS";

const singleItemQuantitiesManufacturingStatusOptions: Record<
  ManufacturingItemStatus,
  TagProps<string>
> = {
  DONE: { label: "wyprodukowano", type: "outlined", variant: "success" },
  IN_PROGRESS: { label: "w trakcie", type: "outlined", variant: "warning" },
  PENDING: { label: "nie zlecono", type: "outlined", variant: "quaternary" },
};

const orderDeliveryPrioritiesDict: Record<PriorityChoices, { label: string; icon: string }> = {
  CRITICAL: { label: "Krytyczne", icon: priorityCritical },
  HIGH: { label: "Pilne", icon: priorityHigh },
  NORMAL: { label: "Normalne", icon: priorityNormal },
};

const orderDeliveryPrioritiesItems: { id: PriorityChoices; name: string }[] = dictToList(
  orderDeliveryPrioritiesDict,
).map(({ key, value }) => ({ id: key, name: value.label }));

const smsStatusIcons: Record<SmsStatus, { icon: JSX.Element; label: string }> = {
  ERROR: { icon: <MdiEmailError color="danger600" size="16" />, label: "błąd wysyłki sms" },
  NOT_SEEN: {
    icon: <MdiMarkEmailUnread color="success500" size="16" />,
    label: "wiadomość sms od klienta (inna niż potwierdzenie)",
  },
  NOT_SENT: { icon: <MdiMail color="neutralBlack48" size="16" />, label: "nie wysłano sms" },
  SEEN: { icon: <MdiMarkEmailRead color="success500" size="16" />, label: "odczytano sms" },
  SENT: { icon: <MdiForwardToInbox color="info500" size="16" />, label: "wysłano sms" },
};

const customerConfirmationStatusIcons: Record<
  CustomerConfirmationStatus,
  { icon: JSX.Element; label: string }
> = {
  CONFIRMED: { icon: <MdiOrderConfirmation color="success500" size="16" />, label: "potwierdzono" },
  DECLINED: {
    icon: <MdiShoppingCart color="danger600" size="16" />,
    label: "odrzucono",
  },
  NOT_SENT: {
    icon: <MdiShoppingCart color="neutralBlack48" size="16" />,
    label: "nie wysłano",
  },
  PENDING: {
    icon: <MdiShoppingCart color="info500" size="16" />,
    label: "oczekuje",
  },
};

const getExpressServiceRemainingTime = (expire: ISODateTime) => {
  const expireDate = new Date(expire);
  const now = new Date();

  const isPast = isBefore(expireDate, now);
  const prefix = isPast ? "po terminie:" : "pozostało";

  const totalMinutes = Math.abs(differenceInMinutes(expireDate, now));
  const totalDays = Math.abs(differenceInDays(expireDate, now));

  if (totalMinutes <= 1) {
    return `${prefix} mniej niż 1 min`;
  } else if (totalMinutes < HOUR_IN_MINUTES) {
    return `${prefix} ${totalMinutes} min`;
  } else if (totalMinutes < DAY_IN_MINUTES * 2) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${prefix} ${hours} h ${minutes} min`;
  } else {
    const days = totalDays;
    return `${prefix} ${days} dni`;
  }
};

export const ordersUtils = {
  customerConfirmationStatusIcons,
  getExpressServiceRemainingTime,
  orderDeliveryPrioritiesDict,
  orderDeliveryPrioritiesItems,
  singleItemQuantitiesManufacturingStatusOptions,
  smsStatusIcons,
};
