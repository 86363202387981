import { useSelector } from "hooks";
import { ColumnView } from "./columnView/ColumnView";
import { ListView } from "./listView/ListView";
import { manufacturingActions } from "api/manufacturing/actions";
import { queryString } from "utilities";
import { useStageId } from "../hooks/useStageId";

export const StageBoard = () => {
  const viewType = useSelector(store => store.ui.viewTypes.manufacturingStageBoard ?? "board");
  const stageId = useStageId();
  const manufacturingStages = useSelector(store => store.partials.stages);
  const manufacturingStage = manufacturingStages.find(stage => stage.id === stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );

  if (!manufacturingStage || !defaultFilters) return null;

  if (viewType === "board") return <ColumnView key={`stage-list-${stageId}`} />;

  return <ListView key={`stage-list-${stageId}`} />;
};
