import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedLineItem } from "../useLineItemColumns";
import { LineItemKind } from "api/wh-entries/enums";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { MdiArrowOutward } from "components/miloDesignSystem/atoms/icons/MdiArrowOutward";
import { Link } from "components/miloDesignSystem/atoms/link/Link";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { ClickOutsideHandler } from "components/utils";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  isNested: boolean;
  lineItem: NormalizedLineItem;
  showConnectedWithAnotherWhEntry: boolean;
}

export const ReleasesConnectionWithAnotherWhEntry = ({
  isNested,
  lineItem,
  showConnectedWithAnotherWhEntry,
}: Props) => {
  if (!isNested || lineItem.kind === LineItemKind.PRODUCT) return null;

  if (!showConnectedWithAnotherWhEntry || !Boolean(lineItem.connectedWithAnotherWhEntry.length))
    return <EmptyValue color="neutralBlack48" fontSize="10" fontWeight="500" />;

  return (
    <div className="d-flex align-items-center gap-1 w-100">
      <IconRenderer color="neutralBlack100" icon={MdiArrowOutward} size={14} />
      <Link
        fontSize="10"
        fontWeight="500"
        to={`/finances/releases/list/all?panelId=${lineItem.connectedWithAnotherWhEntry[0].id}`}
      >
        {lineItem.connectedWithAnotherWhEntry[0].signature}
      </Link>
      {lineItem.connectedWithAnotherWhEntry.length > 1 && (
        <Popover
          hideOnClickOutside={false}
          content={({ hide }) => (
            <ClickOutsideHandler
              onClickOutside={() => {
                hide();
              }}
              outsideClickIgnoreClass={ignoreClickOutsideClassName}
            >
              <div className="d-flex flex-column gap-2">
                {lineItem.connectedWithAnotherWhEntry.slice(1).map(whEntry => (
                  <Link
                    fontSize="10"
                    fontWeight="500"
                    to={`/finances/releases/list/all?panelId=${whEntry.id}`}
                  >
                    {whEntry.signature}
                  </Link>
                ))}
              </div>
            </ClickOutsideHandler>
          )}
          variant="DARK"
        >
          <div onClick={event => event.stopPropagation()}>
            <Typography color="deepPurple400" fontSize="10" fontWeight="500">
              + {lineItem.connectedWithAnotherWhEntry.length - 1}
            </Typography>
          </div>
        </Popover>
      )}
    </div>
  );
};

const ignoreClickOutsideClassName = "releases-links-popover-ignore-class-name";
