import whEntry from "assets/images/307.svg";
import whDeparture from "assets/images/306.svg";
import whPlacement from "assets/images/305.svg";
import circleImg from "assets/images/44.svg";

export const historyCategories = [
  {
    label: "Wszystkie",
    value: "",
    color: "#9bb1dc",
    img: circleImg,
  },
  {
    label: "Przyjęcie do magazynu",
    value: "ACCEPTED_IN_WAREHOUSE",
    color: "#f7743e",
    img: whEntry,
  },
  {
    label: "Wydanie z magazynu",
    value: "RELEASED_FROM_WAREHOUSE",
    color: "#d50180",
    img: whDeparture,
  },
  {
    label: "Ulokowanie na regale",
    value: "LOCATED",
    color: "#5b3fdd",
    img: whPlacement,
  },
  {
    label: "Zdjęcie z regału",
    value: "DISLOCATED",
    color: "#5b3fdd",
    img: whPlacement,
  },
];
