import { Route } from "components/utils";
import { ExpressServices } from "pages/logistics/expressServices/ExpressServices";
import { NotFound } from "pages/notFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const ExpressServicesRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={ExpressServices}
        exact
        path={`${match.path}/list/all`}
        title="Milo - Logistyka - Przesyłki ekspresowe"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
