import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting7 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5457)">
        <path
          d="M11.9997 21.391C17.1865 21.391 21.3911 17.1864 21.3911 11.9997C21.3911 6.81304 17.1865 2.6084 11.9997 2.6084C6.81305 2.6084 2.6084 6.81304 2.6084 11.9997C2.6084 17.1864 6.81305 21.391 11.9997 21.391Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M5.33301 5.38672C7.07823 3.62731 9.44817 2.62896 11.9263 2.60938L12.0005 12.0004L5.33301 5.38672Z"
          fill="black"
        />
        <path
          d="M11.9998 1.02148C18.0629 1.02148 22.9781 5.93662 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9998 1.02148Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5457">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
