import { ISODate, ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { ManufacturingMaterialKind } from "./materials/models";
import {
  ImplementedBy,
  ManufacturingPriority,
  MaterialStageStatus,
  StageStatus,
} from "./units/models";
import { EmployedPlace } from "api/manufacturingNew/models";
import { CURRENCY_TYPE, CountryCode, EmployeeKindExtended } from "CONSTANTS";
import { EmailType, EmailStatus as MessagesEmailStatus } from "api/messages/models";
import { ManufacturingItemStatus, Order } from "api/orders/models";
import { SalesAccount } from "api/sales-accounts/models";

export enum StageBoardAttributeKind {
  FABRIC = "FABRIC",
  SIZE = "SIZE",
  SIDE = "SIDE",
}

export enum EmailKind {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

export enum ManufacturingSmsStatus {
  NOT_SENT = "NOT_SENT",
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
}

export interface Recipe {
  id: UUID;
  isDeleted: boolean;
  material: {
    id: UUID;
    name: string;
    unit: string;
    kind: ManufacturingMaterialKind;
    category: { id: UUID; name: string } | null;
    externalId: string;
  };
  position: number;
  quantity: string;
  createdAt: ISODateTime;
  createdBy: number;
  updatedAt: ISODateTime | null;
  updatedBy: number;
  usedAsOutput: { id: UUID; name: string } | null;
  usedAsInput: { id: UUID; name: string } | null;
}

export interface PostRecipe {
  index: number;
  material: UUID | null;
  position: number;
  quantity: number;
}

export interface StageComponent {
  createdAt: ISODateTime;
  createdBy: number;
  id: UUID;
  index: number;
  kind: StageComponentKind;
  recipe: Recipe;
  stage: UUID;
}

export enum StageComponentKind {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
}
export interface IndexStages {
  id: UUID;
  signature: string;
  name: string;
  stages: {
    code: string;
    id: number;
    isThisTheLastStage: boolean;
    position: number;
    name: string;
    stageId: UUID;
  }[];
}

export interface StageBoardDefaultAttributesKind {
  attributeKind: StageBoardAttributeKind;
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  id: UUID;
  schemaStage: {
    backgroundColor: string;
    code: string;
    id: UUID;
    implementedBy: ImplementedBy;
    name: string;
    textColor: string;
  };
}

export interface StageBoardDefaultAttributesKindPayload {
  attributeKind: StageBoardAttributeKind;
  schemaStage: UUID;
}

export enum Transaction {
  ADVANCE_PAYMENT = "ADVANCE_PAYMENT",
  WITHDRAWAL = "WITHDRAWAL",
}

export interface ManufacturingEmployeeListItem extends UserWithInitials {
  balance: number;
  dateOfEmployment: ISODate;
  email: string;
  employedPlace: EmployedPlace;
  isDeleted: boolean;
  kind: EmployeeKindExtended;
  number: string;
  phone: string;
  userId: number;
  wallet: {
    balance: number;
    id: UUID;
    latestPayment: {
      amount: number;
      createdAt: ISODateTime;
      currency: CURRENCY_TYPE;
      transactionType: Transaction;
    } | null;
  } | null;
}

export interface MaterialOrderListItem {
  id: UUID;
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  materialOrder: {
    id: UUID;
    signature: string;
  };
  material: {
    id: UUID;
    category: {
      id: UUID;
      name: string;
    };
    name: string;
    unit: {
      id: UUID;
      shortName: string;
      name: string;
    };
  };
  manufacturer: {
    id: number;
    name: string;
  };
  manufacturingItem: unknown;
  manufacturingWorkingUnit: unknown;
  status: MaterialStageStatus;
  orderedAt: ISODateTime | null;
  receivedAt: ISODateTime | null;
  scheduledDeadline: ISODateTime;
  scheduledReception: ISODateTime | null;
  quantity: string;
  delivery: {
    id: UUID;
    name: string;
  };
  unit: {
    shortName: string;
    name: string;
  };
}

export enum ManufacturingOrderStatus {
  "NOT_ORDERED" = "NOT_ORDERED",
  "RECEIVED" = "RECEIVED",
  "ORDERED" = "ORDERED",
}

export enum EmailStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  SEEN = "SEEN",
  ERROR = "ERROR",
}

export interface ManufacturingOrder {
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  deliveries: {
    name: string;
    street: string;
    city: string;
    postCode: string;
    countryCode: string;
  }[];
  deliveryDeadlineAt: ISODateTime | null;
  email: string;
  emailStatus: {
    status: MessagesEmailStatus;
  } | null;
  expectedDeliveryAt: ISODateTime | null;
  id: UUID;
  isCancelled: boolean;
  manufacturer: {
    id: number;
    name: string;
  };
  materialsOrdersItems: {
    id: UUID;
    status: ManufacturingOrderStatus;
  }[];
  orderedAt: ISODateTime | null;
  receivedAt: ISODateTime | null;
  receivedBy: UserWithInitials | null;
  scheduledEmailSendAt: ISODateTime | null;
  signature: string;
  status: ManufacturingOrderStatus;
}

export interface CommissionListItem {
  contractor: {
    id: number;
    name: string;
  } | null;
  created: ISODateTime;
  deliveryDeadlineAt: ISODateTime | null;
  emailStatus: {
    status: EmailStatus;
  } | null;
  expectedDeliveryAt: ISODateTime | null;
  id: UUID;
  isCancelled: boolean;
  items: {
    id: UUID;
    status: ManufacturingOrderStatus;
  }[];
  orderedAt: ISODateTime | null;
  receivedAt: ISODateTime | null;
  scheduledEmailSendAt: ISODateTime | null;
  signature: string;
  status: ManufacturingOrderStatus;
}

export interface ManufacturerSingleOrderListItem {
  createdAt: ISODateTime;
  createdBy: UserWithInitials;
  delivery: {
    city: string;
    countryCode: CountryCode;
    id: UUID;
    name: string;
    postCode: string;
    street: string;
  };
  id: UUID;
  manufacturer: {
    id: number;
    name: string;
  };
  manufacturingItem: {
    id: UUID;
    signature: string;
  } | null;
  manufacturingWorkingUnit: {
    id: UUID;
    signature: string;
  } | null;
  material: {
    category: {
      id: UUID;
      name: string;
    } | null;
    externalId: string;
    id: UUID;
    name: string;
    unit: {
      id: UUID;
      name: string;
      shortName: string;
    };
  };
  materialOrder: {
    id: UUID;
    signature: string;
  };
  orderedAt: ISODateTime | null;
  quantity: string;
  receivedAt: ISODateTime | null;
  scheduledDeadline: ISODateTime | null;
  scheduledReception: ISODateTime | null;
  status: ManufacturingOrderStatus;
}

export interface ManufacturingEmail {
  createdAt: ISODateTime;
  id: UUID;
  kind: EmailKind;
  materialOrder: {
    id: UUID;
    signature: string;
  };
  message: {
    body: string;
    created: ISODateTime;
    createdBy: UserWithInitials | null;
    emailFrom: string;
    emailTo: string;
    emailType: EmailType;
    eventType: string;
    failureReason: string;
    id: number;
    status: MessagesEmailStatus;
    subject: string;
  };
}

export interface PostManufacturingEmailPayload {
  orders: UUID[];
  recipientEmail?: string;
}

export interface PostManufacturingOrdersReceivePayload {
  materialsOrders?: UUID[];
  materialsOrdersItems?: UUID[];
}

export interface ItemProgress {
  status: ItemProgressStatus;
  finishedAt: ISODateTime | null;
  employee: UserWithInitials | null;
}

export interface ManufacturingItemProgress {
  id: UUID;
  signature: string;
  order: {
    id: number;
    signature: string;
    customer: {
      fullName: string;
      name: string;
    };
    delivery: {
      firstName: string;
      lastName: string;
    };
  } | null;
  progress: Record<UUID, ItemProgress>;
  index: {
    id: number;
    product: {
      id: number;
      name: string;
      category: string | null;
    };
    attributes: {
      id: number;
      attribute: string;
      value: string;
    }[];
  };
}

export enum ItemProgressStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

export type ManufacturerEmailsTab = "sent" | "not-sent" | "all";

export interface ManufacturingItem {
  id: UUID;
  identity: string;
  signature: string;
  indexId: UUID;
  itemId: UUID;
  createdAt: ISODateTime;
  lastUpdatedAt: ISODateTime;
  externalOrderNumber: string;
  finishedAt: ISODateTime | null;
  note: string;
  status: ManufacturingItemStatus;
  createdBy: UserWithInitials;
  modifiedBy: UserWithInitials;
  priority: ManufacturingPriority;
  name: string;
  itemNote: string;
  materials: {
    averageDeliveryDays: number | null;
    id: UUID;
    isCustomOrdered: boolean;
    manufacturers: {
      id: number;
      name: string;
    }[];
    name: string;
    orders: {
      id: UUID;
      status: MaterialStageStatus;
    }[];
    quantity: number;
    unit: {
      id: UUID;
      shortName: string;
    };
  }[];
  currentStages: {
    id: UUID;
    isDeclined: boolean;
    isQa: boolean;
    masterStageId: UUID;
    name: string;
    status: StageStatus;
  }[];
  countryCode: CountryCode | null;
  route: {
    id: number;
    signature: string;
  } | null;
  orderGroup: {
    id: number;
    signature: string;
  } | null;
  order: {
    id: number;
    signature: string;
    type: Order["type"];
    delivery: {
      firstName: string;
      lastName: string;
      street: string;
      postCode: string;
      city: string;
      countryCode: CountryCode;
      phone: string;
    };
  } | null;
  scheduledAt: ISODateTime | null;
  index: { name: string; id: number } | null;
  locations: string[] | null;
  startedAt: ISODateTime | null;
  salesAccount: SalesAccount | null;
}
