import {
  OrderForCreatingInvoice,
  OrderPositionForCreatingInvoice,
} from "api/trading-documents/models";

export const getAllPositions = (orders: OrderForCreatingInvoice[]) => {
  return orders.reduce((allPositions, order) => {
    return allPositions.concat(order.positions);
  }, [] as OrderPositionForCreatingInvoice[]);
};
