import { useState } from "react";
import { useAsyncModal } from "hooks";
import { Modal } from "components/utils";
import { Button } from "components/common";
import styles from "./ToolbarDateAsyncModal.module.css";
import { DatePicker } from "components/utils/datePicker";
import { dateUtils } from "utilities";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { RangeType } from "../toolbar/types";

export const ToolbarAsyncModal = ({
  id,
  rangeType = "date",
}: {
  id: Symbol;
  rangeType: RangeType;
}) => {
  const asyncModal = useAsyncModal();
  const isOpen = asyncModal.opened === id;
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  return (
    <Modal isOpen={isOpen} close={asyncModal.close} size={{ all: { width: "300px" } }}>
      <div>
        <div>
          <h5 className="pb-3">{rangeType === "date" ? "Wskaż zakres dat" : "Wskaż zakres"}</h5>
        </div>
        <div className="position-relative mb-3">
          <span className={styles.label}>Od:</span>
          {rangeType === "date" ? (
            <DatePicker
              className={styles.date}
              value={start}
              onChange={date => setStart(date ? dateUtils.formatDateToIso(date) : "")}
              overwrites={{ popup: { className: styles.datePickerPopup } }}
            />
          ) : (
            <TextField
              onChange={value => {
                if (!value) setStart("");
                else setStart(value.target.value);
              }}
              type="number"
              value={start}
            />
          )}
        </div>
        <div className="position-relative ">
          <span className={styles.label}>Do:</span>
          {rangeType === "date" ? (
            <DatePicker
              className={styles.date}
              value={end}
              onChange={date => setEnd(date ? dateUtils.formatDateToIso(date) : "")}
              overwrites={{ popup: { className: styles.datePickerPopup } }}
            />
          ) : (
            <TextField
              onChange={value => {
                if (!value) setEnd("");
                else setEnd(value.target.value);
              }}
              type="number"
              value={end}
            />
          )}
        </div>
        <div className="d-flex align-items-center justify-content-end pt-4">
          <Button
            autoFocus
            kind="primary"
            onClick={() => {
              asyncModal.resolve([start, end]);
            }}
          >
            Szukaj
          </Button>
        </div>
      </div>
    </Modal>
  );
};
