import { useQuery, useStateModal } from "hooks";
import { UUID } from "api/types";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useUnitColumns } from "./useUnitColumns";
import { Table } from "components/miloDesignSystem/molecules/table";
import {
  ManufacturingPriority,
  ManufacturingUnitListViewItem,
} from "api/manufacturing/units/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanel } from "./rightPanel/RightPanel";
import styles from "./ListView.module.css";
import { cx, queryString } from "utilities";
import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Header } from "../subcomponents/Header";
import { UnitHistory } from "./subcomponents/UnitHistory";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { StagesTableToolbar } from "./stagesTableToolbar/StagesTableToolbar";
import { useDefaultFilters } from "hooks/useDefaultFilters";
import { useStageId } from "../../hooks/useStageId";
import { OrderTypeChoices } from "api/orders/enums";

export interface HistoryPayload {
  unitId: UUID;
  signature: string;
}

export const ListView = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const stageId = useStageId();

  useDefaultFilters({
    stageStatus: query.stageStatus || "READY",
  });

  const search = queryString.stringify({ masterStages: stageId, ...query });
  const {
    data: units,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingUnitsActions.useGetUnitBoardList(search);
  const historyModal = useStateModal<HistoryPayload>();

  const stageListMultiSelect = useTableMultiSelect({ rows: units });

  const tableProps = useTableFeatureConnector({
    rows: units,
    withDrawer: "manufacturingStageNormalList",
    withMultiSelect: stageListMultiSelect,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useUnitColumns(units, historyModal);

  return (
    <PageWrapper key={`list-view-${stageId}`}>
      <Header />
      <div className="d-flex flex-column flex-1 overflow-hidden gap-2">
        <Table<ManufacturingUnitListViewItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              row: row => {
                return {
                  className: cx(tableProps?.overrides?.().row?.(row).className, {
                    [styles.criticalRow]:
                      row.manufacturingItem.priority === ManufacturingPriority.A,
                    [styles.complaintRow]: row.orderType === OrderTypeChoices.COMPLAINT,
                  }),
                };
              },
            };
          }}
        />
        <RightPanel />
        <StagesTableToolbar
          close={stageListMultiSelect.clear}
          numberOfSelectedItems={stageListMultiSelect.selectedRows.length}
          units={stageListMultiSelect.selectedRows}
        />
      </div>
      {historyModal.isOpen && <UnitHistory close={historyModal.close} state={historyModal.state} />}
    </PageWrapper>
  );
});
