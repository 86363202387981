import {
  DeclinedNoteModal,
  ManufacturingPriority,
  NormalizedGroupElement,
} from "api/manufacturing/units/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./ManufacturingPanel.module.css";
import { MdiQualityAlert } from "components/miloDesignSystem/atoms/icons/MdiQualityAlert";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiStickyNote2 } from "components/miloDesignSystem/atoms/icons/MdiStickyNote2";
import { MdiCancelBg } from "components/miloDesignSystem/atoms/icons/MdiCancelBg";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";
import { MdiComment } from "components/miloDesignSystem/atoms/icons/MdiComment";

export const useGroupElementsColumns = (
  groupElements: NormalizedGroupElement[],
  selectedGroupUnits: NormalizedGroupElement[],
  setSelectedGroupUnits: React.Dispatch<React.SetStateAction<NormalizedGroupElement[]>>,
  declinedNoteModal: DeclinedNoteModal,
) => {
  const isUnitSelected = (id: string | number): boolean =>
    selectedGroupUnits.some(groupElement => groupElement.id === id);

  const setUnit = (id: number | string): void => {
    if (isUnitSelected(id))
      return setSelectedGroupUnits(prevGroups =>
        prevGroups.filter(groupElement => groupElement.id !== id),
      );
    const groupToAdd = groupElements.find(groupElement => groupElement.id === id);
    assertIsDefined(groupToAdd);
    setSelectedGroupUnits(prevGroups => [...prevGroups, groupToAdd]);
  };

  const areSomeUnitsSelected = Boolean(
    selectedGroupUnits.length &&
      groupElements.some(groupElement =>
        selectedGroupUnits.some(
          selectedGroupElement => selectedGroupElement.id === groupElement.id,
        ),
      ),
  );

  const areAllSelected =
    groupElements.every(groupElement =>
      selectedGroupUnits.some(selectedGroupElement => selectedGroupElement.id === groupElement.id),
    ) && Boolean(selectedGroupUnits.length);

  const setAllUnits = (): void => {
    if (areAllSelected) return setSelectedGroupUnits([]);
    const notSelectedGroups = groupElements.filter(
      groupElement =>
        !selectedGroupUnits.some(
          selectedGroupElement => selectedGroupElement.id === groupElement.id,
        ),
    );
    setSelectedGroupUnits(prevGroups => [...prevGroups, ...notSelectedGroups]);
  };

  return useCreateTableColumns<NormalizedGroupElement>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectedGroups",
        header: () => (
          <div className="d-flex align-items-end pb-2">
            <Checkbox
              checked={areAllSelected}
              indeterminate={areSomeUnitsSelected && !areAllSelected}
              onChange={setAllUnits}
              size="sx"
            />
          </div>
        ),
        size: 25,
        cell: info => {
          const element = info.getValue();
          return (
            <div className="my-0">
              <Checkbox
                checked={isUnitSelected(element.id)}
                onChange={() => setUnit(element.id)}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.attributes, {
        header: "tkanina",
        size: 161,
      }),
      columnHelper.accessor(row => row, {
        header: "zlecenie",
        size: 140,
        cell: info => {
          const element = info.getValue();
          return (
            <Typography
              color={
                element.priority === ManufacturingPriority.A
                  ? "danger600"
                  : element.priority === ManufacturingPriority.B
                  ? "orange600"
                  : "neutralBlack88"
              }
              fontSize="14"
              fontWeight={
                element.priority === ManufacturingPriority.A ||
                element.priority === ManufacturingPriority.B
                  ? "700"
                  : "600"
              }
            >
              {element.signature}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zamówienie",
        size: 160,
        cell: info => {
          const element = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="12" fontWeight="700">
                {element.orderSignature}
              </Typography>
              {element.itemNote && (
                <Tooltip title={element.itemNote}>
                  <MdiComment color="deepOrange400" size="16" className="mr-1" />
                </Tooltip>
              )}

              {element.isComplaint && <MdiClaim color="magenta200" size="16" />}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.externalOrderNumber, {
        header: "nr. zlec. klienta",
        size: 130,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "additionalOptions",
        header: " ",
        size: 96,
        cell: info => {
          const element = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              {(element.priority === ManufacturingPriority.A ||
                element.priority === ManufacturingPriority.B) && (
                <div
                  className={styles.alertIcon}
                  style={{
                    borderColor:
                      colorPalette[
                        manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                          element.priority
                        ].light.color
                      ],
                  }}
                >
                  {
                    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                      element.priority
                    ].light.icon
                  }
                </div>
              )}
              {element.isCancelled && <MdiCancelBg color="deepOrange200" size="16" />}
              {element.isDeclined && <MdiQualityAlert color="warning600" size="16" />}
              {element.note && (
                <Tooltip title="Pokaż notatkę">
                  <IconButton
                    icon={MdiStickyNote2}
                    onClick={event => {
                      event.stopPropagation();
                      declinedNoteModal.open({
                        note: element.note,
                        signature: element.signature,
                      });
                    }}
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      }),
    ];
  });
};
