import { OrderPoint, WarehouseDeliveryDetails } from "api/orders/models";
import externalImg from "assets/images/p49.png";

import avgPace from "assets/images/mapMarkers/avg_pace.png";
import visibilityLock from "assets/images/mapMarkers/visibility_lock.png";
import { mapIcons } from "assets/images/mapIcons/generatedMapIcons";
import { Assign } from "utility-types";
import { WarehouseIconKind } from "api/wms/models";
import { OrderTypeChoices, PriorityChoices } from "api/orders/enums";

function getAwaitingMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "warehouseDeliveryDetails"
      | "warehouse"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
): string {
  const priority =
    location.priority === PriorityChoices.NORMAL
      ? ""
      : location.priority === PriorityChoices.CRITICAL
      ? "S"
      : "F";

  const warehouseSelector = getWarehouseSelector(
    location.warehouse?.icon || WarehouseIconKind.ROUND,
  );
  const daySelector = getDaysLeftToDeliverySelector(
    location.warehouseDeliveryDetails?.daysLeftToDelivery!,
  );

  const key = `awaiting${daySelector}${priority}${
    warehouseSelector ? `_${warehouseSelector}` : ""
  }` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}
function getDaysLeftToDeliverySelector(
  days: NonNullable<WarehouseDeliveryDetails["daysLeftToDelivery"]>,
) {
  if (days < 1) return 0;
  if (days === 1) return 1;
  if (days === 2) return 2;
  if (days === 3) return 3;
  if (days === 4) return 4;
  if (days === 5) return 5;
  if (days === 6) return 6;
  if (days >= 7) return 7;
  return 7;
}

function getStandardMarkerIcon(
  location: Pick<
    OrderPoint,
    "hasUpholstery" | "type" | "leftDays" | "priority" | "numberOfDaysFromCreatedDate" | "warehouse"
  >,
): string {
  const kind = location.hasUpholstery ? "upholstery" : "box";
  const type = location.type;
  const priority = location.priority;
  const days =
    location.type === OrderTypeChoices.STANDARD
      ? location.leftDays
      : (location.numberOfDaysFromCreatedDate - 14) * -1;

  const daySelector = getDaySelector(days);
  const typeSelector = getTypeSelector({ type, kind, priority });
  const warehouseSelector = getWarehouseSelector(
    location.warehouse?.icon || WarehouseIconKind.ROUND,
  );
  const key = `p${typeSelector}${daySelector}${warehouseSelector}` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}

function getWarehouseSelector(warehouseIconKind: WarehouseIconKind) {
  switch (warehouseIconKind) {
    case WarehouseIconKind.ROUND:
      return "";
    case WarehouseIconKind.SQUARE:
      return "S";
    case WarehouseIconKind.TRIANGLE:
      return "T";
    case WarehouseIconKind.DIAMOND:
      return "D";
    case WarehouseIconKind.PENTAGON:
      return "P";
    default: {
      const exhaustiveCheck: never = warehouseIconKind;
      console.error(`Unhandled warehouse icon kind: ${exhaustiveCheck}`);
      return "";
    }
  }
}

function getTypeSelector({
  kind,
  priority,
  type,
}: {
  type: OrderTypeChoices;
  kind: "box" | "upholstery";
  priority: PriorityChoices;
}) {
  if (type === OrderTypeChoices.COMPLAINT) {
    if (kind === "upholstery") {
      if (priority === PriorityChoices.CRITICAL) return "URS";
      if (priority === PriorityChoices.HIGH) return "URF";
      return "UR";
    }

    if (priority === PriorityChoices.CRITICAL) return "RS";
    if (priority === PriorityChoices.HIGH) return "RF";
    return "R";
  }

  if (kind === "upholstery") {
    if (priority === PriorityChoices.CRITICAL) return "US";
    if (priority === PriorityChoices.HIGH) return "UF";
    return "U";
  }

  if (priority === PriorityChoices.CRITICAL) return "S";
  if (priority === PriorityChoices.HIGH) return "F";

  return "B";
}

function getDaySelector(days: number) {
  if (days >= 14) {
    return 1;
  }
  if (days >= 7) {
    return 3;
  }
  if (days >= 2) {
    return 2;
  }
  if (days >= 0) {
    return 4;
  }
  if (days >= -7) {
    return 5;
  }
  if (days >= -14) {
    return 7;
  }
  return 8;
}

export function getPinnedAwaitingMarkerIcon(warehouseIconKind: WarehouseIconKind): string {
  const warehouseSelector = getWarehouseSelector(warehouseIconKind);

  const key = `pPawaiting${
    warehouseSelector ? `_${warehouseSelector}` : ""
  }` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}
export function getPinnedMarkerIcon(warehouseIconKind: WarehouseIconKind): string {
  const warehouseSelector = getWarehouseSelector(warehouseIconKind);

  const key = `pinned${warehouseSelector}` as keyof typeof mapIcons;

  //@ts-ignore
  if (!mapIcons.hasOwnProperty(key)) return;

  return mapIcons[key];
}
export function getMarkerIcon(
  location: Assign<
    Pick<
      OrderPoint,
      | "id"
      | "hasUpholstery"
      | "type"
      | "leftDays"
      | "priority"
      | "numberOfDaysFromCreatedDate"
      | "warehouse"
      | "isHidden"
      | "hideUntilIssueIsSolved"
      | "hideOnMapTo"
    >,
    { warehouseDeliveryDetails: WarehouseDeliveryDetails | null }
  >,
): string {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (!location.warehouseDeliveryDetails) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.isInWarehouse) return getStandardMarkerIcon(location);
  if (location.warehouseDeliveryDetails.date === null) return getStandardMarkerIcon(location);

  return getAwaitingMarkerIcon(location);
}

export function getMarkerIconForGroups(
  location: Pick<
    OrderPoint,
    | "hasUpholstery"
    | "type"
    | "leftDays"
    | "numberOfDaysFromCreatedDate"
    | "warehouse"
    | "priority"
    | "isHidden"
    | "hideUntilIssueIsSolved"
    | "hideOnMapTo"
  > & {
    method: string;
  },
  isPinned: boolean,
) {
  if (location.isHidden) {
    if (location.hideOnMapTo) {
      return avgPace;
    }
    return visibilityLock;
  }
  if (isPinned) {
    return externalImg;
  }
  return getStandardMarkerIcon(location);
}
