import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting4 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5454)">
        <path
          d="M11.9997 21.391C17.1865 21.391 21.3911 17.1864 21.3911 11.9997C21.3911 6.81304 17.1865 2.6084 11.9997 2.6084C6.81305 2.6084 2.6084 6.81304 2.6084 11.9997C2.6084 17.1864 6.81305 21.391 11.9997 21.391Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M11.9941 21.3917C9.51022 21.3902 7.12817 20.4048 5.36918 18.6512C3.61017 16.8976 2.61752 14.5184 2.60846 12.0346C2.59939 9.55079 3.57466 7.16456 5.32081 5.3981C7.06697 3.63169 9.44177 2.629 11.9255 2.60938L11.9997 12.0003L11.9941 21.3917Z"
          fill="black"
        />
        <path
          d="M11.9998 1.02148C18.0629 1.02148 22.9781 5.93662 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9998 1.02148Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5454">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
