import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting0 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      d="M11.9997 21.3909C17.1864 21.3909 21.3909 17.1863 21.3909 11.9997C21.3909 6.813 17.1864 2.6084 11.9997 2.6084C6.81299 2.6084 2.6084 6.813 2.6084 11.9997C2.6084 17.1863 6.81299 21.3909 11.9997 21.3909Z"
      fill="black"
      fill-opacity="0.32"
    />
    <path
      d="M11.9997 21.3909C17.1864 21.3909 21.3909 17.1863 21.3909 11.9997C21.3909 6.813 17.1864 2.6084 11.9997 2.6084C6.81299 2.6084 2.6084 6.813 2.6084 11.9997C2.6084 17.1863 6.81299 21.3909 11.9997 21.3909Z"
      fill="black"
    />
    <path
      d="M1.02148 11.9997C1.02148 5.9366 5.93665 1.02148 11.9998 1.02148C18.0629 1.02148 22.9781 5.9366 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.9366 22.978 1.02148 18.0629 1.02148 11.9997Z"
      stroke="black"
    />
  </SvgWrapper>
);
