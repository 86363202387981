import { FinancialPackageInWarehouseValues } from "api/wms/financial-packages/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import {
  Attributes,
  getNormalizedAttributes,
} from "components/miloDesignSystem/molecules/attributes";
import { formatSubUnitsToDecimal } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackagesInWarehouseValuesColumns = () => {
  return useCreateTableColumns<FinancialPackageInWarehouseValues>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 300,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.description, {
        header: "opis",
        size: 400,
        cell: info => {
          const description = info.getValue();

          if (!Boolean(description.length)) return <EmptyValue fontSize="14" fontWeight="500" />;
          if (!Boolean(getNormalizedAttributes(description).length))
            return (
              <Typography fontSize="14" fontWeight="500" noWrap>
                {description}
              </Typography>
            );
          return <Attributes attributes={description} />;
        },
      }),
      columnHelper.text(row => row.internalId, {
        header: "SKU",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.storedPackagesCount, {
        id: "storedPackagesCount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            liczba paczek
          </Typography>
        ),
        size: 100,
        cell: info => {
          const storedPackagesCount = info.getValue();

          return (
            <div className="w-100 d-flex align-items-baseline justify-content-end gap-1">
              <Typography fontSize="14" fontWeight="400">
                {storedPackagesCount}
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.totalValue, {
        id: "totalValue",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wartość towarów
          </Typography>
        ),
        size: 200,
        cell: info => {
          const totalValue = info.getValue();

          return (
            <div className="d-flex justify-content-end w-100">
              {totalValue === null ? (
                <EmptyValue fontSize="14" />
              ) : (
                <AmountDisplay
                  amount={formatSubUnitsToDecimal(totalValue)}
                  currency="PLN"
                  decimalTypographyProps={{
                    fontSize: "12",
                    fontWeight: "600",
                  }}
                  integerTypographyProps={{
                    fontSize: "14",
                    fontWeight: "700",
                  }}
                />
              )}
            </div>
          );
        },
      }),
    ];
  });
};
