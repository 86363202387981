import { createPaginatedKey } from "api/keys";

export const financialPackagesKeys = {
  packageState: {
    states: createPaginatedKey("financialPackageStates"),
    supplies: createPaginatedKey("financialPackageSupplies"),
  },
  inventoryChecks: {
    list: createPaginatedKey("financialPackageInventoryChecks"),
  },
  packagesInWarehouseValues: {
    list: createPaginatedKey("packagesInWarehouseValues"),
  },
};
