import { graphhopper } from "api/graphhopper/graphhopper";
import { OrderPositions, Route } from "api/routes/models";
import { Button } from "components/common";
import { StatusHandler, StatusHandlerHelpers } from "components/utils";
import { useToastr } from "hooks";
import { useRoutePutMutation } from "hooks/apiPrimitives";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useRouteViewState } from "../../routeCreatorState";

export const OptimizeRouteBtn = ({ route }: { route: Route }) => {
  const toastr = useToastr();
  const updateMutation = useRoutePutMutation();
  const actions = useRouteViewState("slave", state => state.actions);
  const isLoading = useRouteViewState("slave", state => state.isLoading);

  async function optimizeRoute(helpers: StatusHandlerHelpers) {
    actions.openLoader("Trwa optymalizacja trasy");
    helpers.startFetching();

    const payload = await graphhopper.optimize({
      vehicle: {
        vehicleId: "vehicle-1",
        typeId: route.vehicleType!,
        startAddress: {
          locationId: "base",
          lat: route.startingPoint.point.lat,
          lon: route.startingPoint.point.lng,
        },
        return_to_depot: route.includeLastPointInOptimization,
      },
      objectives: [
        {
          type: "min",
          value: "completion_time",
        },
      ],
      services: route.ordersPositions
        .filter(el => Boolean(el.meta.point))
        .map(el => ({
          id: String(el.id),
          address: {
            locationId: String(el.id),
            lat: el.meta!.point!.lat,
            lon: el.meta!.point!.lng,
          },
        })),
      configuration: {
        routing: {
          // snapPreventions: getPreventions(),
        },
      },
    });
    helpers.stopFetching();

    if (payload) {
      const returnToStartingPointMeta = {
        distance: route.includeLastPointInOptimization ? payload.returnToStartingPoint.distance : 0,
        time: route.includeLastPointInOptimization ? payload.returnToStartingPoint.duration : 0,
      };

      const waypointsOrderClone = [...payload.waypointsOrder];
      const payloadWaypointsMetaClone = [...payload.waypointsMeta];

      const routeOrdersDict: Record<string, OrderPositions[number]> = route.ordersPositions.reduce(
        (acc, el) => {
          Object.assign(acc, { [el.id]: el });
          return acc;
        },
        {},
      );

      const newOrdersPositions: OrderPositions = route.ordersPositions.map(el => {
        if (el.meta.point) {
          const id = String(waypointsOrderClone.shift());
          const waypointMeta = payloadWaypointsMetaClone.shift();
          assertIsDefined(waypointMeta);
          assertIsDefined(id);
          const prevData = routeOrdersDict[id];

          return {
            id,
            type: prevData.type,
            duration: null,
            meta: {
              ...prevData.meta,
              delivery: {
                ...prevData.meta.delivery,
                time: waypointMeta.duration,
                distance: waypointMeta.distance,
              },
            },
            warehouseDeliveryDetails:
              prevData.type === "order" ? prevData.warehouseDeliveryDetails : null,
            hasGuaranteedDeliveryBeforeChristmas:
              prevData.type === "order" ? prevData.hasGuaranteedDeliveryBeforeChristmas : false,
            expressService: prevData.type === "order" ? prevData.expressService : null,
          };
        }

        return el;
      });

      updateMutation.mutate({
        data: {
          length: payload.distance,
          operation: null,
          returnToStartingPointDistance: String(returnToStartingPointMeta.distance),
          returnToStartingPointTime: String(returnToStartingPointMeta.time),
          ordersPositions: newOrdersPositions,
          shouldCalculateAverageSpeed: true,
        },
        route: route.id,
      });
    } else {
      actions.closeLoader();
      toastr.open({
        type: "failure",
        title: "Nie udało się przeliczyć czasu przejazdu",
        text: "",
      });
    }
  }

  const onClickHandle = (helpers: StatusHandlerHelpers) => {
    optimizeRoute(helpers);
  };

  if (route.orders.length < 2) return null;

  return (
    <StatusHandler>
      {helpers => (
        <Button
          disabled={helpers.isFetching || isLoading}
          kind="secondary-stroke"
          size="small"
          className="fs-10 "
          onClick={() => onClickHandle(helpers)}
        >
          <strong className="text-color-grey">Optymalizuj trasę</strong>
        </Button>
      )}
    </StatusHandler>
  );
};
