import { Shipment } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import {
  ShippingService,
  getInpostCourierService,
  getZadbanoDeliveryStandardConstants,
  getZadbanoOrderTypeConstants,
} from "constants/shippingServiceConstants";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { CollapsibleSection } from "pages/logistics/shared/CollapsibleSection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { zadbanoActions } from "api/shipping/zadbano/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { generateDictBasedOnFactoryAndEnum, getAnyErrorKey } from "utilities";
import {
  DeliveryStandardChoices,
  OrderTypeChoice,
  ServiceTimeWindowChoices,
} from "api/shipping/zadbano/enum";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { inpostActions } from "api/shipping/inpost/actions";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { CURRENCY_TYPE, currenciesOptions } from "CONSTANTS";
import { InpostCourierService } from "api/shipping/inpost/enum";
import { sptActions } from "api/shipping/spt/actions";

interface Props {
  shipment: Shipment;
}

export const CustomShippingServiceOptionsSection = (props: Props) => {
  if (!props.shipment.shippingService) {
    return null;
  }

  switch (props.shipment.shippingService.provider) {
    case ShippingService.AMBRO: {
      return <Ambro {...props} />;
    }
    case ShippingService.DPD: {
      return null;
    }
    case ShippingService.DM_TRANS: {
      return null;
    }
    case ShippingService.GLS: {
      return <GLS {...props} />;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return <SPT {...props} />;
    }
    case ShippingService.ZADBANO: {
      return <Zadbano {...props} />;
    }
    case ShippingService.POCZTEX: {
      return null;
    }
    case ShippingService.BJ_LOGISTICS: {
      return null;
    }
    case ShippingService.INPOST: {
      return <Inpost {...props} />;
    }
    case ShippingService.AJ_TRANSPORT: {
      return null;
    }
    case ShippingService["NOT CONNECTED"]: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.shipment.shippingService?.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const Inpost = ({ shipment }: Props) => {
  const { data, isLoading, error } = inpostActions.useDetails(shipment.id);

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <Spinner size={24} />
      </RightPanelSection>
    );
  }

  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </RightPanelSection>
    );
  }

  assertIsDefined(data);

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <InfoLabel title="usługa email" className="mt-1">
        <Checkbox.Async
          checked={data.hasEmailService}
          label=""
          mutationHook={inpostActions.usePatchDetails}
          size="sm"
          transformQueryData={hasEmailService => ({
            shipments: [shipment.id],
            hasEmailService,
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa zwrotów" className="mt-1">
        <Checkbox.Async
          checked={data.hasReturnService}
          label=""
          mutationHook={inpostActions.usePatchDetails}
          size="sm"
          transformQueryData={hasReturnService => ({
            shipments: [shipment.id],
            hasReturnService,
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa doręczenia w sobotę" className="mt-1">
        <Checkbox.Async
          checked={data.hasSaturdayService}
          label=""
          mutationHook={inpostActions.usePatchDetails}
          size="sm"
          transformQueryData={hasSaturdayService => ({
            shipments: [shipment.id],
            hasSaturdayService,
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa SMS" className="mt-1">
        <Checkbox.Async
          checked={data.hasSmsService}
          label=""
          mutationHook={inpostActions.usePatchDetails}
          size="sm"
          transformQueryData={hasSmsService => ({
            shipments: [shipment.id],
            hasSmsService,
          })}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj usługi" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getInpostCourierService,
            InpostCourierService,
          ).map(({ value, key }) => ({
            text: value.text,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={inpostActions.usePatchDetails}
          transformQueryData={inpostCourierService => ({
            shipments: [shipment.id],
            inpostCourierService: inpostCourierService as InpostCourierService,
          })}
          selected={data.inpostCourierService}
        />
      </InfoLabel>
      <InfoLabel title="kwota ubezpieczenia" className="mt-1">
        <TextField.Async
          placeholder="Wpisz kwotę"
          type="number"
          mutationHook={inpostActions.usePatchDetails}
          transformQueryData={insuranceServiceAmount => ({
            shipments: [shipment.id],
            insuranceServiceAmount: Number(insuranceServiceAmount),
          })}
          value={data.insuranceServiceAmount || 0}
        />
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={currenciesOptions}
          mutationHook={inpostActions.usePatchDetails}
          transformQueryData={insuranceServiceCurrency => ({
            shipments: [shipment.id],
            insuranceServiceCurrency: insuranceServiceCurrency as CURRENCY_TYPE,
          })}
          selected={data.insuranceServiceCurrency}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const SPT = ({ shipment }: Props) => {
  const { data, isLoading, error } = sptActions.useDetails(shipment.id);

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <Spinner size={24} />
      </RightPanelSection>
    );
  }

  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </RightPanelSection>
    );
  }

  assertIsDefined(data);

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <InfoLabel title="usługa wniesienia" className="mt-1">
        <Checkbox.Async
          checked={shipment.hasCarryingService}
          label=""
          mutationHook={shippingActions.usePatchShipping}
          size="sm"
          transformQueryData={hasCarryingService => ({
            id: shipment.id,
            toUpdate: {
              hasCarryingService,
            },
          })}
        />
      </InfoLabel>
      <InfoLabel title="usługa montażu" className="mt-1">
        <Checkbox.Async
          checked={shipment.hasAssemblingService}
          label=""
          mutationHook={shippingActions.usePatchShipping}
          size="sm"
          transformQueryData={hasAssemblingService => ({
            id: shipment.id,
            toUpdate: {
              hasAssemblingService,
            },
          })}
        />
      </InfoLabel>

      <InfoLabel title="usługa czyszczenia po montażu" className="mt-1">
        <Checkbox.Async
          checked={data.hasCleaningService}
          label=""
          mutationHook={sptActions.usePatchDetails}
          size="sm"
          transformQueryData={hasCleaningService => ({
            shipments: [shipment.id],
            hasCleaningService,
          })}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const Zadbano = ({ shipment }: Props) => {
  const { data, isLoading, error } = zadbanoActions.useDetails(shipment.id);

  if (isLoading) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <Spinner size={24} />
      </RightPanelSection>
    );
  }

  if (error) {
    return (
      <RightPanelSection padding="px-3 pt-2 pb-0">
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </RightPanelSection>
    );
  }

  assertIsDefined(data);

  return (
    <RightPanelSection padding="px-3 pt-2 pb-0">
      <InfoLabel title="dostarczyć do" className="mt-1">
        <DatePicker.Async
          value={shipment.shipmentDeadline}
          isNullable
          mutationHook={shippingActions.usePatchShipping}
          transformQueryData={shipmentDeadline => ({
            id: shipment.id,
            toUpdate: {
              shipmentDeadline,
            },
          })}
        />
      </InfoLabel>
      <InfoLabel title="godziny dostaw kuriera" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={Object.entries(ServiceTimeWindowChoices).map(([key, text]) => ({
            text,
            type: MenuItemType.TEXT,
            value: text,
          }))}
          mutationHook={zadbanoActions.usePatchDetails}
          transformQueryData={customerServiceTimeWindow => ({
            shipments: [shipment.id],
            customerServiceTimeWindow: customerServiceTimeWindow as ServiceTimeWindowChoices,
          })}
          selected={data.customerServiceTimeWindow}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj dostawy" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getZadbanoDeliveryStandardConstants,
            DeliveryStandardChoices,
          ).map(({ value, key }) => ({
            text: value.name,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={zadbanoActions.usePatchDetails}
          transformQueryData={deliveryStandard => ({
            shipments: [shipment.id],
            deliveryStandard: deliveryStandard as DeliveryStandardChoices,
          })}
          selected={data.deliveryStandard}
        />
      </InfoLabel>
      <InfoLabel title="rodzaj usługi" className="mt-1">
        <Select.Async
          textFieldProps={{ size: "default" }}
          items={generateDictBasedOnFactoryAndEnum(
            getZadbanoOrderTypeConstants,
            OrderTypeChoice,
          ).map(({ value, key }) => ({
            text: value.name,
            type: MenuItemType.TEXT,
            value: String(key),
          }))}
          mutationHook={zadbanoActions.usePatchDetails}
          transformQueryData={orderType => ({
            shipments: [shipment.id],
            orderType: orderType as OrderTypeChoice,
          })}
          selected={data.orderType}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const GLS = ({ shipment }: Props) => {
  const glsDefaultAdditionalServicesMutation = shippingActions.usePatchShipping();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={shipment.shippingService!.glsDefaultAdditionalServicesOptions}
          selectedOptions={shipment.glsAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      shipment.glsAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const glsAdditionalServices = status
                        ? [...shipment.glsAdditionalServices, option.id]
                        : shipment.glsAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      glsDefaultAdditionalServicesMutation.mutate({
                        id: shipment.id,
                        toUpdate: {
                          glsAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};

const Ambro = ({ shipment }: Props) => {
  const ambroServiceMutation = shippingActions.usePatchShipping();
  const ambroDefaultAdditionalServicesMutation = shippingActions.usePatchShipping();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="domyślny typ usługi">
        <Select
          items={shipment.shippingService!.ambroServicesOptions.map(option => ({
            value: option.id,
            text: option.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={id => {
            const ambroDefaultService = shipment.shippingService!.ambroServicesOptions.find(
              service => service.id === id,
            );
            assertIsDefined(ambroDefaultService);
            ambroServiceMutation.mutate({
              id: shipment.id,
              toUpdate: { ambroService: ambroDefaultService.id },
            });
          }}
          selected={shipment.ambroService}
        />
      </InfoLabel>
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={shipment.shippingService!.ambroAdditionalServicesOptions}
          selectedOptions={shipment.ambroAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      shipment.ambroAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const ambroAdditionalServices = status
                        ? [...shipment.ambroAdditionalServices, option.id]
                        : shipment.ambroAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      ambroDefaultAdditionalServicesMutation.mutate({
                        id: shipment.id,
                        toUpdate: {
                          ambroAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};
