import { ForwardedRef, forwardRef } from "react";
import styles from "../ColumnView.module.css";
import { cx } from "utilities";

interface Props {
  children: React.ReactNode;
  isDragOver?: boolean;
}

export const ColumnWrapper = forwardRef(
  ({ children, isDragOver }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className={cx(
          styles.basicColumn,
          "d-flex flex-column flex-1 position-relative overflow-scroll p-2",
        )}
      >
        <div
          className={cx(styles.draggedOverBorder, {
            [styles.columnDraggedOver]: isDragOver,
          })}
        />
        {children}
      </div>
    );
  },
);
