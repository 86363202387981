import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAwaiting3 = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17929_5453)">
        <path
          d="M11.9997 21.391C17.1865 21.391 21.3911 17.1864 21.3911 11.9997C21.3911 6.81303 17.1865 2.6084 11.9997 2.6084C6.81305 2.6084 2.6084 6.81303 2.6084 11.9997C2.6084 17.1864 6.81305 21.391 11.9997 21.391Z"
          fill="black"
          fill-opacity="0.32"
        />
        <path
          d="M18.6337 18.6477C17.1737 20.1047 15.2748 21.0405 13.23 21.3107C11.1852 21.581 9.10823 21.1704 7.32002 20.1426C5.53176 19.1148 4.13158 17.527 3.33571 15.6242C2.53983 13.7214 2.39253 11.6094 2.91654 9.61454C3.44053 7.61963 4.60669 5.8526 6.23496 4.5865C7.8632 3.32041 9.86298 2.62568 11.9255 2.60938L11.9997 12.0004L18.6337 18.6477Z"
          fill="black"
        />
        <path
          d="M11.9998 1.02148C18.0629 1.02148 22.9781 5.93662 22.9781 11.9997C22.9781 18.0629 18.0629 22.978 11.9998 22.978C5.93662 22.978 1.02148 18.0629 1.02148 11.9997C1.02148 5.93662 5.93662 1.02148 11.9998 1.02148Z"
          stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17929_5453">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgWrapper>
);
