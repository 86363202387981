import logisticsIcon from "assets/images/transport.svg";
import shippingIcon from "assets/images/localShippingLight.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { routes } from "./routes/routes";
import { carriers } from "./routes/carriers";
import { carrierOrders } from "./routes/carrierOrders";
import favicon from "assets/images/favicons/fv_logistic.svg";
import { shippingShipments } from "./routes/shippingShipments";
import { shippingCouriers } from "./routes/shippingCouriers";
import { routeLineItems } from "./routes/routeLineItems";
import { pickupPoints } from "./routes/pickupPoints";
import { expressServices } from "./routes/expressServices";

export const logistics: ModuleNavigation = {
  favicon,
  color: "#8c73ff",
  navigationConfig: {
    label: "Logistyka",
    icon: { src: shippingIcon, background: "#352a68" },
  },
  menuDropdownIcon: logisticsIcon,
  name: "LOGISTICS",
  url: "/logistics",
  dashboard,
  navigationSections: [
    routes,
    carriers,
    carrierOrders,
    shippingShipments,
    shippingCouriers,
    routeLineItems,
    pickupPoints,
    expressServices,
  ],
};
