import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { CreateInvoiceValues } from "../CreateInvoicesForMultipleOrdersModal";
import cx from "classnames";

interface Props {
  convertionRate?: number;
  currency: string;
  fieldName: "amountWithoutTax" | "amountWithTax" | "taxAmountBeforeRounding";
  isMainSummary?: boolean;
  orders: CreateInvoiceValues[];
}

export const WholeAmountToPay = ({
  convertionRate,
  currency,
  fieldName,
  isMainSummary,
  orders,
}: Props) => {
  const getAmount = () => {
    return orders
      .filter(order =>
        order.positions.filter(
          position => position[fieldName] !== null && position[fieldName] !== undefined,
        ),
      )
      .map(order =>
        order.positions.reduce((acc, position) => acc + position[fieldName] * position.quantity, 0),
      )
      .reduce((acc, positionTotal) => acc + positionTotal, 0);
  };

  const getCurrencyConvertion = () => {
    return orders
      .filter(order =>
        order.positions.filter(
          position => position[fieldName] !== null && position[fieldName] !== undefined,
        ),
      )
      .map(order =>
        order.positions.reduce(
          (acc, position) => acc + position[fieldName] * position.quantity * convertionRate!,
          0,
        ),
      )
      .reduce((acc, positionTotal) => acc + positionTotal, 0);
  };

  if (
    orders.some(order =>
      order.positions.some(
        position =>
          position[fieldName] === undefined ||
          position[fieldName] === null ||
          position[fieldName] < 0,
      ),
    )
  ) {
    return <div className="d-flex align-items-center text-color-coral">Błąd przeliczania</div>;
  }

  if (convertionRate) {
    return (
      <>
        <strong
          className={cx({
            "body-14 fw-800": isMainSummary === undefined,
            "body-18-800": isMainSummary,
          })}
        >
          <FinanceAmountFormat value={getCurrencyConvertion()} />
        </strong>
        <div className="fw-700">PLN</div>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <FinanceAmountFormat value={getAmount()} />
      <div className="fs-12 text-color-grey">{currency}</div>
    </div>
  );
};
