import { TradingDocument } from "api/trading-documents/models";
import { GeneralInfoSection } from "./subcomponents/generalInfoSection/GeneralInfoSection";
import { OrderForInvoicing } from "api/orders/models";
import styles from "./AddOrdersToDraft.module.css";
import { OrdersList } from "./subcomponents/ordersList/OrdersList";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useState } from "react";
import { DraftInvoiceOrdersFilters } from "./AddOrdersToDraft";
import { FiltersSection } from "./subcomponents/filtersSection/FiltersSection";
import { Pagination } from "hooks/createPaginatedQuery";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { createDraftDocumentUtils } from "../utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Line } from "components/miloDesignSystem/atoms/line/Line";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
  filters: DraftInvoiceOrdersFilters;
  isLoading: boolean;
  isPreviousData: boolean;
  orders: OrderForInvoicing[];
  pagination: Pagination;
  setFilter: <T extends keyof DraftInvoiceOrdersFilters, U extends DraftInvoiceOrdersFilters[T]>(
    name: T,
    value: U,
  ) => void;
}

export type ItemsOrService = "onlyItems" | "onlyServices" | null;

export const AddOrdersToDraftContent = ({
  close,
  draftInvoice,
  filters,
  isLoading,
  isPreviousData,
  orders,
  pagination,
  setFilter,
}: Props) => {
  const addDraftDocumentPositionsMutation = tradingDocumentsActions.useCreateDraftDocumentPosition();
  const [itemsOrServices, setItemsOrServices] = useState<ItemsOrService>(null);
  const [selectedOrders, setSelectedOrders] = useState<OrderForInvoicing[]>([]);

  const isOrderSelected = (id: number): boolean => selectedOrders.some(order => order.id === id);

  const setOrder = (id: number): void => {
    if (isOrderSelected(id))
      return setSelectedOrders(prevOrders => prevOrders.filter(order => order.id !== id));
    const orderToAdd = orders.find(order => order.id === id);
    assertIsDefined(orderToAdd);
    setSelectedOrders(prevOrders => [...prevOrders, orderToAdd]);
  };

  const areAllOrdersInvoiced =
    selectedOrders.filter(order => createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
      .length === orders.length;

  const areSomeOrdersSelected = Boolean(
    selectedOrders.filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
      .length &&
      orders
        .filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
        .some(order =>
          selectedOrders
            .filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
            .some(selectedOrder => selectedOrder.id === order.id),
        ),
  );

  const areAllSelected =
    orders
      .filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
      .every(order =>
        selectedOrders
          .filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
          .some(selectedOrder => selectedOrder.id === order.id),
      ) &&
    selectedOrders.filter(order => !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order))
      .length > 0;

  const setAllOrders = (): void => {
    if (areAllSelected) return setSelectedOrders([]);
    const notSelectedOrders = orders.filter(
      order =>
        !selectedOrders.some(selectedOrder => selectedOrder.id === order.id) &&
        !createDraftDocumentUtils.areAllPositionsAlreadyInvoiced(order),
    );
    setSelectedOrders(prevOrders => [...prevOrders, ...notSelectedOrders]);
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.content}>
        <GeneralInfoSection draftInvoice={draftInvoice} />
        <Line lineStyle="dashed" />

        <FiltersSection
          filters={filters}
          itemsOrServices={itemsOrServices}
          setFilter={setFilter}
          setItemsOrServices={setItemsOrServices}
        />
        <OrdersList
          orders={orders}
          areAllOrdersInvoiced={areAllOrdersInvoiced}
          areAllSelected={areAllSelected}
          areSomeOrdersSelected={areSomeOrdersSelected}
          isOrderSelected={isOrderSelected}
          isLoading={isLoading}
          isPreviousData={isPreviousData}
          pagination={pagination}
          setAllOrders={setAllOrders}
          setFilter={setFilter}
          setOrder={setOrder}
          selectedOrders={selectedOrders}
        />
      </div>
      <div className={styles.buttons}>
        <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
          Anuluj
        </Button>
        <Button
          className="text-uppercase"
          onClick={() => {
            addDraftDocumentPositionsMutation.mutate(
              {
                tradingDocumentId: draftInvoice.id,
                positions: createDraftDocumentUtils.normalizePositions(
                  itemsOrServices,
                  selectedOrders,
                ),
              },
              {
                onSuccess: () => close(),
              },
            );
          }}
          size="medium"
          variant="deepPurple"
        >
          Dodaj do faktury
        </Button>
      </div>
    </div>
  );
};
