import React from "react";
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip";
import styles from "./Tooltip.module.css";
import cx from "classnames";

type Variant = "LIGHT" | "DARK";

/**
 * Component for displaying tooltip to the user
 *
 * @example
 *  <Tooltip title="Więcej informacji">
 *    <IconButton icon="moreInfo"/> (any component)
 *  </Tooltip>
 *
 * @example
 *  <Tooltip title="Sygnatura zamówienia">
 *     <Typography>{order.signature}</Typography>
 *  </Tooltip>
 */
export const Tooltip = ({
  title,
  children,
  variant = "LIGHT",
  disabled = false,
}: {
  title: React.ReactNode;
  children: JSX.Element;
  disabled?: boolean;
  variant?: Variant;
}) => {
  const tooltip = useTooltipState();
  if (disabled) return <>{children}</>;
  const Child = <span>{children}</span>;
  return (
    <>
      <TooltipReference {...tooltip} {...Child.props}>
        {referenceProps => React.cloneElement(Child, referenceProps)}
      </TooltipReference>
      {Boolean(title) && (
        <ReakitTooltip
          {...tooltip}
          className={cx(styles.tooltip, {
            [styles.light]: variant === "LIGHT",
            [styles.dark]: variant === "DARK",
            "text-center": typeof title === "string",
          })}
        >
          <TooltipArrow
            {...tooltip}
            size="20px"
            className={cx({
              [styles.lightArrow]: variant === "LIGHT",
              [styles.darkArrow]: variant === "DARK",
            })}
          />
          {title}
        </ReakitTooltip>
      )}
    </>
  );
};
